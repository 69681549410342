import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { get, post, del, patch, FOODPRICES_URL, HUKKATAGLOGS_URL, FOODCANONNAMES_URL, ORGFOODS_URL } from '../../utility/api'
import {
  addFoodPrice,
  renewOrgFoods
} from '../../actions'
import HukkaSelect from '../hukkaSelect'
import Modal from '../modal'
import ErrorMessage from '../errorMessage'

const Foods = () => {
  const { t } = useTranslation()
  const { orgFoods, foodPrices, hukkaTags } = useSelector(
    state => state
  )
  const { org } = useSelector(
    state => state.user
  )
  const dispatch = useDispatch()
  const [show, setShow] = useState('all')
  const [error, setError] = useState(null)
  const [search, setSearch] = useState('')
  const [editModal, setEditModal] = useState(false)
  const [editModalFood, setEditModalFood] = useState(null)
  const [editPrice, setEditPrice] = useState(0)
  const [origEditPrice, setOrigEditPrice] = useState(0)
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedTagIds, setSelectedTagIds] = useState([])
  const [selectedCanons, setSelectedCanons] = useState([])
  const [selectedCanonIds, setSelectedCanonIds] = useState([])
  const [selectedCanonId, setSelectedCanonId] = useState(null)
  const [hasCanon, setHasCanon] = useState(false)
  const [isChildCanon, setIsChildCanon] = useState(false)
  const [canonParentName, setCanonParentName] = useState('')
  const [updateCanons, setUpdateCanons] = useState(false)
  const [updateFoods, setUpdateFoods] = useState(false)
  const [loading, setLoading] = useState(false)
  const [initialCanons, setInitialCanons] = useState(false)

  const getTagLogs = async (id) => {
    const resp = await get(HUKKATAGLOGS_URL + '?object_id=' + id)

    if (resp.status === 200) {
      setSelectedTags(resp.data)
      setSelectedTagIds(resp.data.map(tag => tag.tag))
    }
  }

  const updateOrgFoods = async (id) => {
    const resp = await get(ORGFOODS_URL)

    if (resp.status === 200) {
      dispatch(renewOrgFoods(resp.data))
    }
    emptyEditVars()
  }

  const emptyEditVars = () => {
    setEditModalFood(null)
    setEditModal(false)
    setHasCanon(false)
    saveNewPrice()
    setSelectedTags([])
    setSelectedCanons([])
    setSelectedCanonIds([])
    setSelectedCanonId(false)
    setUpdateCanons(false)
    setUpdateFoods(false)
    setInitialCanons(false)
    setIsChildCanon(false)
    setCanonParentName('')
    setLoading(false)
  }

  const validateNumeric = (str) => {
    let orig = str.toString()
    if (orig.length === 0 || orig === '' || orig === null) {
      return '0'
    }
    let firstChar = orig.substr(0, 1)
    let lastChar = orig.substr(orig.length - 1)
    if (lastChar === ',') {
      orig += '.'
    }
    let validated = orig.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")
    if (firstChar === '0') {
      if (validated.substr(1, 1) !== '.') {
        validated = validated.substr(1)
      }
    }
    return validated.toString()
  }

  const tagOptions = hukkaTags && hukkaTags.filter(ht => selectedTagIds.indexOf(ht.id) === -1).slice().sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    return 0
  }).map(ht => {
    return {
      value: ht.id,
      label: ht.name
    }
  })

  const canonOptions = orgFoods && editModalFood && orgFoods.filter(food => selectedCanonIds.indexOf(food.id) === -1 && Object.keys(food.canon).length === 0 && food.id !== editModalFood.id).slice().sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    return 0
  }).map(f => {
    return {
      value: f.id,
      label: f.name
    }
  })

  const saveNewPrice = async () => {
  	if (parseFloat(editPrice) !== origEditPrice) {
      const resp = await post(FOODPRICES_URL, {
        food: editModalFood.id,
        org: org,
        price: editPrice
      })

      if (resp.status !== 201) {
        setError(t('Ruuan hintaa ei voitu päivittää'))
      } else {
      	dispatch(addFoodPrice(resp.data))
      }
    }
   	setEditPrice(0)
   	setOrigEditPrice(0)
  }

  const addCanon = async () => {
    const resp = await post(FOODCANONNAMES_URL, {
      food: editModalFood.id,
      foods: [],
      org
    })

    if (resp.status !== 201) {
      setError(t('Jokin meni vikaan päänimikkeen asettamisessa'))
    } else {
      console.log({resp})
      setSelectedCanonId(resp.data.id)
      setHasCanon(true)
      setUpdateFoods(true)
    }
    setLoading(false)
  }

  const editCanon = async (id, canons) => {
    const resp = await patch(FOODCANONNAMES_URL, id, {
      foods: canons
    })

    if (resp.status !== 200) {
      setError(t('Jokin meni vikaan alanimikkeiden muokkaamisessa.'))
    } else {
      updateOrgFoods()
    }
  }

  const removeCanon = async () => {
    const resp = await del(FOODCANONNAMES_URL, selectedCanonId)

    if (resp.status !== 204) {
      setError(t('Jokin meni vikaan alanimikkeiden muokkaamisessa.'))
    } else {
      setSelectedCanonId(false)
      setSelectedCanonIds([])
      setSelectedCanons([])
      setHasCanon(false)
      setUpdateFoods(true)
    }
    setLoading(false)
  }

  const addTag = async (tag) => {
    const resp = await post(HUKKATAGLOGS_URL, {
      content_type: 13,
      tag: tag.value,
      object_id: editModalFood.id,
      org
    })

    if (resp.status !== 201) {
      setError('Jokin meni vikaan lisätessä tagia.')
    } else {
      getTagLogs(editModalFood.id)
    }
  }

  const removeTag = async (tagId) => {
    const resp = await del(HUKKATAGLOGS_URL, tagId)

    if (resp.status !== 204) {
      setError(t('Jokin meni vikaan poistaessa tagia.'))
    } else {
      getTagLogs(editModalFood.id)
    }
  }

  const foodsList = () => {
  	let html = []

	  let sortedFoods = []

    if (show === 'all') {
    	sortedFoods = orgFoods.filter(food => Object.keys(food.canon).length === 0 || food.id === food.canon.food).slice().sort((a, b) => {
  	    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
  	    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
  	    return 0
  	  })
    } else if (show === 'withPrice') {
      let includedFoods = []
      foodPrices.forEach(function (price) {
        if (includedFoods.indexOf(price.food) === -1) {
          includedFoods.push(price.food)
        }
      })
      sortedFoods = orgFoods.filter(food => Object.keys(food.canon).length === 0 || food.id === food.canon.food).filter(food => includedFoods.indexOf(food.id) !== -1).slice().sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      }) 
    } else if (show === 'withoutPrice') {
      let includedFoods = []
      foodPrices.forEach(function (price) {
        if (includedFoods.indexOf(price.food) === -1) {
          includedFoods.push(price.food)
        }
      })
      sortedFoods = orgFoods.filter(food => Object.keys(food.canon).length === 0 || food.id === food.canon.food).filter(food => includedFoods.indexOf(food.id) === -1).slice().sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      })
    } else if (show === 'canons') {
      sortedFoods = orgFoods.filter(food => Object.keys(food.canon).length > 0 && food.id !== food.canon.food).slice().sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      })
    }

    if (search !== '') {
      sortedFoods = sortedFoods.filter(food => food.name.toLowerCase().includes(search.toLowerCase()))
    }

    let foodsCount = sortedFoods.length

    if (show === 'canons') {
      html.push(<tr>
        <td><b>{t('Alanimike')}</b></td>
        <td></td>
        <td><b>{t('Päänimike')}</b></td>
      </tr>)
    }

	  sortedFoods.forEach(function (food) {
	  	let foodPrice = foodPrices.filter(price => price.food === food.id)
	  	html.push(<tr key={food.id}>
	  		<td className="foodName">
	  			{Object.keys(food.canon).length > 0 && food.id === food.canon.food && (<div className="fas fa-crown" />)} {food.name}
	  		</td>
	  		<td className="dashLine" />
		  	<td className="foodPrice" style={{textAlign: (show === 'canons' ? 'left' : 'center')}}>
          {show !== 'canons' && (<>
      			{foodPrice && foodPrice.length > 0 ? ((foodPrice[foodPrice.length - 1].price).toFixed(2) + ' €/kg') : '-'}
    			</>)}
          {show === 'canons' && (<>
            {Object.keys(food.canon).length > 0 ? food.canon.foodName : ''}
          </>)}
		  	</td>
        {show !== 'canons' && (
          <td style={{verticalAlign: 'middle'}}>
            <div
              className="foodEditBtn fas fa-edit"
              onClick={() => {
                getTagLogs(food.id)
                if (food.canon.id) {
                  if (food.canon.food === food.id) {
                    setSelectedCanonId(food.canon.id)
                    let canonsArr = orgFoods.filter(f => f.id !== food.id && f.canon.food === food.id).map(f => {
                      return {
                        value: f.id,
                        label: f.name
                      }
                    })
                    setSelectedCanons(canonsArr)
                    setSelectedCanonIds(canonsArr.map(f => f.value))
                    setHasCanon(true)
                  } else {
                    setIsChildCanon(true)
                    setCanonParentName(food.canon.foodName)
                  }
                }
                setEditModalFood({
                  id: food.id,
                  name: food.name
                })
                if (foodPrice && foodPrice.length > 0) {
                  setEditPrice(foodPrice[foodPrice.length - 1].price)
                  setOrigEditPrice(foodPrice[foodPrice.length - 1].price)
                }
                setEditModal(true)
              }}
            />
          </td>
        )}
	  	</tr>)
	  })

  	return (
  		<div>
        <div>
          <h2>{t('Näytä')}</h2>
          <button
            type="button"
            className={'button is-small' + (show === 'all' ? ' is-primary-background' : '')}
            style={{margin: '5px'}}
            onClick={() => {
              if (show !== 'all')
              setShow('all')
            }}
          >
            <Trans>Kaikki</Trans>
          </button>
          <button
            type="button"
            className={'button is-small' + (show === 'withPrice' ? ' is-primary-background' : '')}
            style={{margin: '5px'}}
            onClick={() => {
              if (show !== 'withPrice')
              setShow('withPrice')
            }}
          >
            <Trans>Hinnalliset</Trans>
          </button>
          <button
            type="button"
            className={'button is-small' + (show === 'withoutPrice' ? ' is-primary-background' : '')}
            style={{margin: '5px'}}
            onClick={() => {
              if (show !== 'withoutPrice')
              setShow('withoutPrice')
            }}
          >
            <Trans>Hinnattomat</Trans>
          </button>
          <button
            type="button"
            className={'button is-small' + (show === 'canons' ? ' is-primary-background' : '')}
            style={{margin: '5px'}}
            onClick={() => {
              if (show !== 'canons')
              setShow('canons')
            }}
          >
            <Trans>Alanimikkeet</Trans>
          </button>
        </div>
        <br />
        <input
          className="input has-text-primary"
          type="text"
          id="modal-edit-restaurant"
          placeholder={t('Hae ruokaa') + '...'}
          value={search}
          onChange={e => {
            setSearch(e.target.value)
          }}
        />
        <p
          className="title is-3 is-size-4-mobile is-uppercase"
          style={{margin: '0'}}
        >
          {foodsCount} {t('ruokaa listattuna')}
        </p>
        {show !== 'canons' && (
          <p
            style={{textAlign: 'center'}}
          >
            <i className="fas fa-crown" /> = {t('Ruoka on asetettu päänimikkeeksi')}
          </p>
        )}
	  		<table className="foodsTable">
	  			<tbody>
	  				{html}
	  			</tbody>
	  		</table>
  		</div>
  	)
  }

  const tagCloud = () => {
    let html = []

    selectedTags.slice().sort((a, b) => {
      if (a.tagName.toLowerCase() > b.tagName.toLowerCase()) return 1
      if (a.tagName.toLowerCase() < b.tagName.toLowerCase()) return -1
      return 0
    }).forEach(function (tag) {
      html.push(<div key={'tag-' + tag.id} className="tagCloud">
        {t(tag.tagName)}
        <div
          className="fas fa-times tagCloud__close"
          onClick={() => {
            removeTag(tag.id)
          }}
        />
      </div>)
    })

    return html
  }

  const editModalHtml = (
    <Modal
      isActive={editModal}
      okButtonOnly
      title={editModalFood ? editModalFood.name : ''}
      closeModalCallBack={() => {
        if (!loading) {
          setLoading(true)
          if (hasCanon && updateCanons) {
            let canonArr = selectedCanons && selectedCanons.length > 0 ? selectedCanons.map(canon => canon.value) : []
            editCanon(selectedCanonId, canonArr)
          } else if (updateFoods) {
            updateOrgFoods()
          } else {
            emptyEditVars()
          }
        }
      }}
    >
      { loading && (
        <p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>
      )}
      {!loading && (<>
        {!isChildCanon && (<>
          <h1
            className="title is-2 is-size-3-mobile is-uppercase"
            style={{lineHeight: '1'}}
          >
            {t('Hinta')}
          </h1>
          <h2>{t('Muokkaa hintaa')}</h2>
          <div className="control is-large has-text-primary is-clearfix">
            <input
              className="input is-large has-text-primary"
              style={{ fontSize: '16px', flex: 1 }}
              type="text"
              inputMode="numeric"
              value={
                editPrice ? editPrice.toString() : 0
              }
              onChange={e => {
                setEditPrice(validateNumeric(e.target.value))
              }}
            />
          </div>
          <h1
            className="title is-2 is-size-3-mobile is-uppercase"
            style={{lineHeight: '1', marginTop: '40px'}}
          >
            {t('Tagit')}
          </h1>
          <h2>{t('Lisää tageja')}</h2>
          <div className="control">
            <div className="select is-fullwidth hukkaselect">
              <HukkaSelect
                options={tagOptions ? tagOptions : []}
                placeholder={t('Lisää tageja')}
                value={[]}
                keepOpen
                onChange={e => {
                  addTag(e)
                }}
              />
            </div>
          </div>
          <h2>{t('Käytössä olevat tagit')}</h2>
          <div>
            {tagCloud()}
          </div>
        </>)}
        <h1
          className="title is-2 is-size-3-mobile is-uppercase"
          style={{lineHeight: '1', marginTop: '40px'}}
        >
          {t('Nimikkeet')}
        </h1>
        {isChildCanon && (<>
          <h2>{t('Päänimike')}</h2>
          <h2
            className="title is-2 is-size-3-mobile"
            style={{margin: '0', textTransform: 'none'}}
          >
            {canonParentName}
          </h2>
        </>)}
        {hasCanon && (<>
          <h2>{t('Alanimikkeet')}</h2>
          <div className="control">
            <div className="select is-fullwidth hukkaselect">
              <HukkaSelect
                options={canonOptions}
                placeholder={t('Lisää alanimike')}
                isMulti
                value={selectedCanons}
                onChange={e => {
                  if (initialCanons && selectedCanons && selectedCanons.length === 0) {
                    setInitialCanons(false)
                  } else {
                    setUpdateCanons(true)
                    setUpdateFoods(true)
                    if (e === null) {
                      setSelectedCanons([])
                    } else {
                      setSelectedCanons(e)
                    }
                  }
                }}
              />
            </div>
          </div>
          {!isChildCanon && selectedCanons.length === 0 && (
            <button
              type="button"
              className="button is-small is-primary-background"
              style={{margin: '5px'}}
              onClick={() => {
                setLoading(true)
                removeCanon()
              }}
            >
              <Trans>Poista ruuan päänimike nimitys</Trans>
            </button>
          )}
        </>)}
        {!hasCanon && !isChildCanon && (
          <div>
            <button
              type="button"
              className="button is-small is-primary-background"
              style={{margin: '5px'}}
              onClick={() => {
                setLoading(true)
                addCanon()
              }}
            >
              <Trans>Tee tästä ruuasta päänimike</Trans>
            </button>
          </div>
        )}
      </>)}
    </Modal>
  )

  const baseHtml = (
  	<div>
  		<h1
        className="title is-1 is-size-3-mobile is-uppercase"
        style={{margin: '0'}}
      >
        <Trans>Hintaluettelo</Trans>
      </h1>
      {error && (
        <div style={{margin: '10px 0'}}>
          <ErrorMessage msg={error} />
        </div>
      )}
  		{orgFoods && foodPrices && foodsList()}
      {editModalHtml}
  	</div>
  )

  return baseHtml
}

export default Foods

import React, { useState } from 'react'
import {
  instanceOf,
  arrayOf,
  string,
  shape,
  oneOfType,
  number,
} from 'prop-types'
import produce from 'immer'
import sumBy from 'lodash/sumBy'
import moment from 'moment'
import { Trans } from 'react-i18next'

const WasteReportTable = ({ tableData, lineWaste, startDate, endDate }) => {
  const [tableSort, setTableSort] = useState('foodName')
  const [reverseSort, setReverseSort] = useState(false)
  const sortedByFood =
    tableData &&
    tableData.reduce((obj, { data }) => {
      return produce(obj, draft => {
        const [foodName, waste, prepared, consumed, course, food, customers, wasteType] = data
        const addable = {
          foodName,
          waste,
          prepared,
          consumed,
          course,
          food,
          customers,
          wasteType
        }

        if (lineWaste === wasteType) {
          if (foodName in obj) {
            draft[foodName].push(addable)
          } else {
            // eslint-disable-next-line no-param-reassign
            draft[foodName] = [addable]
          }
        }

      })
    }, {})

  let sortedRows = []
  Object.keys(sortedByFood).forEach(key => {
    let preparedSum = parseFloat(sumBy(sortedByFood[key], 'prepared').toFixed(2))
    let wasteSum = parseFloat(sumBy(sortedByFood[key], 'waste').toFixed(2))
    let consumedSum = parseFloat(sumBy(sortedByFood[key], 'consumed').toFixed(2))
    let consumedPercent = preparedSum > 0 ? parseFloat((consumedSum / preparedSum * 100).toFixed(1)) : 0
    let customers = sumBy(sortedByFood[key], 'customers')
    let consumedPerCustomer = customers > 0 ? parseFloat(((preparedSum - wasteSum) / customers).toFixed(3)) : 0

    sortedRows.push({
      foodName: key,
      preparedSum: preparedSum,
      wasteSum: wasteSum,
      consumedSum: consumedSum,
      consumedPercent: consumedPercent,
      servedTimes: sortedByFood[key].length,
      consumedPerCustomer,
      customers
    })
  })

  const foodRows = () => {
    let rows = []
    sortedRows.sort(function (a, b) {
      let aa = a[tableSort]
      let bb = b[tableSort]
      if (typeof aa === 'string' && typeof bb === 'string') {
        aa = aa.toLowerCase()
        bb = bb.toLowerCase()
      }
      if (aa > bb) return reverseSort ? 1 : -1
      if (aa < bb) return reverseSort ? -1 : 1
      return 0
    }).forEach(sr => {
      if (sr.foodName !== 'plate') {
        rows.push(
          <tr key={sr.foodName}>
            <td>{sr.foodName}</td>
            <td>{sr.preparedSum}</td>
            <td>{sr.wasteSum}</td>
            <td>{sr.consumedSum}</td>
            <td>{sr.consumedPercent}%</td>
            <td>{sr.consumedPerCustomer}</td>
            <td>{sr.servedTimes}</td>
            <td>{sr.customers}</td>
          </tr>
        )
      }
    })
    return rows
  }
  

  return (
    <div>
      <div style={{ textAlign: '-webkit-center' }}>
        <table className="table is-striped is-bordered">
          <thead>
            <tr>
              <th><Trans>Alkupäivämäärä</Trans>: </th>
              <th>{startDate.format('L')}</th>
              <th><Trans>Loppupäivämäärä</Trans>: </th>
              <th>{endDate.format('L')}</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>

            <tr>
              <th onClick={() => {
                if (tableSort === 'foodName') {
                  setReverseSort(!reverseSort)
                } else {
                  setTableSort('foodName')
                  setReverseSort(false)
                }
              }} style={{cursor: 'pointer'}}><Trans>Ruoka</Trans> <i style={{float: 'right', lineHeight: '1.5', color: (tableSort === 'foodName' ? '#8ab51d' : '#1f78b4')}} className="fas fa-sort" /></th>
              <th onClick={() => {
                if (tableSort === 'preparedSum') {
                  setReverseSort(!reverseSort)
                } else {
                  setTableSort('preparedSum')
                  setReverseSort(false)
                }
              }} style={{cursor: 'pointer'}}><Trans>Tarjoilumäärä</Trans> kg <i style={{float: 'right', lineHeight: '1.5', color: (tableSort === 'preparedSum' ? '#8ab51d' : '#1f78b4')}} className="fas fa-sort" /></th>
              <th onClick={() => {
                if (tableSort === 'wasteSum') {
                  setReverseSort(!reverseSort)
                } else {
                  setTableSort('wasteSum')
                  setReverseSort(false)
                }
              }} style={{cursor: 'pointer'}}><Trans>Hukattu</Trans> kg <i style={{float: 'right', lineHeight: '1.5', color: (tableSort === 'wasteSum' ? '#8ab51d' : '#1f78b4')}} className="fas fa-sort" /></th>
              <th onClick={() => {
                if (tableSort === 'consumedSum') {
                  setReverseSort(!reverseSort)
                } else {
                  setTableSort('consumedSum')
                  setReverseSort(false)
                }
              }} style={{cursor: 'pointer'}}><Trans>Syöty arvio</Trans> kg <i style={{float: 'right', lineHeight: '1.5', color: (tableSort === 'consumedSum' ? '#8ab51d' : '#1f78b4')}} className="fas fa-sort" /></th>
              <th onClick={() => {
                if (tableSort === 'consumedPercent') {
                  setReverseSort(!reverseSort)
                } else {
                  setTableSort('consumedPercent')
                  setReverseSort(false)
                }
              }} style={{cursor: 'pointer'}}><Trans>Syöty arvio</Trans> % <i style={{float: 'right', lineHeight: '1.5', color: (tableSort === 'consumedPercent' ? '#8ab51d' : '#1f78b4')}} className="fas fa-sort" /></th>
              <th onClick={() => {
                if (tableSort === 'consumedPerCustomer') {
                  setReverseSort(!reverseSort)
                } else {
                  setTableSort('consumedPerCustomer')
                  setReverseSort(false)
                }
              }} style={{cursor: 'pointer'}}><Trans>Syöty per asiakas</Trans> kg <i style={{float: 'right', lineHeight: '1.5', color: (tableSort === 'consumedPerCustomer' ? '#8ab51d' : '#1f78b4')}} className="fas fa-sort" /></th>
              <th onClick={() => {
                if (tableSort === 'servedTimes') {
                  setReverseSort(!reverseSort)
                } else {
                  setTableSort('servedTimes')
                  setReverseSort(false)
                }
              }} style={{cursor: 'pointer'}}><Trans>Tarjoilukerrat</Trans> <i style={{float: 'right', lineHeight: '1.5', color: (tableSort === 'servedTimes' ? '#8ab51d' : '#1f78b4')}} className="fas fa-sort" /></th>
              <th onClick={() => {
                if (tableSort === 'customers') {
                  setReverseSort(!reverseSort)
                } else {
                  setTableSort('customers')
                  setReverseSort(false)
                }
              }} style={{cursor: 'pointer'}}><Trans>Asiakasmäärä</Trans> <i style={{float: 'right', lineHeight: '1.5', color: (tableSort === 'customers' ? '#8ab51d' : '#1f78b4')}} className="fas fa-sort" /></th>
            </tr>
          </thead>

          <tbody>{foodRows()}</tbody>
        </table>
      </div>

      <p className="is-primary"><Trans>Taulukko on suoraan kopioitavissa exceliin</Trans></p>
    </div>
  )
}

WasteReportTable.propTypes = {
  tableData: arrayOf(shape({ data: arrayOf(oneOfType([string, number])) }))
    .isRequired,
  startDate: instanceOf(moment).isRequired,
  endDate: instanceOf(moment).isRequired,
}

export default WasteReportTable

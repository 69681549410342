import axios from 'axios'
import camelCase from 'camelcase'
import mapObj from 'map-obj'

const toCamelCase = obj => {
  // eslint-disable-next-line func-names
  const mapFunc = function(key, val) {
    return [
      camelCase(key),
      // eslint-disable-next-line no-nested-ternary
      Array.isArray(val)
        ? // eslint-disable-next-line func-names
          val.map(function(o) {
            if (typeof o !== 'object') return o
            return mapObj(o, mapFunc)
          })
        : val === Object(val) && !Array.isArray(val)
        ? mapObj(val, mapFunc)
        : val,
    ]
  }
  return mapObj(obj, mapFunc)
}

const handleError = error => {
  if (error && error.response) {
    return error.response
  }
  return { data: 'Yhteys palvelimeen on katkennut.', status: 500 }
}

// Add a response interceptor and camelCase return data (for JS)
axios.interceptors.response.use(
  response => {
    if (response.data) {
      // If response is array of objects
      // toCamelCase would return {0: object1, 1: object2...}
      if (Array.isArray(response.data)) {
        response.data = Object.values(toCamelCase(response.data))
      } else {
        response.data = toCamelCase(response.data)
      }
    }

    return response
  },
  error => {
    const camelCasedError = { ...error }
    camelCasedError.response.data = toCamelCase(error.response.data)

    return Promise.reject(camelCasedError)
  }
)

export const BASE_URL = process.env.REACT_APP_API_URL
export const TOKEN_URL = `${BASE_URL}token/`
export const PROFILE_URL = `${BASE_URL}profile/`

export const RESTAURANTS_URL = `${BASE_URL}restaurants/`
export const RESTAURANTTYPES_URL = `${BASE_URL}restauranttypes/`
export const MEALSERVINGS_URL = `${BASE_URL}mealservings/`
export const MEALTYPES_URL = `${BASE_URL}mealtypes/`
export const WASTETYPES_URL = `${BASE_URL}wastetypes/`
export const FOODS_URL = `${BASE_URL}foods/`
export const ORGFOODS_URL = `${BASE_URL}orgfoods/`
export const FOODPRICES_URL = `${BASE_URL}foodprices/`
export const MEALSERVINGLOGS_URL = `${BASE_URL}mealservinglogs/`
export const ORGS_URL = `${BASE_URL}orgs/`
export const HUKKAPROFILES_URL = `${BASE_URL}hukkaprofiles/`
export const COURSES_URL = `${BASE_URL}courses/`
export const PWD_URL = `${BASE_URL}pwd/`
export const GENERICSURVEYS_URL = `${BASE_URL}genericsurveys/`
export const GENERICSURVEYLINES_URL = `${BASE_URL}genericsurveylines/`
export const GENERICSURVEYLOGS_URL = `${BASE_URL}genericsurveylogs/`
export const HUKKASERVICES_URL = `${BASE_URL}hukkaservices/`
export const HUKKASERVICESUBSCRIPTIONS_URL = `${BASE_URL}hukkaservicesubscriptions/`
export const AVGWASTE_URL = `${BASE_URL}avgwaste/`
export const SCORES_URL = `${BASE_URL}scores/`
export const ORDERESTIMATE_URL = `${BASE_URL}orderestimate/`
export const MENUORDERESTIMATE_URL = `${BASE_URL}menuorderestimate/`
export const WEEKSERVINGSTATS_URL = `${BASE_URL}weekservingstats/`
export const YEARSERVINGSTATS_URL = `${BASE_URL}yearservingstats/`
export const HUKKATAGS_URL = `${BASE_URL}hukkatags/`
export const HUKKATAGLOGS_URL = `${BASE_URL}hukkataglogs/`
export const FOODCANONNAMES_URL = `${BASE_URL}foodcanonnames/`
export const SIMPLEINSIGHT_URL = `${BASE_URL}simpleinsight/`
export const FOODSTATS_URL = `${BASE_URL}foodstats/`
export const SEASONS_URL = `${BASE_URL}seasons/`
export const SCORELOGS_URL = `${BASE_URL}scorelogs/`
export const SERVINGPREDICTION_URL = `${BASE_URL}servingprediction/`
export const FORMFIELDTEMPLATES_URL = `${BASE_URL}formfieldtemplates/`
export const FORMTEMPLATES_URL = `${BASE_URL}formtemplates/`
export const FIELDORDERS_URL = `${BASE_URL}fieldorders/`
export const FORMS_URL = `${BASE_URL}forms/`
export const FORMROWS_URL = `${BASE_URL}formrows/`
export const FORMFIELDS_URL = `${BASE_URL}formfields/`
export const MONITORINGPLANS_URL = `${BASE_URL}monitoringplans/`
export const MONITORINGPLANUPLOAD_URL = `${BASE_URL}monitoringplanfileupload/`


export const hydrateTokenHeader = token => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Token ${token}`
  }
}

export const login = async (username, password) => {
  try {
    const tokenRes = await axios.post(TOKEN_URL, {
      username,
      password,
    })
    const { token } = tokenRes.data
    axios.defaults.headers.common.Authorization = `Token ${token}`

    const profileRes = await axios.get(PROFILE_URL)

    return { ...profileRes, token }
  } catch (error) {
    return handleError(error)
  }
}

export const get = async (url, body) => {
  try {
    const resp = await axios.get(url, {params: body})
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const post = async (url, body) => {
  try {
    const resp = await axios.post(url, body)
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const patch = async (url, id, body) => {
  try {
    const resp = await axios.patch(`${url}${id}/`, body)
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const put = async (url, body) => {
  try {
    const resp = await axios.put(`${url}`, body)
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const del = async (url, id) => {
  try {
    const resp = await axios.delete(`${url}${id}/`)
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const postFile = async (url, body) => {
  try {
    const resp = await axios.post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}

export const patchFile = async (url, id, body) => {
  try {
    const resp = await axios.patch(`${url}${id}/`, body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return resp
  } catch (error) {
    return handleError(error)
  }
}
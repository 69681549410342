import React from 'react'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next';
import i18n from 'i18next'

const InfoPage = () => {
  const { loggedIn, isStaff, orgAdmin } = useSelector(state => state.user)
  
  return (
    <div className="info">
      <h1 className="title is-1 is-size-3-mobile is-uppercase" style={{marginBottom: '0'}}>
        <Trans>Info</Trans>
      </h1>
      <h2 className="title is-2 is-size-4-mobile is-uppercase" style={{lineHeight: '1'}}>
        <Trans>Opastusvideot</Trans>
      </h2>
      <div>
        <div
          className="videoGrid"
        >
          {i18n.language === 'fi' && (<>
            {loggedIn && (isStaff || orgAdmin) && (<>
              <div>
                <p><b>
                  <Trans>Ruokalistan lisääminen</Trans>
                </b></p>
                <iframe title="Hävikkiseuranta ja sen hyödyt" width="350" height="250" src="https://www.youtube.com/embed/_Wz3R4IesKw" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
              </div>
              <div>
                <p><b>
                  <Trans>Ravintolan lisääminen</Trans>
                </b></p>
                <iframe title="Ravintolan lisääminen" width="350" height="250" src="https://www.youtube.com/embed/aSbUA3By2tM" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
              </div>
              <div>
                <p><b>
                  <Trans>Käyttäjän lisääminen</Trans>
                </b></p>
                <iframe title="Käyttäjän lisääminen" width="350" height="250" src="https://www.youtube.com/embed/d0REWywmxQw" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
              </div>
            </>)}
            <div>
              <p><b>
                <Trans>Raportit</Trans>
              </b></p>
              <iframe title="Raportit" width="350" height="250" src="https://www.youtube.com/embed/Ntsf66r6LMI" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>
            <div>
              <p><b>
                <Trans>Menekkiarvio</Trans>
              </b></p>
              <iframe title="Menekkiarvio" width="350" height="250" src="https://www.youtube.com/embed/nQAd40ANPZM" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>
            <div>
              <p><b>
                <Trans>Kysely</Trans>
              </b></p>
              <iframe title="Kysely" width="350" height="250" src="https://www.youtube.com/embed/uIg0U8IszQ8" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>
            <div>
              <p><b>
                <Trans>Hävikkiseuranta</Trans>
              </b></p>
              <iframe title="Hävikkiseuranta" width="350" height="250" src="https://www.youtube.com/embed/w5axO2xeuSI" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>
            <div>
              <p><b>
                <Trans>Hävikin kirjaaminen</Trans>
              </b></p>
              <iframe title="Hävikin kirjaaminen" width="350" height="250" src="https://www.youtube.com/embed/wgRiuwf2ckI" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>
            <div>
              <p><b>
                <Trans>Taikametsä</Trans>
              </b></p>
              <iframe title="Taikametsä" width="350" height="250" src="https://www.youtube.com/embed/nB5_ItTjQO0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>
          </>)}

          {i18n.language !== 'fi' && (<>
            <div>
              <p><b>
                <Trans>Inputting the food waste</Trans>
              </b></p>
              <iframe title="Inputting the food waste" width="350" height="250" src="https://www.youtube.com/embed/mOelYZUkiRs" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>

            <div>
              <p><b>
                <Trans>Changing the password</Trans>
              </b></p>
              <iframe title="Changing the password" width="350" height="250" src="https://www.youtube.com/embed/b1gdW7wMTLw" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>
            <div>
              <p><b>
                <Trans>Adding a user</Trans>
              </b></p>
              <iframe title="Adding a user" width="350" height="250" src="https://www.youtube.com/embed/u9l-0ZzWxA0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>
            <div>
              <p><b>
                <Trans>Adding a restaurant</Trans>
              </b></p>
              <iframe title="Adding a restaurant" width="350" height="250" src="https://www.youtube.com/embed/TAfDjnHORxc" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>
          </>)}
        </div>
      </div>
    </div>
  )
}

export default InfoPage

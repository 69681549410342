import React from 'react'
import Select from 'react-select/creatable'
import { func, arrayOf, shape, string, bool, oneOf } from 'prop-types'

const HukkaSelect = ({
  options,
  value,
  onChange,
  formatCreateLabel,
  isLoading,
  onCreateOption,
  placeholder,
  isDisabled,
  isMulti,
  disableText,
  keepOpen
}) => {
  const doNothing = () => {}

  return (
    <Select
      options={options}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      placeholder={placeholder}
      styles={{
        placeholder: provided => {
          return { ...provided, color: '#2bdbac' }
        },
        menu: provided => {
          return { ...provided, zIndex: 41 }
        },
      }}
      isMulti={isMulti || false}
      closeMenuOnSelect={!isMulti && !keepOpen}
      isSearchable={!disableText}
      value={value}
      onChange={e => onChange(e)}
      isLoading={isLoading}
      isDisabled={isDisabled || false}
      formatCreateLabel={formatCreateLabel || doNothing}
      onCreateOption={onCreateOption || doNothing}
    />
  )
}

HukkaSelect.defaultProps = {
  formatCreateLabel: null,
  isLoading: null,
  onCreateOption: null,
  value: null,
  isDisabled: null,
  isMulti: null,
}

HukkaSelect.propTypes = {
  onChange: func.isRequired,
  options: arrayOf(shape({ label: string, value: string })).isRequired,
  value: oneOf(
    shape({ value: string, label: string }),
    arrayOf(shape({ value: string, label: string }))
  ),
  formatCreateLabel: func,
  isLoading: bool,
  onCreateOption: func,
  placeholder: string.isRequired,
  isDisabled: bool,
  isMulti: bool,
}

export default HukkaSelect

import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import { get, FORMROWS_URL, FORMFIELDS_URL } from '../../utility/api'
import HukkaSelect from '../hukkaSelect'

const History = () => {
  const { t } = useTranslation()
  const {restaurants, hukkaProfiles, formFieldTemplates, formTemplates, fieldOrders, forms} = useSelector(
    state => state
  )
  const [selectedRestaurant, setSelectedRestaurant] = useState(null)
  const [selectedForm, setSelectedForm] = useState(null)
  const [formRows, setFormRows] = useState([])
  const [formFields, setFormFields] = useState([])
  const [onlyViewActiveForms, setOnlyViewActiveForms] = useState(true)

  const getFormData = async (restaurant, form) => {
    let payload = {
      restaurant: restaurant ? restaurant : selectedRestaurant.value,
      form: form ? form : selectedForm.value
    }
    const resp = await get(FORMROWS_URL, payload)

    if (resp.status === 200) {
      setFormRows(resp.data)
      const resp2 = await get(FORMFIELDS_URL, payload)
      
      if (resp2.status === 200) {
        setFormFields(resp2.data)
      } else {
        console.log({error: resp2.data})
      }
    } else {
      console.log({error: resp.data})
    }
  }

  const restaurantOptions = restaurants.slice()
  .sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    return 0
  })
  .map(r => ({
    value: r.id,
    label: r.name,
  }))
  
  const formOptions = selectedRestaurant ? forms.filter(f => f.restaurant === selectedRestaurant.value && (onlyViewActiveForms === false || (f.active === true && formTemplates.find(ft => ft.id === f.template).active === true))).map(f => ({
    value: f.id,
    label: formTemplates.find(ft => ft.id === f.template).name,
  })) : []

  const formRowsHtml = () => {
    let html = []

    if (formRows && formRows.length > 0 && formFields && formFields.length > 0) {
      let formTemplate = forms.find(f => f.id === selectedForm.value).template
      let columns = fieldOrders.filter(fo => fo.formTemplate === formTemplate).slice().sort((a, b) => {
        if (a.order > b.order) return 1
        if (a.order < b.order) return -1
        return 0
      })
  
      let columnHeadersHtml = []
      let types = []
      columnHeadersHtml.push(<td><b>{t('pvm')}</b></td>)
      columnHeadersHtml.push(<td><b>{t('klo')}</b></td>)
  
      columns.forEach(function (column) {
        types.push(formFieldTemplates.find(fft => fft.id === column.fieldTemplate).type)
        columnHeadersHtml.push(<td><b>{formFieldTemplates.find(fft => fft.id === column.fieldTemplate).name}</b></td>)
      })

      columnHeadersHtml.push(<td><b>{t('kuitannut')}</b></td>)
  
      html.push(<thead>
        <tr>
          {columnHeadersHtml}
        </tr>
      </thead>)
  
      let rowsHtml = []

      let rows = formRows.sort((a, b) => {
        if (moment(a.created).unix() < moment(b.created).unix()) return 1
        if (moment(a.created).unix() > moment(b.created).unix()) return -1
        return 0
      })
  
      rows.forEach(function (row) {
        let fields = formFields.filter(ff => ff.row === row.id)
        let columnHtml = []
  
        columns.forEach(function (column, index) {
          let field = fields.find(f => f.row === row.id && f.field === column.fieldTemplate)
          let type = formFieldTemplates.find(fft => fft.id === column.fieldTemplate).type
          columnHtml.push(<td key={'column_' + row.id + column.id}>{field ? field[type + 'Value'] : ' - '}</td>)
        })

        let user = row.completedBy ? hukkaProfiles.find(hp => hp.user === row.completedBy) : false
  
        rowsHtml.push(<tr key={'row_' + row.id}>
          <td>{moment(row.updated).format('DD.MM.YYYY')}</td>
          <td>{moment(row.updated).format('HH:mm')}</td>
          {columnHtml}
          <td>{user ? user.email : ''}</td>
        </tr>)
      })
  
      html.push(<tbody>{rowsHtml}</tbody>)
    }

    return (<table className="table is-striped is-bordered" style={{margin: '5px auto'}}>
        {html}
    </table>)
  }

  return (<div>
  	<h1
    className="title is-1 is-size-3-mobile is-uppercase"
    style={{margin: '0'}}
    >
        <Trans>Historia</Trans>
    </h1>

    <div style={{textAlign: 'left', margin: '10px 0'}}>
      <HukkaSelect
        options={restaurantOptions}
        value={selectedRestaurant}
        placeholder={t('Valitse ravintola')}
        onChange={e => {
          setSelectedRestaurant(e)
          setSelectedForm(null)
        }}
      />
    </div>

    <label>
      <input
        type="checkbox"
        checked={onlyViewActiveForms}
        style={{marginRight: '5px'}}
        onChange={e => {
          setOnlyViewActiveForms(e.target.checked)
        }}
      />
      {t('Näytä vain aktiiviset lomakkeet')}
    </label>

    <div style={{textAlign: 'left', margin: '10px 0'}}>
      <HukkaSelect
        options={formOptions.sort((a, b) => {
            if (a.label.toLowerCase() > b.label.toLowerCase()) return 1
            if (a.label.toLowerCase() < b.label.toLowerCase()) return -1
            return 0
          })}
        value={selectedForm}
        placeholder={t('Valitse lomake')}
        onChange={e => {
          setSelectedForm(e)
          if (selectedRestaurant) {
            getFormData(null, e.value)
          }
        }}
      />
    </div>

    <div style={{marginTop: '30px'}}>
        {selectedRestaurant && selectedForm && formRowsHtml()}
    </div>
  </div>)
}

export default History

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom";
import i18n from 'i18next'
import 'moment/locale/fi'
import 'moment/locale/sv'
import {
  toggleSideNav,
  toggleProfileMenu,
  toggleLangMenu,
  logout
} from '../../actions'

const Nav = () => {
  const { sideNav, profileMenu, langMenu, user } = useSelector(
    state => state
  )
  const { t } = useTranslation()
  const [currentLang, setCurrentLang] = useState(i18n.language ? i18n.language.substring(0,2) : 'fi')
  const dispatch = useDispatch()
  const history = useHistory()

  const linkClick = (link) => {
    history.push(link)
  }

  const changeLang = (lang) => {
    i18n.changeLanguage(lang)
    setCurrentLang(lang)
    window.location.reload()
  }

  return (
    <nav
      className="topNav"
      role="navigation"
      aria-label="main navigation"
    >
      <div className='topNav__left'>
      { user && user.username && (
        <div className="burger">
          <a
            role="button"
            className="burger__link"
            aria-label="menu"
            aria-expanded="false"
            onClick={() => dispatch(toggleSideNav(!sideNav))}
            onKeyPress={() => dispatch(toggleSideNav(!sideNav))}
            tabIndex={0}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
      )}
        <img id="hukka-logo" src="/img/hukka_logo.png" alt="Hukka logo" />
      </div>

      <div className='topNav__right'>
        <div className='navMinimenu'>
          {langMenu === true && (
            <div
              className='navMinimenu__bgClose'
              onClick={() => {
                dispatch(toggleLangMenu(false))
              }}
            ></div>
          )}
          <div
            className='navMinimenu__toggle navMinimenu__toggle--lang'
            onClick={() => {
              dispatch(toggleLangMenu(!langMenu))
              dispatch(toggleProfileMenu(false))
            }}
          >
            <span style={{fontSize: '20px'}}>
              { currentLang.toUpperCase() }
            </span>
          </div>
          <div className={'navMinimenu__menu' + (langMenu ? ' navMinimenu__menu--open' : '')}>
            <span className='navMinimenu__title'>{t('Valitse kieli')}</span>
            <span
              className={'navMinimenu__link' + (currentLang === 'fi' ? ' navMinimenu__link--active' : '')}
              onClick={() => {
                changeLang('fi')
              }}
            >
              {t('FI')}
            </span>
            <span
              className={'navMinimenu__link' + (currentLang === 'en' ? ' navMinimenu__link--active' : '')}
              onClick={() => {
                changeLang('en')
              }}
            >
              {t('EN')}
            </span>
          </div>
        </div>

        { user && user.username && (
          <div className='navMinimenu'>
            {profileMenu === true && (
              <div
                className='navMinimenu__bgClose'
                onClick={() => {
                  dispatch(toggleProfileMenu(false))
                }}
              ></div>
            )}
            <div
              className='navMinimenu__toggle'
              onClick={() => {
                dispatch(toggleProfileMenu(!profileMenu))
                dispatch(toggleLangMenu(false))
              }}
            >
              <span>
                {user.username.charAt(0).toUpperCase() }
              </span>
            </div>
            <div className={'navMinimenu__menu' + (profileMenu ? ' navMinimenu__menu--open' : '')}>
              <span className='navMinimenu__title'>{t('Omat asetukset')}</span>
              <span
                className='navMinimenu__link'
                onClick={() => linkClick('/profile')}
              >
                {t('Ryhmät')}
              </span>
              <span
                className='navMinimenu__link'
                onClick={() => linkClick('/profile')}
              >
                {t('Salasanan vaihto')}
              </span>

              <span
                className='navMinimenu__title navMinimenu__title--link'
                onClick={() => {
                  dispatch(logout())
                }}
              >
                {t('Kirjaudu ulos')}
              </span>
            </div>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Nav
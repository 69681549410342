import React, { useState } from 'react'
import moment from 'moment'
import { string, shape, arrayOf } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next';
import ErrorMessage from '../errorMessage'
import { post, get, FOODPRICES_URL, FOODS_URL, MEALSERVINGLOGS_URL, MEALSERVINGS_URL, del } from '../../utility/api'
import {
  emptyAddWasteForm,
  removeMealServingLog,
  removeMealServing,
  renewMealServings,
  renewMealServingLogs,
  updateOrgFoods,
  addFoodCourseDefault,
  updateFoodCourseDefault
} from '../../actions'
import Modal from '../modal'
import HukkaSelect from '../hukkaSelect'

const ServingBox = ({
  mealServing,
  mealTypeName,
  mealTypeId,
  mealServingLogs,
  groupMealServingIds,
  groupMealServingLogs,
  monday,
  selectedRestaurants
}) => {
  const { org } = useSelector(
    state => state.user
  )
  const {
    foodCourseDefaults
  } = useSelector(state => state)
  const [selectedFood, setSelectedFood] = useState(null)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [loading, setLoading] = useState(false)
  const [modalError, setModalError] = useState(null)
  const [error, setError] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [foodPrice, setFoodPrice] = useState(0)
  const [origFoodPrice, setOrigFoodPrice] = useState(0)
  const [foodPriceError, setFoodPriceError] = useState('')
  const { orgFoods, courses, wasteTypes, mealServings } = useSelector(state => state)
  const dispatch = useDispatch()
  const currentDate = moment(new Date())
  const servingDate = moment(mealServing.mealServing.servingDatetime)
  const diff = servingDate.diff(currentDate, 'days')

  const { servingNotes } = mealServing.mealServing
  const time = moment(mealServing.mealServing.servingDatetime).format('kk:mm')

  const getMealServings = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServings(resp.data))
    }
  }

  const getMealServingLogs = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGLOGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServingLogs(resp.data))
    }
  }

  const handleMealServingLogDelete = async id => {
    // Find the serving user actually clicked to delete, get food and course from it
    const servingLog = groupMealServingLogs.find(msl => msl.id === id)
    const { food, course } = servingLog

    // mealServingLogs with same food and course are considered same
    // when user deletes one log, we should remove all grouped similar logs
    const servingLogsToDelete = groupMealServingLogs.filter(
      msl => msl.course === course && msl.food === food
    )

    const resp = await Promise.all(
      servingLogsToDelete.map(msl => del(MEALSERVINGLOGS_URL, msl.id))
    )

    resp.forEach((r, i) => {
      if (r.status === 204) {
        dispatch(removeMealServingLog(servingLogsToDelete[i].id))
      } else {
        setError(r.data.detail)
        setTimeout(() => setError(null), 2500)
      }
    })
  }

  const servingLogs = mealServingLogs.map(msl => {
    const food = orgFoods.find(f => f.id === msl.food)
    const foodName = food ? food.name : '-'
    const course = courses.find(c => msl.course === c.id)
    const courseName = course ? t(course.name) : '-'
    const wasteTypeName = wasteTypes ? wasteTypes.find(wt => wt.id === msl.wasteType).name : '-'

    if ((foodName === '-' && courseName === '-') || wasteTypeName !== 'line') {
      return false
    }

    return (
      <div
        key={`${msl.id}`}
        className="column is-size-6"
        style={{ textAlign: 'left' }}
      >
        <div className="column is-size-5">
          {
            diff > -10 && (
              <span
                className="delete is-large"
                onClick={() => handleMealServingLogDelete(msl.id)}
                onKeyPress={() => handleMealServingLogDelete(msl.id)}
                role="button"
                tabIndex={0}
                alt="delete row"
                style={{ marginRight: '30px' }}
              />
            )
          }
          <span>{foodName}</span>
          <span style={{ marginLeft: '35px' }}>{courseName}</span>
        </div>
      </div>
    )
  })

  const foodOptions = orgFoods && orgFoods.slice()
  .filter(f => f.name !== null && (Object.keys(f.canon).length === 0 || f.id === f.canon.food))
  .sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    return 0
  }).map(f => ({
    value: f.id,
    label: f.name,
  }))

  const courseOptions = courses.map(c => ({ value: c.id, label: t(c.name) }))

  const handleOptionCreate = async name => {
    setLoading(true)
    const resp = await get(FOODS_URL + '?search=' + name)

    if (resp.status === 200) {
      let find = resp.data.find(f => f.name === name)

      if (find) {
        const { id } = find
        dispatch(updateOrgFoods(find))
        setSelectedFood({ value: id, label: name })
        setLoading(false)
      } else {
        const resp2 = await post(FOODS_URL, {name})      
        if (resp2.status === 201) {
          const { id } = resp2.data
          dispatch(updateOrgFoods(resp2.data))
          setSelectedFood({ value: id, label: name })
          setLoading(false)
        } else {
          setLoading(false)
          setModalError(resp2.data.detail)
        }
      }
    } else {
      const resp2 = await post(FOODS_URL, name)      
      if (resp2.status === 201) {
        const { id } = resp2.data
        dispatch(updateOrgFoods(resp2.data))
        setSelectedFood({ value: id, label: name })
        setLoading(false)
      } else {
        setLoading(false)
        setModalError(resp2.data.detail)
      }
    }

  }

  const validateNumeric = (str) => {
    let orig = str.toString()
    if (orig.length === 0) {
      return '0'
    }
    let firstChar = orig.substr(0, 1)
    let lastChar = orig.substr(orig.length - 1)
    if (lastChar === ',') {
      orig += '.'
    }
    let validated = orig.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")
    if (firstChar === '0') {
      if (validated.substr(1, 1) !== '.') {
        validated = validated.substr(1)
      }
    }
    return validated.toString()
  }

  const handleModalSubmit = async () => {
    const reqData = {
      meal_serving: mealServing.id,
      food: selectedFood.value,
      waste_type: wasteTypes.find(wt => wt.name === 'line').id,
      prepared_amount_grams: 0,
      waste_grams: 0,
      course: selectedCourse.value
    }

    let payload = []

    groupMealServingIds.forEach(function (msId) {
      payload.push({ ...reqData, meal_serving: msId })
    })
    
    const resp = await post(MEALSERVINGLOGS_URL, payload)

    if (resp.status === 201) {
      getMealServingLogs(monday.format('YYYY-MM-DD'), monday.clone().add(7, 'days').format('YYYY-MM-DD'))
      dispatch(emptyAddWasteForm())
      let obj = resp.data[0]
      let food = foodCourseDefaults.find(fcd => fcd.id === obj.food)
      if (food && food.id) {
        if (food.course.id !== obj.course) {
          dispatch(updateFoodCourseDefault({
            id: obj.food,
            name: obj.foodName,
            course: {
              id: obj.course,
              name: courses.find(c => c.id === obj.course).name
            }
          }))
        }
      } else {
        dispatch(addFoodCourseDefault({
          id: obj.food,
          name: obj.foodName,
          course: {
            id: obj.course,
            name: courses.find(c => c.id === obj.course).name
          }
        }))
      }
    } else {
      setModalError(resp.data.detail)
    }
    if (foodPrice !== origFoodPrice) {
      const resp2 = await post(FOODPRICES_URL, {
        food: selectedFood.value,
        org: org,
        price: foodPrice
      })

      if (resp2.status !== 201) {
        setError(t('Ruuan hintaa ei voitu päivittää'))
      }
    }

    setFoodPrice(0)
    setOrigFoodPrice(0)
    setModalOpen(false)
  }

  const fetchFoodPrice = async (foodId) => {
    const resp = await get(FOODPRICES_URL)

    if (resp.status === 200) {
      let arr = resp.data
      if (Array.isArray(arr)) {
        arr.forEach(function (price) {
          if (price.food === foodId) {
            setFoodPrice(price.price)
            setOrigFoodPrice(price.price)
          }
        })
      }
    } else {
      setFoodPriceError(resp.data.detail)
    }
  }

  const servingLogFormModal = (
    <Modal
      isActive={modalOpen}
      closeModalCallBack={() => {
        setSelectedFood(null)
        setModalOpen(false)
      }}
      hideModalSubmit={
        !selectedFood || !selectedCourse
      }
      errors={modalError}
      title={t('Lisää ruoka')}
      submitCallback={handleModalSubmit}
    >
      <Modal.Field label={t('Ruokalaji')}>
        <HukkaSelect
          placeholder={t('Valitse ruokalaji')}
          options={foodOptions}
          value={selectedFood}
          onChange={e => {
            setSelectedFood(e)
            setFoodPrice(0)
            fetchFoodPrice(e.value)
            let food = foodCourseDefaults.find(fcd => fcd.id === e.value)
            if (!food || typeof food === 'undefined') {
              food = orgFoods.find(of => of.id === e.value)
            }
            if (food && food.course && food.course.id) {
              setSelectedCourse({
                value: food.course.id,
                label: food.course.name
              })
            }
          }}
          isLoading={loading}
          formatCreateLabel={input => t('Luo uusi ruoka')+': '+input}
          onCreateOption={label => handleOptionCreate(label)}
        />
      </Modal.Field>

      <Modal.Field label={t('Aterian osa')}>
        <HukkaSelect
          onChange={e => setSelectedCourse(e)}
          placeholder={t('Valitse aterian osa')}
          value={selectedCourse}
          options={courseOptions}
        />
      </Modal.Field>

      <Modal.Field label={t('Ruuan hinta')+' (€/Kg)'}>
        {foodPriceError && (
          <div style={{marginTop: '10px'}}>
            <ErrorMessage msg={foodPriceError} />
          </div>
        )}
        <input
          className="is-large has-text-primary"
          style={{ fontSize: '16px', flex: 1 }}
          type="text"
          inputMode="numeric"
          disabled={!selectedFood}
          value={(foodPrice) || 0}
          onChange={e => setFoodPrice(validateNumeric(e.target.value))}
        />
      </Modal.Field>
    </Modal>
  )

  const handleServingRemoval = async () => {
    const resp = await Promise.all(
      groupMealServingIds.map(msId =>
        del(MEALSERVINGS_URL, msId)
      )
    )

    resp.forEach((r, rIndex) => {
      if (r.status === 204) {
        dispatch(removeMealServing(groupMealServingIds[rIndex]))
      } else {
        setError(r.data.detail)
        setRemoveModalOpen(false)
      }
    })
  }

  const addForAllSelected = async () => {
    let selectedWithoutServing = []
    selectedRestaurants.forEach(function (r) {
      if (!mealServings.find(ms => moment(ms.servingDatetime).format('DD.MM.YYYY') === moment(servingDate).format('DD.MM.YYYY') && r.value === ms.restaurant && mealTypeId === ms.mealType)) {
        selectedWithoutServing.push(r.value)
      }
    })

    if (selectedWithoutServing.length > 0) {
      let payload = []
      selectedWithoutServing.forEach(function (r) {
        payload.push({
          meal_type: mealTypeId,
          serving_datetime: servingDate,
          restaurant: r,
          servingNotes: ''
        })
      })

      let servingLogsToCopy = mealServingLogs.filter(msl => msl.mealServing === groupMealServingIds[0] && msl.food !== '' && msl.food !== null)

      const resp = await post(MEALSERVINGS_URL, payload)

      if (resp.status === 201) {
        let servingArrayToPost = []
        resp.data.forEach((rd, rdIndex) => {
          servingLogsToCopy.forEach(function (sl) {
            servingArrayToPost.push({
              meal_serving: rd.id,
              food_name: sl.foodName,
              food: sl.food,
              waste_type: sl.wasteType,
              course: sl.course,
              waste_grams: 0,
              prepared_amount_grams: 0,
              consumption_grams: 0
            })
          })
        })

        const logsResp = await post(MEALSERVINGLOGS_URL, servingArrayToPost)

        if (logsResp.status === 201) {
          getMealServings(monday.format('YYYY-MM-DD'), monday.clone().add(7, 'days').format('YYYY-MM-DD'))
          getMealServingLogs(monday.format('YYYY-MM-DD'), monday.clone().add(7, 'days').format('YYYY-MM-DD'))
        } else {
          setError(t('Jokin meni vikaan'))
        }
      } else {
        setError(t('Jokin meni vikaan'))
      }
    }
  }

  const removeServingModal = (
    <div className={`modal ${removeModalOpen ? 'is-active' : null}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title"><Trans>Vahvista poisto</Trans></p>
        </header>
        <section className="modal-card-body">
          {t('Haluatko varmasti poistaa tämän kattauksen')+'?'}
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-success is-danger is-large"
            onClick={handleServingRemoval}
          >
            <Trans>Kyllä</Trans>
          </button>
          <button
            type="button"
            className="button is-large"
            onClick={() => setRemoveModalOpen(false)}
          >
            <Trans>Ei</Trans>
          </button>
        </footer>
      </div>
    </div>
  )

  return (
    <div id={mealServing.mealServing.id} className="box is-full" style={{position: 'relative'}}>
      {
        diff > -10 && (
          <div
            className="removeBtn fas fa-times"
            onClick={() => {
              setRemoveModalOpen(true)
            }}
          />
        )
      }
      <div className="columns">
        <div className="column is-size-5" style={{ textAlign: 'left' }}>
          {t(mealTypeName)} <Trans>klo</Trans> {time}
        </div>

        <div className="column is-size-5" style={{ textAlign: 'left' }}>
          <Trans>Huomiot</Trans>: {servingNotes}
        </div>
      </div>

      {servingLogs}

      <button
        type="button"
        className="button is-small is-primary"
        onClick={() => {
          setSelectedFood(null)
          setSelectedCourse(null)
          setModalOpen(true)
        }}
      >
        <Trans>Lisää ruoka</Trans>
      </button>
      <button
        style={{float: 'right', padding: '30px 20px', maxWidth: '300px', whiteSpace: 'break-spaces'}}
        type="button"
        className="button is-small is-green"
        onClick={() => {
          addForAllSelected()
        }}
      >
        <Trans>Kopioi kattaus loppuihin valittuihin ravintoloihin</Trans>
      </button>

      <div style={{ marginTop: '15px' }}>
        <ErrorMessage msg={error} />
      </div>

      {servingLogFormModal}
      {removeServingModal}
    </div>
  )
}

ServingBox.propTypes = {
  mealTypeName: string.isRequired,
  mealServing: shape({ servingDatetime: string }).isRequired,
  mealServingLogs: arrayOf(shape({ id: string, foodName: string })).isRequired,
  groupMealServingIds: arrayOf(string).isRequired,
  groupMealServingLogs: arrayOf(
    shape({ id: string, food: string, course: string })
  ).isRequired,
}

export default ServingBox

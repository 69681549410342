import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import HukkaSelect from '../hukkaSelect'
import moment from 'moment'
import { get, post, patch, FORMROWS_URL, FORMFIELDS_URL } from '../../utility/api'
import { updateFormRows, updateFormFields } from '../../actions'

var cron = require('cron-parser');

const Actions = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {restaurants, formFieldTemplates, formTemplates, fieldOrders, forms, formRows, formFields} = useSelector(
    state => state
  )
  const user = useSelector(
    state => state.user
  )
  const [selectedRestaurant, setSelectedRestaurant] = useState(null)
  const [currentFormFields, setCurrentFormFields] = useState([])
  const [currentFormFieldValues, setCurrentFormFieldValues] = useState([])
  const [selectedField, setSelectedField] = useState('')
  const [selectedFieldValue, setSelectedFieldValue] = useState('')
  const [formsLoading, setFormsLoading] = useState(false)
  const [setup, setSetup] = useState(false)
  const [loading, setLoading] = useState(false)
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD')
  const weekAgo = moment().subtract(1, 'weeks').format('YYYY-MM-DD')

  if (selectedRestaurant !== null && setup === true) {
    let selectedRestaurantForms = forms.filter(f => f.restaurant === selectedRestaurant.value && f.active === true)
    let currentFieldsArr = []
    let currentFieldValuesArr = []

    selectedRestaurantForms.forEach(function (form) {
      let template = formTemplates.find(ft => ft.id === form.template)
      if (template) {
        let validCron = true
        let interval = null
        try {
          interval = cron.parseExpression(template.cron)
        } catch {
          validCron = false
        }

        if (validCron) {
          let nextDeadline = interval.next().toString()
          let deadlineUnix = moment(nextDeadline).unix()
          let gracePeriodUnix = moment(nextDeadline).subtract(template.gracePeriod, 'm').unix()
  
          if (moment().unix() < deadlineUnix && moment().unix() > gracePeriodUnix) {
            let validFormRows = formRows.filter(fr => fr.form === form.id && moment(fr.created).unix() < deadlineUnix && moment(fr.created).unix() > gracePeriodUnix)

            validFormRows.forEach(function (formRow) {
              formFields.filter(ff => ff.row === formRow.id).forEach(function (field) {
                let valueType = formFieldTemplates.find(fft => fft.id === field.field).type
                let value = field[valueType + 'Value']
                currentFieldsArr.push(field.id)
                currentFieldValuesArr.push(value)
              })
            })
          }
        }
      }
    })
    setCurrentFormFields(currentFieldsArr)
    setCurrentFormFieldValues(currentFieldValuesArr)
    setSetup(false)
  }

  const restaurantOptions = restaurants.slice()
  .sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    return 0
  })
  .map(r => ({
    value: r.id,
    label: r.name,
  }))

  if (selectedRestaurant === null && restaurantOptions.length === 1) {
    setSelectedRestaurant(restaurantOptions[0])
  }

  const createNewRow = async (form) => {
    let payload = {
      form: form
    }
    const resp = await post(FORMROWS_URL, payload)

    if (resp.status === 201) {
      let form = forms.find(f => f.restaurant === selectedRestaurant.value && f.id === resp.data.form)
      let formTemplate = formTemplates.find(ft => ft.id === form.template)
      let fields = formTemplate.fields

      await Promise.all(fields.map(async field => {
        const promise = await post(FORMFIELDS_URL, {row: resp.data.id, field: field})
        return promise
      })).then(results => {
        if (results.status !== 201) {
          console.log({error: results.data})
        }
      })

      const resp2 = await get(FORMROWS_URL + '?from=' + weekAgo + '&to=' + tomorrow)
      if (resp2.status === 200) {
        dispatch(updateFormRows(resp2.data))
      } else {
        console.log({error: resp2.data})
      }

      const resp3 = await get(FORMFIELDS_URL + '?from=' + weekAgo + '&to=' + tomorrow)
      if (resp3.status === 200) {
        dispatch(updateFormFields(resp3.data))
      } else {
        console.log({error: resp3.data})
      }
    } else {
      console.log({error: resp.data})
    }

    setSetup(true)
    setLoading(false)
  }

  const updateRowFields = async (row) => {
    let fieldIds = formFields.filter(ff => ff.row === row).map(ff => ff.id)
    let updateFieldsArr = []

    currentFormFields.forEach(function (id, index) {
      if (fieldIds.indexOf(id) !== -1) {
        let formField = formFields.find(ff => ff.id === id)
        let fieldTemplate = formFieldTemplates.find(fft => fft.id === formField.field)
        let valueType = fieldTemplate.type + '_value'
        updateFieldsArr.push({
          id: id,
          valueType: valueType,
          value: currentFormFieldValues[index]
        })
      }
    })

    if (updateFieldsArr.length > 0) {
      await Promise.all(updateFieldsArr.map(async field => {
        let payload = {}
        payload[field.valueType] = field.value
        const promise = await patch(FORMFIELDS_URL, field.id, payload)
        return promise
      }))
  
      const formFieldsResp = await get(FORMFIELDS_URL)
      if (formFieldsResp.status === 200) {
        dispatch(updateFormFields(formFieldsResp.data))
      } else {
        console.log({error: formFieldsResp.data})
      }

      const formRowPatch = await patch(FORMROWS_URL, row, {
        completed: true,
        completed_by: user.user
      })
      if (formRowPatch.status === 200) {
        const formRowGet = await get(FORMROWS_URL + '?from=' + weekAgo + '&to=' + tomorrow)
        if (formRowGet.status === 200) {
          dispatch(updateFormRows(formRowGet.data))
        } else {
          console.log({error: formRowGet.data})
        }
      } else {
        console.log({error: formRowPatch.data})
      }
    }
    setFormsLoading(false)
  }

  const uncompleteRow = async (row) => {
    const formRowPatch = await patch(FORMROWS_URL, row, {
      completed: false,
      completed_by: user.user
    })
    if (formRowPatch.status === 200) {
      const formRowGet = await get(FORMROWS_URL + '?from=' + weekAgo + '&to=' + tomorrow)
      if (formRowGet.status === 200) {
        dispatch(updateFormRows(formRowGet.data))
      } else {
        console.log({error: formRowGet.data})
      }
    } else {
      console.log({error: formRowPatch.data})
    }
    setFormsLoading(false)
  }

  const validateNumeric = (str) => {
    let orig = str.toString()
    if (orig.length === 0 || orig === '' || orig === null) {
      return '0'
    }
    let firstChar = orig.substr(0, 1)
    let lastChar = orig.substr(orig.length - 1)
    if (lastChar === ',') {
      orig += '.'
    }
    let validated = orig.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")
    if (firstChar === '0') {
      if (validated.substr(1, 1) !== '.') {
        validated = validated.substr(1)
      }
    }
    return validated.toString()
  }

  const formRowsHtml = () => {
    let html = []
    let notActiveHtml = []

    let selectedRestaurantForms = forms.filter(f => f.restaurant === selectedRestaurant.value && f.active === true)

    selectedRestaurantForms.forEach(function (form) {
      let template = formTemplates.find(ft => ft.id === form.template && ft.active === true)
      if (template) {
        let validCron = true
        let interval = null
        try {
          interval = cron.parseExpression(template.cron)
        } catch {
          validCron = false
        }

        if (validCron) {
          let nextDeadline = interval.next().toString()
          let deadlineUnix = moment(nextDeadline).unix()
          let gracePeriodUnix = moment(nextDeadline).subtract(template.gracePeriod, 'm').unix()
  
          if (moment().unix() < deadlineUnix && moment().unix() > gracePeriodUnix) {
            let rowsHtml = []
            let headersHtml = []

            let fieldsOrdering = fieldOrders.filter(fo => fo.formTemplate === form.template).slice().sort((a, b) => {
              if (a.order > b.order) return 1
              if (a.order < b.order) return -1
              return 0
            })

            fieldsOrdering.forEach(function (formRow) {
              let field = formFieldTemplates.find(fft => fft.id === formRow.fieldTemplate)
              headersHtml.push(<td key={'header_' + form.id + field.id}><b>{field.name}<br />({field.type === 'text' ? t('teksti') : (field.type === 'float' ? t('numero') : '')})</b></td>)
            })

            headersHtml.push(<td key={'header_check_' + form.id}><b>{t('Kuittaus')}</b></td>)

            let validFormRows = formRows.filter(fr => fr.form === form.id && moment(fr.created).unix() < deadlineUnix && moment(fr.created).unix() > gracePeriodUnix).sort((a, b) => {
              if (moment(a.created).unix() > moment(b.created).unix()) return 1
              if (moment(a.created).unix() < moment(b.created).unix()) return -1
              return 0
            })
            validFormRows.forEach(function (formRow) {
              let fieldsHtml = []

              fieldsOrdering.forEach(function (fieldOrder) {
                let field = formFields.find(ff => ff.row === formRow.id && ff.field === fieldOrder.fieldTemplate)
                let fieldIndex = field && field.id ? currentFormFields.indexOf(field.id) : -1
                if (fieldIndex !== -1) {
                  let fieldObj = formFieldTemplates.find(f => f.id === fieldOrder.fieldTemplate)
                  if (formRow.completed) {
                    fieldsHtml.push(<td>{currentFormFieldValues[fieldIndex]}</td>)
                  } else if (fieldObj.type === 'text' || fieldObj.type === 'float') {
                    fieldsHtml.push(<td key={'input_wrap'+field.id}>
                      <input
                        type="text"
                        disabled={formsLoading}
                        value={selectedField === field.id ? selectedFieldValue : currentFormFieldValues[fieldIndex]}
                        onFocus={() => {
                          setSelectedField(field.id)
                          let index = currentFormFields.findIndex(cff => cff === field.id)
                          setSelectedFieldValue(currentFormFieldValues[index])
                        }}
                        onChange={e => {
                          let values = currentFormFieldValues
                          let index = currentFormFields.findIndex(cff => cff === field.id)
                          let fieldValue = e.target.value
                          if (fieldObj.type === 'float') {
                            fieldValue = validateNumeric(e.target.value)
                          }
                          values[index] = fieldValue
                          setCurrentFormFieldValues(values)
                          setSelectedFieldValue(fieldValue)
                        }}
                      />
                    </td>)
                  } else if (fieldObj.type === 'int') {
                    fieldsHtml.push(<td key={'input_wrap'+field.id}>
                      <input
                        type="number"
                        disabled={formsLoading}
                        value={selectedField === field.id ? selectedFieldValue : currentFormFieldValues[fieldIndex]}
                        onFocus={() => {
                          setSelectedField(field.id)
                          let index = currentFormFields.findIndex(cff => cff === field.id)
                          setSelectedFieldValue(currentFormFieldValues[index])
                        }}
                        onChange={e => {
                          let values = currentFormFieldValues
                          let index = currentFormFields.findIndex(cff => cff === field.id)
                          let fieldValue = e.target.value
                          values[index] = fieldValue
                          setCurrentFormFieldValues(values)
                          setSelectedFieldValue(fieldValue)
                        }}
                      />
                    </td>)
                  } else {
                    fieldsHtml.push(<td></td>)
                  }
                }
              })

              if (fieldsHtml.length > 0) {
                if (formRow.completed) {
                  fieldsHtml.push(<td key={'check' + formRow.id}>
                    <div
                      className="fas fa-edit"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setFormsLoading(true)
                        uncompleteRow(formRow.id)
                      }}
                    />            
                  </td>)
                } else {
                  fieldsHtml.push(<td key={'check' + formRow.id}>
                    <button
                      type="button"
                      className={'button is-small is-primary-background'}
                      style={{margin: '0px'}}
                      disabled={formsLoading}
                      onClick={() => {
                        setFormsLoading(true)
                        updateRowFields(formRow.id)
                      }}
                    >
                      <Trans>Tallenna</Trans>
                    </button>
                  </td>)
                }
                rowsHtml.push(<tr key={'row_' + formRow.id}>{fieldsHtml}</tr>)
              }
            })


            html.push(<div key={'form_'+form.id} style={{margin: '5px'}}>
              <h1
                className="title is-3 is-size-4-mobile is-uppercase"
                style={{margin: '30px 0 0 0', lineHeight: '1.2'}}
              >
                {template.name}
              </h1>
              <span style={{margin: '0'}}>{t('Kirjattavissa') + ' ' + t('klo') + ' ' + moment.unix(gracePeriodUnix).format('HH:mm') + ' - ' + moment.unix(deadlineUnix).format('HH:mm')}</span>
              <table className="table is-striped is-bordered" style={{margin: '5px auto'}}>
                <tbody>
                  <tr>
                    {headersHtml}
                  </tr>
                  {rowsHtml}
                </tbody>
              </table>
              <button
                disabled={loading}
                type="button"
                className={'button is-small is-primary-background'}
                style={{margin: '10px'}}
                onClick={() => {
                  setLoading(true)
                  createNewRow(form.id)
                }}
              >
                <Trans>Lisää rivi</Trans>
              </button>
            </div>)
          } else if (moment().endOf('day').unix() > gracePeriodUnix) {
            notActiveHtml.push(<><h1
              className="title is-3 is-size-4-mobile is-uppercase"
              style={{margin: '30px 0 0 0', lineHeight: '1.2', color: 'grey'}}
            >
              {template.name}
            </h1>
            <span style={{margin: '0', color: 'grey'}}>{t('Kirjattavissa') + ' ' + t('klo') + ' ' + moment.unix(gracePeriodUnix).format('HH:mm') + ' - ' + moment.unix(deadlineUnix).format('HH:mm')}</span>
            </>)

            let headersHtml = []

            let fieldsOrdering = fieldOrders.filter(fo => fo.formTemplate === form.template).sort((a, b) => {
              if (a > b) return 1
              if (a < b) return -1
              return 0
            })

            fieldsOrdering.forEach(function (formRow) {
              let field = formFieldTemplates.find(fft => fft.id === formRow.fieldTemplate)
              headersHtml.push(<td key={'header_' + form.id + field.id}><b>{field.name}<br />({field.type === 'text' ? t('teksti') : (field.type === 'float' ? t('numero') : '')})</b></td>)
            })

            headersHtml.push(<td key={'header_check_' + form.id}><b>{t('Kuittaus')}</b></td>)
            notActiveHtml.push(<table className="table is-striped is-bordered" style={{margin: '5px auto'}}>
              <tbody>
                <tr>
                  {headersHtml}
                </tr>
              </tbody>
            </table>)
          }
        }
      }
    })

    if (html.length > 0 || notActiveHtml.length > 0) {
      return (<div style={{padding: '20px'}}>
        {html}
        {notActiveHtml}
      </div>)
    } else {
      return (<div>
        <p
          className="title is-2 is-size-4-mobile is-uppercase"
          style={{margin: '0'}}
        >
            <Trans>Ei kirjattavaa tällä hetkellä</Trans>
        </p>
      </div>)
    }
  }

  return (<div>
  	<h1
      className="title is-1 is-size-3-mobile is-uppercase"
      style={{margin: '0'}}
    >
        <Trans>Toimenpiteet</Trans>
    </h1>
    <div style={{textAlign: 'left', margin: '10px 0'}}>
      <HukkaSelect
        options={restaurantOptions}
        value={selectedRestaurant}
        placeholder={t('Valitse ravintola')}
        onChange={e => {
          setSelectedRestaurant(e)
          setSetup(true)
        }}
      />
    </div>

    { selectedRestaurant && formRowsHtml() }
  </div>)
}

export default Actions

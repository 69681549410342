/* eslint-disable import/prefer-default-export */
import moment from 'moment'
import 'moment/locale/fi'

moment.locale('fi')

const DATE_FORMAT = 'DD.MM.'

export const lastFiveWeekDays = (formatted = true) => {
  const today = moment().subtract(1, 'days')
  const dates = [...Array(7).keys()]
    .map(i => {
      const date = today.clone().subtract(i, 'days')

      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
        return formatted ? date.format(DATE_FORMAT) : date
      }

      return null
    })
    .filter(d => d)

  return dates
}

import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { tokenLogin } from '../../actions'
import MagicForestRender from '../magicForest2/magicForestView'

const MagicForest = () => {
  const dispatch = useDispatch()
  const {
    hukkaProfiles,
    hukkaServices,
    hukkaServiceSubscriptions
  } = useSelector(state => state)
  const { t } = useTranslation()
  const chartRef = useRef(null)
  const { loggedIn, rou:readOnlyUser } = useSelector(state => state.user) || { loggedIn: false, readOnlyUser: false }
  const [init, setInit] = useState(false)

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  
  if (urlParams.get('token') !== null) {
    dispatch(tokenLogin(urlParams.get('token')))
    urlParams.delete('token')
    window.location.href = `${window.location.pathname}?${urlParams}${window.location.hash}`
  }

  const refreshAt = (hours, minutes, seconds) => {
    var now = new Date();
    var then = new Date();

    if(now.getHours() > hours ||
        (now.getHours() == hours && now.getMinutes() > minutes) ||
        now.getHours() == hours && now.getMinutes() == minutes && now.getSeconds() >= seconds) {
        then.setDate(now.getDate() + 1);
    }
    then.setHours(hours);
    then.setMinutes(minutes);
    then.setSeconds(seconds);

    var timeout = (then.getTime() - now.getTime());
    return timeout
  }

  useEffect(() => {
    if (init === false) {
      let refreshTime = refreshAt(4,0,0)
      setTimeout(function() { window.location.reload(true); }, refreshTime);
      setInit(true)
    }
	}, [init])

  const MagicForestView = () => (
    <div
      style={{
        display: 'block',
        alignItems: 'center',
        height: '100vh',
        width: '100vw'
      }}
      ref={chartRef}
    >
      <div style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%'}}>
        <MagicForestRender />
      </div> 
    </div>
  )

  const showMagicForestView = () => {
    let hasMagicForestService = false
    if (hukkaServices && hukkaServiceSubscriptions) {
      let magicForestService = hukkaServices.find(hs => hs.name === 'Taikametsä')
      if (hukkaServiceSubscriptions.find(hss => hss.service === magicForestService.id && moment(new Date()) < moment(hss.validUntil))) {
        hasMagicForestService = true
      }
    }
    if (hasMagicForestService === true) {
        setTimeout(function () {
            try {
                document.getElementsByClassName('magicForest_video').forEach(function (elem) {
                elem.load()
            })
            } catch {}
        }, 5000)
    }
    return hasMagicForestService ? MagicForestView() : (<p className="is-size-2 is-size-3-mobile is-uppercase" style={{marginTop: '30px'}}>{t('Hei! Valitettavasti sinulla ei ole oikeuksia tämän palvelun käyttöön, mutta jos haluat tietää lisää, niin ota yhteyttä mailitse')}:<br /><a href="mailto:asiakaspalvelu@hukka.ai">asiakaspalvelu@hukka.ai</a></p>)
  }


  /* General */

  return (
    <>
      {!hukkaProfiles && (<p className="is-size-2 is-size-3-mobile is-uppercase">Ladataan...</p>)}
      {loggedIn && hukkaProfiles && hukkaServices && hukkaServiceSubscriptions && readOnlyUser ? showMagicForestView() : ''}
    </>
  )
}

export default MagicForest

import React, { useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DateRangePicker } from 'react-dates'
import { useTranslation, Trans } from 'react-i18next'
import { get, MEALSERVINGS_URL, MEALSERVINGLOGS_URL } from '../../utility/api'
import {
  renewMealServings,
  renewMealServingLogs
} from '../../actions'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import {
  VERTICAL_ORIENTATION,
  HORIZONTAL_ORIENTATION,
} from 'react-dates/constants'
import moment from 'moment'
import HukkaSelect from '../hukkaSelect'


const SurveyReport = () => {
  const {
    restaurants,
    mealServings,
    mealServingLogs,
    mealTypes,
    genericSurveys,
    genericSurveyLines,
    genericSurveyLogs
  } = useSelector(state => state)
  const dispatch = useDispatch()
  const [selectedRestaurants, setSelectedRestaurants] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const [startDate, setStartDate] = useState(moment().startOf('month'))
  const [endDate, setEndDate] = useState(moment().endOf('month'))
  const [selectedDate, setSelectedDate] = useState('')
  const [filter, setFilter] = useState(0)
  const chartRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const getMealServings = async (from, to, res) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServings(resp.data))
      setLoading(false)
    } else {
    }
  }

  const getMealServingLogs = async (from, to, res) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGLOGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServingLogs(resp.data))
      setLoading(false)
    } else {
    }
  }

  // Allows us to use this function in an useEffect -function without triggering re-render on every update
  const getRestaurantOptions = useCallback(
    () =>
      restaurants.slice()
        .sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          return 0
        })
        .map(r => ({
          value: r.id,
          label: r.name,
        }))
        .concat([{ value: 'all', label: t('Lisää kaikki ravintolat') }]),
    [restaurants, t]
  )

  const datePickerOrientation = window.matchMedia('(max-width: 768px)').matches
    ? VERTICAL_ORIENTATION
    : HORIZONTAL_ORIENTATION

  const chart = () => {
    let selectedRestaurantIds = selectedRestaurants && selectedRestaurants.map(sr => sr.value)
    let surveys = genericSurveys && selectedRestaurantIds && genericSurveys
    .filter(gs => selectedRestaurantIds.indexOf(gs.restaurant) !== -1 && moment(gs.validUntil)
    .isBetween(startDate, endDate))
    .sort(function (a, b) {
      if (moment(a.validUntil) > moment(b.validUntil)) return 1
      if (moment(a.validUntil) < moment(b.validUntil)) return -1
      return 0
    })
    let surveyIds = surveys && surveys.map(gs => gs.id)
    let surveyLines = genericSurveyLines && selectedRestaurantIds && genericSurveyLines.filter(gsl => surveyIds.indexOf(gsl.survey) !== -1)
    let surveyLineIds = surveyLines && selectedRestaurantIds && surveyLines.map(sl => sl.id)
    let surveyLogs = genericSurveyLogs && selectedRestaurantIds && genericSurveyLogs.filter(gsl => surveyLineIds.indexOf(gsl.line) !== -1)
    let surveyData = {}
    let chartData = []
    let dates = []

    let tableData = []
    let tableDataTotals = []
    let tableDataHtml = []

    let html = []
    let restaurantsArr = selectedRestaurants ? selectedRestaurants.map(r => r.value) : []
    let servingDaysHtml = {}
    servingDaysHtml[selectedDate] = []

    let commentDates = []
    let comments = []
    let commentsHtml = []
    if (surveys) {
      surveys.forEach((s, index) => {
        let date = moment(new Date(s.validUntil)).format('DD.MM.YYYY')
        let dateIndex = commentDates.indexOf(date)
        let restaurantIndex = 0

        if (dateIndex === -1) {
          commentDates.push(date)
          comments[date] = {
            restaurants: [
              {
                restaurant: restaurants.find(r => r.id === s.restaurant).name,
                surveys: []
              }
            ]
          }
          commentsHtml.push(<button key={'noteDate'+index} type="button" style={{margin: "2px"}} className={'button is-uppercase is-rounded' + (selectedDate === date ? ' is-primary' : '')} onClick={() => {
            if (selectedDate !== date) {
              setSelectedDate(date)
            } else {
              setSelectedDate('')
            }
          }}>{ date }</button>)
        } else {
          comments[date].restaurants.push({
            restaurant: restaurants.find(r => r.id === s.restaurant).name,
            surveys: []
          })
          restaurantIndex = comments[date].restaurants.length - 1
        }
        
        let yes = 0, no = 0
        surveyLines.forEach(sl => {
          if (sl.survey === s.id) {
            comments[date].restaurants[restaurantIndex].surveys.push({
              survey: sl.description,
              comments: []
            })
            surveyLogs.forEach(sLogs => {
              if (sLogs.line === sl.id) {
                if (sLogs.score > 50) yes++
                else if (sLogs.score < 50) no++
                if (sLogs.comment && sLogs.comment.length > 0) {
                  comments[date].restaurants[restaurantIndex].surveys[comments[date].restaurants[restaurantIndex].surveys.length - 1].comments.push(sLogs.comment)
                }
              }
            })
            surveyData[sl.relatedServing] = {
              yes,
              no
            }
          }
        })

        if (yes > 0 || no > 0) {
          let chartDateIndex = dates.indexOf(date)
          if (chartDateIndex !== -1) {
            chartData[chartDateIndex].yes = chartData[chartDateIndex].yes + yes
            chartData[chartDateIndex].no = chartData[chartDateIndex].no + no
            tableData[chartDateIndex].yes = tableData[chartDateIndex].yes + yes
            tableData[chartDateIndex].no = tableData[chartDateIndex].no + no
          } else {
            dates.push(date)
            chartData.push({
              date,
              yes,
              no
            })
            tableData.push({
              date,
              yes,
              no
            })
          }
        }
      })

      tableData.forEach(td => {
        let total = td.yes + td.no
        if (total > 0) {
          if (tableDataTotals.length === 0) {
            tableDataTotals[0] = 1
            tableDataTotals[1] = td.yes
            tableDataTotals[2] = td.no
          } else {
            tableDataTotals[0] = tableDataTotals[0] + 1
            tableDataTotals[1] = tableDataTotals[1] + td.yes
            tableDataTotals[2] = tableDataTotals[2] + td.no
          }
          tableDataHtml.push(<tr key={td.date}>
            <td>{td.date}</td>
            <td>{td.yes} - {td.no}</td>
            <td>{parseFloat((td.yes / total * 100).toFixed(1))}% - {parseFloat((td.no / total * 100).toFixed(1))}%</td>
          </tr>)
        }
      })
      if (tableDataTotals.length > 0) {
        let averages = {
          yes: tableDataTotals[1] / tableDataTotals[0],
          no: tableDataTotals[2] / tableDataTotals[0]
        }
        tableDataHtml.push(<>
          <tr style={{borderTop: '2px solid #000'}}>
            <td><b>{t('Yhteensä')}</b></td>
            <td>{tableDataTotals[1]} - {tableDataTotals[2]}</td>
            <td></td>
          </tr>
          <tr style={{borderTop: '2px solid #000'}}>
            <td><b>{t('Keskiarvo')}</b></td>
            <td>{parseFloat(averages.yes.toFixed(1))} - {parseFloat(averages.no.toFixed(1))}</td>
            <td>{parseFloat((averages.yes / (averages.yes + averages.no) * 100).toFixed(1))}% - {parseFloat((averages.no / (averages.yes + averages.no) * 100).toFixed(1))}%</td>
          </tr>
        </>)
      }

      if (filter === 1) {
        chartData.forEach(cd => {
          let total = cd.yes + cd.no
          if (total > 0) {
            cd.yes = cd.yes > 0 ? parseFloat((cd.yes / total * 100).toFixed(1)) : 0
            cd.no = cd.no > 0 ? parseFloat((cd.no / total * 100).toFixed(1)) : 0
          } 
        })
      }
    }
    let selectedCommentsHtml = []
    if (surveys && selectedDate > '') {
      let selectedRestaurantsHtml = []
      if (comments[selectedDate].restaurants) {
        comments[selectedDate].restaurants.forEach(function (r) {
          let selectedSurveysHtml = []
          r.surveys.forEach(function (s) {
            selectedSurveysHtml.push(<h3><b>{s.survey}</b></h3>)
            let selectedCommentsHtml = []
            s.comments.forEach(function (c) {
              selectedCommentsHtml.push(<li>{c}</li>)
            })
            if (selectedCommentsHtml.length > 0) {
              selectedSurveysHtml.push(<div>{selectedCommentsHtml}</div>)
            } else {
              selectedSurveysHtml.push(<i>--- {t('kyselyyn ei lähetetty kommentteja')} ---</i>)
            }
          })
          if (selectedSurveysHtml.length > 0) {
            selectedRestaurantsHtml.push(<h2 className="title is-3 is-uppercase" style={{marginBottom: '0', lineHeight: '2'}}>{r.restaurant}</h2>)
            selectedRestaurantsHtml.push(<div>{selectedSurveysHtml}</div>)
          }
        })
        selectedCommentsHtml.push(<div>{selectedRestaurantsHtml}</div>)
      }
    }

    if (selectedDate !== '') {
      mealServings.filter(ms => restaurantsArr.indexOf(ms.restaurant) !== -1 && selectedDate === moment(ms.servingDatetime).format('DD.MM.YYYY')).forEach(function (ms) {
        let servingLogs = mealServingLogs.filter(msl => msl.mealServing === ms.id)
        let servingsHtml = []
        let totals = {
          prepared: 0,
          wasted: 0,
          customers: ms.customers
        }
        servingLogs.forEach(function (s) {
          if (s.foodName && s.foodName !== '') {
            totals.prepared = s.preparedAmountGrams + totals.prepared
            totals.wasted = s.wasteGrams + totals.wasted
            servingsHtml.push(<tr>
              <td>{s.foodName}</td>
              <td>{s.preparedAmountGrams ? (s.preparedAmountGrams).toFixed(0) : 0} g</td>
              <td>{s.wasteGrams ? (s.wasteGrams).toFixed(0) : 0} g</td>
              <td>{ms.customers > 0 ? ((s.preparedAmountGrams - s.wasteGrams) / ms.customers).toFixed(0) : 0} g</td>
              <td>{ms.customers > 0 ? (s.wasteGrams / ms.customers).toFixed(0) : 0} g</td>
              <td>{s.preparedAmountGrams && s.preparedAmountGrams !== 0 ? (s.wasteGrams / s.preparedAmountGrams * 100).toFixed(1) : 0} %</td>
            </tr>)
          }
        })

        servingDaysHtml[moment(ms.servingDatetime).format('DD.MM.YYYY')].push(<>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>{restaurants.find(r => r.id === ms.restaurant).name}</b></td>
            <td><b>{moment(ms.servingDatetime).format('ddd DD.MM.')}</b></td>
            <td><b>{t(mealTypes.find(mt => mt.id === ms.mealType).name)}</b></td>
            <td><b>{t('Asiakasmäärä')}: {ms.customers}</b></td>
            <td><b>yes: {surveyData[ms.id] ? (surveyData[ms.id].yes + ' (' + parseFloat((surveyData[ms.id].yes / (surveyData[ms.id].yes + surveyData[ms.id].no) * 100).toFixed(1)) + '%)') : ' - '}</b></td>
            <td><b>no: {surveyData[ms.id] ? (surveyData[ms.id].no + ' (' + parseFloat((surveyData[ms.id].no / (surveyData[ms.id].yes + surveyData[ms.id].no) * 100).toFixed(1)) + '%)') : ' - '}</b></td>
          </tr>
        </>)
        if (servingsHtml.length === 0) {
          servingDaysHtml[moment(ms.servingDatetime).format('DD.MM.YYYY')].push(<tr>
            <td>- {t('ei kirjattuja ruokia')} -</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>)
        } else {
          servingDaysHtml[moment(ms.servingDatetime).format('DD.MM.YYYY')].push(<>
            <tr>
              <td><b>{t('Ruoka')}</b></td>
              <td><b>{t('Tarjoilumäärä')}</b></td>
              <td><b>{t('Hukattu')}</b></td>
              <td><b>{t('Menekki per asiakas')}</b></td>
              <td><b>{t('Hävikki per asiakas')}</b></td>
              <td><b>{t('Hävikkiprosentti')}</b></td>
            </tr>
            {servingsHtml}
            <tr style={{borderTop: '2px solid #000'}}>
              <td><b>{t('Yhteensä')}</b></td>
              <td>{(totals.prepared).toFixed(0)} g</td>
              <td>{(totals.wasted).toFixed(0)} g</td>
              <td>{((totals.prepared - totals.wasted) / totals.customers).toFixed(0)} g</td>
              <td>{(totals.wasted / totals.customers).toFixed(0)} g</td>
              <td>{totals.prepared !== 0 ? (totals.wasted / totals.prepared * 100).toFixed(1) : 0} %</td>
            </tr>
          </>)
        }
      })
      if (html.length > 0) {
        html.push(<tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>)
      }
      html.push(<tr style={{background: '#000', color: '#fff'}}>
        <td><b>{selectedDate}</b></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>)
      if (servingDaysHtml[selectedDate]) {
        servingDaysHtml[selectedDate].forEach(function (sdh) {
          html.push(sdh)
        })
      }
    }

    return (
      <div>
        <h3 className="title is-3 is-uppercase"><Trans>Suodata</Trans></h3>
          <div>
            <button
              type="button"
              className={`button is-small is-rounded is-uppercase ${
                filter === 0 ? 'is-primary' : null
              }`}
              onClick={() => setFilter(0)}
              style={{ margin: '5px' }}
            >
              <Trans>Lukumäärät</Trans>
            </button>

            <button
              type="button"
              className={`button is-small is-rounded is-uppercase ${
                filter === 1 ? 'is-primary' : null
              }`}
              onClick={() => setFilter(1)}
              style={{ margin: '5px' }}
            >
              <Trans>Prosentit</Trans>
            </button>
          </div>

        { chartData.length <= 0 && (
          <p>{t('Ei dataa valituilla ravintoloilla ja aikavälillä')}.</p>
        )}
        { chartData.length > 0 && (
          <div>
            <ResponsiveContainer width="99%" height={500} minHeight={500}>
              <BarChart
                data={chartData}
                margin={{
                  top: 40,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                onClick={columnProps => {
                  if (columnProps) {
                    setSelectedDate(columnProps.activeLabel)
                    document.getElementById('comments').scrollIntoView({behavior: 'smooth'})
                  }
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis tickFormatter={label => {
                  if (filter === 0) {
                    return `${label}`
                  } else {
                    return `${label} %`
                  }
                }}/>
                <Tooltip formatter={label => {
                  if (filter === 0) {
                    return `${label}`
                  } else {
                    return `${label} %`
                  }
                }}/>
                <Bar dataKey="yes" stackId="a" fill="green" />
                <Bar dataKey="no" stackId="a" fill="red" />
                <Legend wrapperStyle={{ top: 500, left: 25 }} />
              </BarChart>
            </ResponsiveContainer>
            <table className="table is-striped is-bordered" style={{margin: '50px auto'}}>
              <tbody>
                <tr>
                  <td><b>{t('Päivämäärä')}</b></td>
                  <td><b>{t('Lukumäärät')} (yes - no)</b></td>
                  <td><b>{t('Prosentit')} (yes - no)</b></td>
                </tr>
                {tableDataHtml}
              </tbody>
            </table>
          </div>
          )
        }
        <div id="comments" className="box" style={{ marginTop: '100px' }}>
          <div className="column title is-size-3 is-marginless is-paddingless">
            <Trans>Kyselyt ja Kommentit</Trans>
          </div>
          <div>
            { commentsHtml }
          </div>
          <br />
          { selectedCommentsHtml }
        </div>

        <div>
          {loading === false && mealServings && mealServings.length > 0 && (
            <table className="table is-striped is-bordered" style={{margin: '20px auto'}}>
              <tbody>
                {html}
              </tbody>
            </table>
          )}
        </div>
      </div>
    )
  }





  return (
    <div className="survey">
      <h1 className="title is-1 is-size-3-mobile is-uppercase">
        <Trans>Kyselyiden seuranta</Trans>
      </h1>

      <div className="field" ref={chartRef}>
        <div className="control">
          <div className="select is-large is-fullwidth hukkaselect">
            <HukkaSelect
              options={getRestaurantOptions()}
              placeholder={t('Valitse ravintola')}
              value={selectedRestaurants}
              onChange={e => {
                let res = e
                if (e && e.find(r => r.value === 'all')) {
                  res = getRestaurantOptions().filter(ro => ro.value !== 'all')
                  setSelectedRestaurants(
                    res
                  )
                } else {
                  setSelectedRestaurants(res)
                }
                getMealServings(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), res)
                getMealServingLogs(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), res)
              }}
              isMulti
            />
          </div>
        </div>
      </div>

      <DateRangePicker
        startDate={startDate}
        startDateId="reportWasteStartDate"
        endDate={endDate}
        endDateId="reportWasteEndDate"
        onDatesChange={({ startDate: sd, endDate: ed }) => {
          let start = sd
          let end = ed
          if (new Date(moment(sd).format()) < new Date(moment(ed).format())) {
            setEndDate(ed)
            setStartDate(sd)
          } else {
            end = sd
            setEndDate(sd)
            setStartDate(sd)
          }
          getMealServings(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD'), selectedRestaurants)
          getMealServingLogs(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD'), selectedRestaurants)
        }}
        focusedInput={focusedInput}
        onFocusChange={newFocusedInput => setFocusedInput(newFocusedInput)}
        block
        orientation={datePickerOrientation}
        withPortal
        displayFormat="DD.MM.YYYY"
        isOutsideRange={() => false}
      />

      {chart()}
    </div>
  )
}

export default SurveyReport

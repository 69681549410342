import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { MEALSERVINGS_URL, AVGWASTE_URL, SEASONS_URL, SCORELOGS_URL, get } from '../../utility/api'
import { renewMealServings, updateAvgWaste } from '../../actions'

const MagicForestView = ({
  scaleValue
}) => {
  const dispatch = useDispatch()
  const {
    orgs,
    mealServings,
    restaurants,
    avgWaste,
  } = useSelector(state => state)
  const { t } = useTranslation()
  const { loggedIn, rou:readOnlyUser } = useSelector(state => state.user) || { loggedIn: false, readOnlyUser: false }
  const [init, setInit] = useState(false)
  const [fetchedData, setFetchedData] = useState([])
  const [avg, setAvg] = useState(null)
  const [weekRating, setWeekRating] = useState(null)
  const [chartMax, setChartMax] = useState(null)
  const [calculated, setCalculated] = useState(false)
  const [weekDaysData, setWeekDaysData] = useState([])
  const [restaurantScores, setRestaurantScores] = useState([])

  const mapPositions = [
    {top: '70', left: '50.5'},
    {top: '64.5', left: '46'},
    {top: '58.8', left: '42.7'},
    {top: '57', left: '36.3'},
    {top: '56.8', left: '30'},
    {top: '55.5', left: '24.5'},
    {top: '49.6', left: '20.9'},
    {top: '46.5', left: '26.2'},
    {top: '46.2', left: '32.6'},
    {top: '45.6', left: '39'},
    {top: '42.8', left: '45.7'},
    {top: '38.3', left: '50.8'},
    {top: '37.4', left: '55.7'},
    {top: '32.9', left: '59.2'},
    {top: '26.7', left: '52.7'},
    {top: '27.7', left: '44.7'},
    {top: '24.9', left: '38.6'},
    {top: '29.9', left: '34.1'},
    {top: '30.5', left: '29.7'},
    {top: '22.5', left: '26.7'},
    {top: '17.8', left: '23'},
    {top: '15', left: '18.4'}
  ]
  const pawns = [
    'pelinappula_kettu_red',
    'pelinappula_kettu_blue',
    'pelinappula_kettu_yellow',
    'pelinappula_kettu_orange'
  ]

  const getMealServings = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServings(resp.data))
      let fetchedDataArr = fetchedData
      fetchedDataArr.push('mealServings')
      setFetchedData(fetchedDataArr)
    }
  }

  const getAvgWaste = async (restaurant, from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(AVGWASTE_URL + restaurant + '/', payload)

    if (resp.status === 200) {
      dispatch(updateAvgWaste(resp.data))
      let fetchedDataArr = fetchedData
      fetchedDataArr.push('avgWaste')
      setFetchedData(fetchedDataArr)
    }
  }

  const getSeasons = async () => {
    const resp = await get(SEASONS_URL)

    if (resp.status === 200) {
      getScoreLogs(resp.data[resp.data.length - 1].id)
    }
  }
  
  const getScoreLogs = async (season) => {
    let today = moment()
    let yesterday = moment().subtract(1, 'days')
    if (today.isoWeekday() === 1) {
      yesterday = moment().subtract(3, 'days')
    } else if (today.isoWeekday() === 0) {
      today = moment().subtract(2, 'days')
      yesterday = moment().subtract(3, 'days')
    } else if (today.isoWeekday() === 6) {
      today = moment().subtract(1, 'days')
      yesterday = moment().subtract(2, 'days')
    }
    const currentScores = await get(SCORELOGS_URL, {ordering: '-plate_score', season: season, date: today.format('YYYY-MM-DD'), 'restaurant__org': orgs[0].id})

    if (currentScores.status === 200 && currentScores.data && currentScores.data.length > 0) {
      const prevScores = await get(SCORELOGS_URL, {ordering: '-plate_score', season: season, date: yesterday.format('YYYY-MM-DD'), 'restaurant__org': orgs[0].id})
      if (prevScores.status === 200) {
        let currentScoreIds = currentScores.data.map(cs => cs.restaurant)
        let prevScoreIds = prevScores.data.map(ps => ps.restaurant)

        let scores = []

        let topRestaurantsCount = 3
        if (currentScores.data.length < 3) {
          topRestaurantsCount = currentScores.data.length
        }
        for (let i = 0; i < topRestaurantsCount; i++) {
          scores.push({
            ...currentScores.data[i],
            position: i + 1,
            prevPosition: prevScoreIds.indexOf(currentScores.data[i].restaurant) + 1,
            isPlayer: currentScores.data[i].restaurant === restaurants[0].id ? true : false,
            showNumber: false
          })
        }
        
        let playerScores = currentScores.data.find(d => d.restaurant === restaurants[0].id)
        scores.push({
          ...playerScores,
          position: currentScoreIds.indexOf(restaurants[0].id) + 1,
          prevPosition: prevScoreIds.indexOf(restaurants[0].id) + 1,
          isPlayer: true,
          showNumber: true
        })
  
        setRestaurantScores(scores)
      }

    }
  }

  if (!calculated && fetchedData.indexOf('mealServings') !== -1 && fetchedData.indexOf('avgWaste') !== -1) {
    let monday = moment().startOf('isoWeek')
    let platewaste = 0
    let date = false
    let avgNum = avgWaste && avgWaste.plateAvg
    let weekRatingDays = 0
    let countWeekRatingDays = true
    let dataArr = []
    let maximumWaste = avgNum * 2
    let today = moment().format('YYYY-MM-DD')

    for (let i = 0; i < 5; i++) {
      date = moment(monday).add(i, 'days').format('YYYY-MM-DD')
      platewaste = 0

      mealServings.filter(ms => moment(ms.servingDatetime).format('YYYY-MM-DD') === date).forEach(function (ms) {
        platewaste = parseInt(ms.plateWasteGrams) + platewaste
      })
  
      if (platewaste > maximumWaste) {
        maximumWaste = platewaste
      }

      if (today === date) {
        countWeekRatingDays = false
      }

      if (countWeekRatingDays) {
        if (platewaste < avgNum) {
          weekRatingDays++
        } else {
          weekRatingDays--
        }
      }
  
      dataArr.push({
        weekDay: moment(date).format('dd'),
        platewaste: platewaste,
        today: date === today ? true : false
      })
    }

    setWeekDaysData(dataArr)
    setWeekRating(weekRatingDays)
    setAvg(avgNum),
    setCalculated(true)
    setChartMax(maximumWaste)
  }

  if (loggedIn && readOnlyUser && !init && restaurants.length > 0 ) {
    const startingDate = moment().startOf('isoWeek')
    getMealServings(startingDate.format('YYYY-MM-DD'), startingDate.clone().add(5, 'days').format('YYYY-MM-DD'))
    getAvgWaste(restaurants[0].id, moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'))
    getSeasons()
    setInit(true)
  }

  const drawChart = () => {
    let weekDaysHtml = []

    let maximumWaste = Math.ceil(chartMax / 10000) * 10000

    weekDaysData.forEach(function (data, index) {
      if (data.today) {
        let background = 'green'
        if ((scaleValue * 1000)  > avg) {
          background = 'red'
        }
        weekDaysHtml.push(<div key={data.weekDay + scaleValue} className='magicForest__weekday' style={{marginLeft: (20 * index) + '%'}}>
          <span className='magicForest__weekValue' style={{bottom: (scaleValue * 1000 / maximumWaste * 100).toFixed(1) + '%'}}>{scaleValue}kg</span>
          <span className={'magicForest__weekLetters magicForest__weekLetters--today'}>{data.weekDay.toUpperCase()}</span>
          <div className='magicForest__bar' style={{height: (scaleValue * 1000 / maximumWaste * 100).toFixed(1) + '%', background: background}}></div>
        </div>)
      } else {
        let background = 'green'
        if (data.platewaste > avg) {
          background = 'red'
        }
        weekDaysHtml.push(<div key={data.weekDay + data.platewaste} className='magicForest__weekday' style={{marginLeft: (20 * index) + '%'}}>
          <span className='magicForest__weekValue' style={{bottom: (data.platewaste / maximumWaste * 100).toFixed(1) + '%'}}>{data.platewaste ? parseFloat((data.platewaste / 1000).toFixed(1)) : 0}kg</span>
          <span className={'magicForest__weekLetters' + (data.today ? ' magicForest__weekLetters--today' : '')}>{data.weekDay.toUpperCase()}</span>
          <div className='magicForest__bar' style={{height: (data.platewaste / maximumWaste * 100).toFixed(1) + '%', background: background}}></div>
        </div>)
      }
    })

    return (<div className='magicForest__chartArea'>
      <div className='magicForest__chartNumbersLeft'>
        <span className='magicForest__chartMax'>{parseInt(maximumWaste / 1000)}kg</span>
        <span className='magicForest__chartMin'>0kg</span>
      </div>
      <div className='magicForest__chart'>
        <div className='magicForest__avgLine' style={{bottom: (avg / maximumWaste * 100).toFixed(2) + '%'}}></div>
        {weekDaysHtml}
      </div>
      <div className='magicForest__chartNumbersRight'>
        <span className='magicForest__avgValue' style={{bottom: (avg / maximumWaste * 100).toFixed(2) + '%'}}>{Math.round(avg / 1000)}kg</span>
      </div>
    </div>)
  }

  const mapVideo = () => {
    let videoNumber = 1
    let maxPoints = restaurantScores[0].plateScore

    if (maxPoints >= 42) {
      videoNumber = 6
    } else if (maxPoints >= 36) {
      videoNumber = 5
    } else if (maxPoints >= 26) {
      videoNumber = 4
    } else if (maxPoints >= 20) {
      videoNumber = 3
    } else if (maxPoints >= 10) {
      videoNumber = 2
    }

    return (<video
      key={'map'+videoNumber}
      id="video"
      className="magicForest__map magicForest__video"
      autoPlay="autoplay"
      loop
    >
      <source src={'/webm/magic-forest/kartta-1-' + videoNumber + '.webm'} type="video/webm" />
    </video>)
  }

  const miniVideo = () => {
    let videoUrl = 'video_neutraali.webm'
    //let videoNumber = 1

    if (weekRating > 0) {
      videoUrl = 'video_hyva.webm'
    } else if (weekRating < 0) {
      videoUrl = 'video_huono.webm'
    }

    return (<video
      id="miniVideo"
      className="magicForest__miniVideo magicForest__video"
      autoPlay="autoplay"
      loop
    >
      <source src={'/webm/magic-forest/' + videoUrl} type="video/webm" />
    </video>)
  }

  const restaurantStandings = () => {
    let html = []

    restaurantScores.forEach(function (rs) {
      let icon = 'number'
      let change = 'neutral'
      
      if (rs.position === 1) {
        icon = 'pokaali-gold.png'
      } else if (rs.position === 2) {
        icon = 'pokaali-silver.png'
      } else if (rs.position === 3) {
        icon = 'pokaali-bronze.png'
      }
      
      if (rs.position > rs.prevPosition) {
        change = 'down'
      } else if (rs.position < rs.prevPosition) {
        change = 'up'
      }

      if (rs.showNumber) {
        html.push(<div style={{marginTop: '2vh'}}>
          <div className='magicForest__standingNumber'>{rs.position}</div>
          <img className='magicForest__rankChange' src={'/img/magic-forest/rank-' + change + '.png'} />
          <span className='magicForest__restaurantName bold underline'>{rs.restaurantName} ({rs.plateScore}/42)</span>
        </div>)
      } else {
        html.push(<div>
          <img className='magicForest__prizeIcon' src={'/img/magic-forest/' + icon} />
          <img className='magicForest__rankChange' src={'/img/magic-forest/rank-' + change + '.png'} />
          <span className={'magicForest__restaurantName' + (rs.isPlayer ? ' bold underline' : '')}>{rs.restaurantName} ({rs.plateScore}/42)</span>
        </div>)
      }

    })

    return html
  }

  const pawnVideos = () => {
    let html = []
    let pawnPositions = []

    restaurantScores.forEach(function (rs, index) {
      if (!rs.showNumber || rs.position > 3) {
        let mapPosition = Math.floor(Math.floor(rs.plateScore) / 2)
        if (mapPosition > 21) {
          mapPosition = 21
        } else if (mapPosition < 0) {
          mapPosition = 0
        }
        let pawnIndex = rs.isPlayer ? 3 : index
        let leftIndex = pawnPositions.filter(p => p === mapPosition).length
        pawnPositions.push(mapPosition)
        html.push(<video
          className="magicForest__pawn magicForest__video"
          autoPlay="autoplay"
          loop
          style={{top: mapPositions[mapPosition].top + '%', left: (mapPositions[mapPosition].left - (leftIndex * 1)) + '%'}}
        >
          <source src={'/webm/magic-forest/' + pawns[pawnIndex] + '.webm'} type="video/webm" />
        </video>)
      }
    })

    return html
  }

  const feedbackVideo = () => {
    let html = []

    html.push(<video
      id="magicForestPosReview"
      className="magicForest__review"
      style={{top: '0%', left: '0%', position: 'absolute', height: '100%', zIndex: '1'}}
    >
      <source src={'/webm/magic-forest/palaute_kettu_positiivinen.webm'} type="video/webm" />
    </video>)

    html.push(<video
      id="magicForestNegReview"
      className="magicForest__review"
      style={{top: '0%', left: '0%', position: 'absolute', height: '100%', zIndex: '1'}}
    >
      <source src={'/webm/magic-forest/palaute_kettu_negatiivinen.webm'} type="video/webm" />
    </video>)

    html.push(<div
      id="magicForestWasteAmount"
      className="magicForest__wasteAmount"
    >--- kg</div>)

    return html
  }

  return (<div className='magicForest'>
    <div className='magicForest__videoWrapper'>
      {restaurantScores && restaurantScores.length > 0 && mapVideo()}
      {restaurantScores && restaurantScores.length > 0 && pawnVideos()}
    </div>
    <div className='magicForest__panel' style={{background: 'url(/img/magic-forest/sivupalkki.png)'}}>
      <div className='magicForest__panelSection' >
        {restaurantScores && restaurantScores.length > 0 && miniVideo()}
      </div>

      <div className='magicForest__panelSection' style={{flex: '1'}}>
        <span className='magicForest__title' style={{marginTop: '-5vh'}}>{t('Viikon puhti')}</span>
        {restaurantScores && restaurantScores.length > 0 && drawChart()}
      </div>

      <div className='magicForest__panelSection' style={{flex: '0', borderTop: '1px solid #fff'}}>
        <span className='magicForest__title'>{t('Top 3 -pelaajat')}</span>
        <div style={{marginTop: '0.7vh'}}></div>
        {restaurantScores && restaurantScores.length > 0 && restaurantStandings()}
      </div>
    </div>
    {feedbackVideo()}
  </div>)
}

export default MagicForestView

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { shape, string, bool, arrayOf, number, func } from 'prop-types'
import { Trans, useTranslation } from 'react-i18next';
import { get, patch, MEALSERVINGS_URL, MEALSERVINGLOGS_URL } from '../../utility/api'
import moment from 'moment'
import {
  updateMealServings,
  renewMealServingLogs
} from '../../actions'

const MealServingLogsTable = ({
  mealServingLogs,
  wasteTypes,
  foods,
  orgAdmin,
  selectedMealServing,
  setWasteLogInEdit,
  courses,
  deleteWasteLog
}) => {
  const { mealServings } = useSelector(state => state)
  const [customerAmount, setCustomerAmount] = useState(mealServings.find(ms => ms.id === selectedMealServing) ? mealServings.find(ms => ms.id === selectedMealServing).customers : 0)
  const [notes, setNotes] = useState(mealServings.find(ms => ms.id === selectedMealServing) ? mealServings.find(ms => ms.id === selectedMealServing).servingNotes : '')
  const [selected, setSelected] = useState(selectedMealServing)
  const [saving, setSaving] = useState('')
  const [savingTimeout, setSavingTimeout] = useState(null)
  const { t } = useTranslation()
  const [foodToRemove, setFoodToRemove] = useState(null)
  const [foodToAppend, setFoodToAppend] = useState(null)
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [appendModalOpen, setAppendModalOpen] = useState(false)
  const [preparedAppend, setPreparedAppend] = useState(0)
  const [wasteAppend, setWasteAppend] = useState(0)
  const dispatch = useDispatch()
  const lineWaste = wasteTypes && wasteTypes.find(wt => wt.name === 'line' || wt.name === 'Linjastohävikki')
  
  const selectedMealservingInfos =
  selectedMealServing &&
  mealServings &&
  mealServings.find(ms => ms.id === selectedMealServing)
  const currentDate = moment(new Date())
  const servingDate = selectedMealservingInfos ? moment(selectedMealservingInfos.servingDatetime) : false
  const diff = servingDate.diff(currentDate, 'days')

  const validateNumeric = (str) => {
    let orig = str.toString()
    if (orig.length === 0 || orig === '' || orig === null) {
      return '0'
    }
    let firstChar = orig.substr(0, 1)
    let lastChar = orig.substr(orig.length - 1)
    if (lastChar === ',') {
      orig += '.'
    }
    let validated = orig.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")
    if (firstChar === '0') {
      if (validated.substr(1, 1) !== '.') {
        validated = validated.substr(1)
      }
    }
    return validated.toString()
  }

  const getMealServingLogs = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGLOGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServingLogs(resp.data))
    }
  }

  const editLog = async (id, payload) => {
    const resp = await patch(MEALSERVINGLOGS_URL, id, payload)
    
    if (resp.status === 200) {
      let serv = mealServings.find(ms => ms.id === foodToAppend.mealServing)
      getMealServingLogs(moment(serv.servingDatetime).format('YYYY-MM-DD'), moment(serv.servingDateTime).add(1, 'days').format('YYYY-MM-DD'))
    } else if (resp && resp.data && resp.data.detail) {
      setError(resp.data.detail)
    } else {
      setError('Tapahtui virhe')
    }

    setAppendModalOpen(false)
    setFoodToAppend(null)
    setPreparedAppend(0)
    setWasteAppend(0)
  }

  const wasteTypeColor = (wasteTypeObj) => {
    let color = ''

    if (wasteTypeObj && wasteTypeObj.name) {
      if (wasteTypeObj.name === 'line' || wasteTypeObj.name === 'Linjastohävikki') {
        color = '#ACDDDE'
      } else if (wasteTypeObj.name === 'plate' || wasteTypeObj.name === 'Lautashävikki') {
        color = '#CAF1DE'
      } else if (wasteTypeObj.name === 'Keittiöhävikki') {
        color = '#F7D8BA'
      } else if (wasteTypeObj.name === 'Varastohävikki') {
        color = '#FEF8DD'
      } else {
        color = '#fff'
      }
    }

    return color
  }

  const wasteRows = [...mealServingLogs]
    .sort((a, b) => a.logDatetime.localeCompare(b.logDatetime))
    .filter(msl => msl.mealServing === selectedMealServing)
    .map(msl => {
      const rowWasteType = wasteTypes.find(wt => wt.id === msl.wasteType)
      const rowFood = foods.find(f => f.id === msl.food)
      const rowCourse = courses.find(c => c.id === msl.course)
      return (
        <tr key={msl.id} style={{background: wasteTypeColor(rowWasteType)}}>
          <td className='hideDesktop'>
            <b>{((rowWasteType && rowWasteType.name) !== 'plate' && (rowWasteType && rowWasteType.name) !== 'Keittiöhävikki' ? ((rowFood && rowFood.name)) : '-')}</b>
            <br />-<br />
            <i>{((rowWasteType && rowWasteType.name) !== 'plate' && (rowWasteType && rowWasteType.name) !== 'Keittiöhävikki' ? ((rowCourse && t(rowCourse.name)) || '') : '-')}</i>
          </td>
          <td className='hideDesktop'>
            {((rowWasteType && rowWasteType.name) === 'line' ? `${msl.preparedAmountGrams / 1000} KG` : '-')}
            <br />-<br />
            {`${msl.wasteGrams / 1000} KG`}
          </td>
          <td className='hideMobile'><b>{((rowWasteType && rowWasteType.name) !== 'plate' && (rowWasteType && rowWasteType.name) !== 'Keittiöhävikki' ? ((rowFood && rowFood.name)) : '-')}</b></td>
          <td className='hideMobile'><i>{((rowWasteType && rowWasteType.name) !== 'plate' && (rowWasteType && rowWasteType.name) !== 'Keittiöhävikki' ? ((rowCourse && t(rowCourse.name)) || '') : '-')}</i></td>
          <td className='hideMobile'>{((rowWasteType && rowWasteType.name) === 'line' ? `${msl.preparedAmountGrams / 1000} KG` : '-')}</td>
          <td className='hideMobile'>{`${msl.wasteGrams / 1000} KG`}</td>
          
          { ((orgAdmin && diff > -90) || (diff > -30)) && (
            <td style={{ width: '100px', background: '#fff', padding: '0' }}>
              <button
                type="button"
                className="button is-primary is-small is-fullwidth is-uppercase"
                style={{ margin: '0 0 2px 0', padding: '10px' }}
                onClick={() =>
                  setWasteLogInEdit({
                    ...msl,
                    wasteKg: parseFloat(msl.wasteGrams / 1000, 10),
                    preparedAmountKg: parseFloat(
                      msl.preparedAmountGrams / 1000,
                      10
                    ),
                    selectedFood: msl.food,
                    selectedWasteType: msl.wasteType,
                    selectedCourse: msl.course,
                  })
                }
              >
                <Trans>muokkaa</Trans>
              </button>

              <button
                type="button"
                className="button is-primary-background is-small is-fullwidth is-uppercase"
                style={{ margin: '0 0 0 0', color: '#fff', padding: '10px' }}
                onClick={() => {
                  setFoodToAppend(msl)
                  setAppendModalOpen(true)
                }}
              >
                <Trans>lisää</Trans>
              </button>
              
              { orgAdmin && (
                <button
                  type="button"
                  className="button is-danger is-small is-fullwidth is-uppercase"
                  style={{ margin: '2px 0 0 0', padding: '10px' }}
                  onClick={() => {
                    setFoodToRemove(msl.id)
                    setRemoveModalOpen(true)
                  }}
                >
                  <Trans>poista</Trans>
                </button>
              )}
            </td>
          )}
        </tr>
      )
    })

    const removeFoodModal = (
    <div className={`modal ${removeModalOpen ? 'is-active' : null}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title"><Trans>Vahvista poisto</Trans></p>
        </header>
        <section className="modal-card-body">
          {t('Haluatko varmasti poistaa tämän kirjauksen')+'?'}
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-success is-danger is-large"
            onClick={() => {
              deleteWasteLog(foodToRemove)
              setRemoveModalOpen(false)
            }}
          >
            <Trans>Kyllä</Trans>
          </button>
          <button
            type="button"
            className="button is-large"
            onClick={() => setRemoveModalOpen(false)}
          >
            <Trans>Ei</Trans>
          </button>
        </footer>
      </div>
    </div>
  )

  const appendFoodModal = (
    <div className={`modal ${appendModalOpen ? 'is-active' : null}`}>
      <div className="modal-background" />
      <div className="modal-card" style={{textAlign: 'center'}}>
        <header className="modal-card-head">
          { lineWaste && foodToAppend && foodToAppend.wasteType === lineWaste.id && (
            <p className="modal-card-title">{foodToAppend.foodName}</p>
          )}
          { lineWaste && foodToAppend && foodToAppend.wasteType !== lineWaste.id && (
            <p className="modal-card-title">{wasteTypes.find(wt => wt.id === foodToAppend.wasteType).name}</p>
          )}
        </header>
        <section className="modal-card-body">
          { foodToAppend && (<>
            { lineWaste && foodToAppend.wasteType === lineWaste.id && (<>
              <div
                className='is-primary-background'
                style={{padding: '5px', borderRadius: '8px'}}
              >
                <h2>
                  {t('Lisää tarjoilumäärään')}
                </h2>

                <input
                  className="is-large has-text-primary is-expanded"
                  placeholder="Syötä tarjoilumäärä kiloina."
                  style={{ fontSize: '16px', flex: 1 }}
                  type="text"
                  inputMode="numeric"
                  value={
                    preparedAppend ? preparedAppend.toString() : 0
                  }
                  onChange={e => {
                    let val = e.target.value
                    if (parseFloat(val) >= 1000) {
                      val = 1000
                    }
                    setPreparedAppend(validateNumeric(val))
                  }}
                />

                <p>{t('Tarjoilumäärä') + ' ' + t('yhteensä')} {parseFloat((foodToAppend.preparedAmountGrams / 1000).toFixed(1)) + parseFloat((parseFloat(preparedAppend)).toFixed(1))} kg </p>
              </div>
              <br />
            </>)}

            <div
              className='is-secondary-background'
              style={{padding: '5px', borderRadius: '8px', color: '#fff'}}
            >
              <h2>
                {t('Lisää hävikkimäärään')}
              </h2>

              <input
                className="is-large has-text-primary is-expanded"
                placeholder="Syötä hävikkimäärä kiloina."
                style={{ fontSize: '16px', flex: 1 }}
                type="text"
                inputMode="numeric"
                value={
                  wasteAppend ? wasteAppend.toString() : 0
                }
                onChange={e => {
                  let val = e.target.value
                  if (parseFloat(val) >= 1000) {
                    val = 1000
                  }
                  setWasteAppend(validateNumeric(val))
                }}
              />

              <p>{t('Hävikkimäärä') + ' ' + t('yhteensä')} {parseFloat((foodToAppend.wasteGrams / 1000).toFixed(1)) + parseFloat((parseFloat(wasteAppend)).toFixed(1))} kg </p>
            </div>
          </>)}
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-success is-large"
            onClick={() => {
              let values = {
                course: foodToAppend.course,
                food: foodToAppend.food,
                meal_serving: foodToAppend.mealServing,
                waste_type: foodToAppend.wasteType,
                prepared_amount_grams: parseInt(foodToAppend.preparedAmountGrams) + parseInt(preparedAppend * 1000),
                waste_grams: parseInt(foodToAppend.wasteGrams) + parseInt(wasteAppend * 1000)
              }
              editLog(foodToAppend.id, values)
            }}
          >
            <Trans>Lisää</Trans>
          </button>
          <button
            type="button"
            className="button is-large"
            onClick={() => setAppendModalOpen(false)}
          >
            <Trans>Peruuta</Trans>
          </button>
        </footer>
      </div>
    </div>
  )

    useEffect(() => {
      if (selectedMealServing !== selected) {
        setSelected(selectedMealServing)
        setCustomerAmount(mealServings.find(ms => ms.id === selectedMealServing).customers)
        setNotes(mealServings.find(ms => ms.id === selectedMealServing).servingNotes)
      }
    }, [selectedMealServing, mealServings, selected])
  
    const handleMealServingEdit = async (customerAmountVal, notesVal) => {
      setSaving(true)
      const resp = await patch(MEALSERVINGS_URL, selectedMealServing, {
        meal_serving: selectedMealServing,
        customers: customerAmountVal === false ? customerAmount : customerAmountVal,
        serving_notes: notesVal === false ? notes : notesVal
      })
  
      if (resp.status === 200) {
        setSaving(t('Muutokset tallennettu')+'.')
        dispatch(updateMealServings(resp.data))
      } else {
        setSaving(t('Jokin meni vikaan')+'.')
        console.log(resp.data)
      }
    }

  return (
    <div
      style={{textAlign: 'left', marginBottom: '10px'}}
    >
      <div className="label"><Trans>Asiakasmäärä</Trans> <span style={{fontSize: '10px', color: '#777'}}>{saving}</span></div>
      <div
        className={'control has-icons-left is-large has-text-primary is-clearfix' + (customerAmount === 0 ? ' red-border' : '')}
      >
        <input
          type="number"
          placeholder={t('Asiakasmäärä')}
          className="input is-large has-text-primary"
          value={customerAmount || 0}
          onChange={e => {
            let val = e.target.value
            setCustomerAmount(val)
            setSaving(t('Tallennetaan')+'...')
            clearTimeout(savingTimeout)
            setSavingTimeout(setTimeout(function () {
              handleMealServingEdit(val,false)
            }, 2000))
          }}
          onFocus={e => e.target.select()}
        />
        <span className="icon is-left">
          <i className="mdi mdi-account-multiple mdi-24px" />
        </span>
      </div>
      <div className="field" style={{marginTop: '5px'}}>
        <div className="label"><Trans>Huomiot</Trans> <span style={{fontSize: '10px', color: '#777'}}>{saving}</span></div>
        <div className="control has-icons-left is-large has-text-primary is-clearfix">
          <input
            type="text"
            placeholder={t('Huomiot')}
            className="input is-large has-text-primary"
            value={notes || ''}
            onChange={e => {
              let val = e.target.value
              setNotes(val)
              setSaving(t('Tallennetaan...'))
              clearTimeout(savingTimeout)
              setSavingTimeout(setTimeout(function () {
                handleMealServingEdit(false,val)
              }, 2000))
            }}
          />
          <span className="icon is-left">
            <i className="mdi mdi-pencil mdi-24px" />
          </span>
        </div>
      </div>
      <div>
        <br />
        <ul>
          <li><div style={{width: '12px', height: '12px', background: wasteTypeColor({name: 'Linjastohävikki'}), display: 'inline-block'}}></div> <Trans>Linjastohävikki</Trans></li>
          <li><div style={{width: '12px', height: '12px', background: wasteTypeColor({name: 'Lautashävikki'}), display: 'inline-block'}}></div> <Trans>Lautashävikki</Trans></li>
          <li><div style={{width: '12px', height: '12px', background: wasteTypeColor({name: 'Keittiöhävikki'}), display: 'inline-block'}}></div> <Trans>Keittiöhävikki</Trans></li>
          <li><div style={{width: '12px', height: '12px', background: wasteTypeColor({name: 'Varastohävikki'}), display: 'inline-block'}}></div> <Trans>Varastohävikki</Trans></li>
        </ul>
      </div>
      <div className="table-container" style={{ overflowX: 'auto' }}>
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
            <tr className='hideDesktop'>
              <th><Trans>Ruoka</Trans><br />-<br /><Trans>Aterian osa</Trans></th>
              <th><Trans>Tarjoiltu</Trans><br />-<br /><Trans>Hävikki</Trans></th>
            </tr>
            <tr className='hideMobile'>
              <th><Trans>Ruoka</Trans></th>
              <th><Trans>Aterian osa</Trans></th>
              <th><Trans>Tarjoiltu</Trans></th>
              <th><Trans>Hävikki</Trans></th>
            </tr>
          </thead>
          <tbody>{wasteRows}</tbody>
        </table>
      </div>
      {wasteTypes && removeFoodModal}
      {wasteTypes && appendFoodModal}
    </div>
  )
}

MealServingLogsTable.propTypes = {
  mealServingLogs: arrayOf(
    shape({
      wasteType: string,
      food: string,
      preparedAmountGrams: number,
      wasteGrams: number,
      id: string,
    })
  ).isRequired,
  wasteTypes: arrayOf(
    shape({
      id: string,
    })
  ).isRequired,
  foods: arrayOf(
    shape({
      id: string,
    })
  ).isRequired,
  isStaff: bool.isRequired,
  orgAdmin: bool.isRequired,
  selectedMealServing: string.isRequired,
  setWasteLogInEdit: func.isRequired,
  courses: arrayOf(shape({ id: string, name: string })).isRequired,
  deleteWasteLog: func.isRequired,
}

export default MealServingLogsTable

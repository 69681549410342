import lang_en from './locales/en.json'
import lang_sv from './locales/sv.json'
import lang_es from './locales/es.json'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    nsSeparator: false,
    keySeparator: false,
    resources: {
      en: {
        translation: lang_en
      },
      sv: {
        translation: lang_sv
      },
      es: {
        translation: lang_es
      }
    }
  })

moment.locale(i18n.language.substring(0,2))
// Set first day of week to be monday
moment.updateLocale(i18n.language.substring(0,2), {
  week: {
    dow: 1
  }
})

export default i18n;
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'

const Insights = () => {
	const [selectedTime, setSelectedTime] = useState('week')
	const { simpleinsight, monthSimpleinsight } = useSelector(
    state => state
  )
  const { t } = useTranslation()
	if (!simpleinsight || !monthSimpleinsight) {
		return (<p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>)
	}

	const InsightHtml = () => {
		let co2Html = []
		let wasteHtml = []
		let platewasteHtml = []
		let logsCurrentHtml = []
		let logsComparisonHtml = []

		let co2Data = []
		let wasteData = []
		let platewasteData = []
		let logsCurrentData = []
		let logsComparisonData = []

		let co2DataEmpty = []
		let wasteDataEmpty = []
		let platewasteDataEmpty = []
		let logsCurrentDataEmpty = []
		let logsComparisonDataEmpty = []

		let insightArr = []

		if (selectedTime === 'week') {
			insightArr = simpleinsight
		} else if (selectedTime === 'month') {
			insightArr = monthSimpleinsight
		}

		insightArr.forEach(function (arr) {
			let restaurantName = arr.restaurantName

			let co2 = false
			let waste = false
			let platewaste = false
			let activity = false

			if (arr.insights.length > 0) {
				arr.insights.forEach(function (insight) {
          let key = Object.keys(insight)[0]
          let amount = false
          let className = ''
          if (key !== 'activity') {
          	amount = Math.round((insight[key].current - insight[key].comparison) / insight[key].comparison * 100)
	          if (amount > 0) {
	          	className = 'text-danger-color'
	          } else if (amount < 0) {
	          	className = 'text-primary-color'
	          }
          }
          if (key === 'co2') {
          	co2 = true
          	co2Data.push({
          		name: restaurantName,
          		amount: amount,
          		className: className
          	})
          } else if (key === 'totalWaste') {
          	waste = true
          	wasteData.push({
          		name: restaurantName,
          		amount: amount,
          		className: className
          	})
					} else if (key === 'plateWaste') {
          	platewaste = true
						platewasteData.push({
          		name: restaurantName,
          		amount: amount,
          		className: className
          	})
					} else if (key === 'activity') {
						activity = true
						logsCurrentData.push({
							name: restaurantName,
							servings: insight[key].currentServings,
							zeroes: insight[key].currentZeroes,
							percentage: insight[key].currentServings / (insight[key].currentServings + insight[key].currentZeroes) * 100
						})
						logsComparisonData.push({
							name: restaurantName,
							servings: insight[key].comparisonServings,
							zeroes: insight[key].comparisonZeroes,
							percentage: insight[key].comparisonServings / (insight[key].comparisonServings + insight[key].comparisonZeroes) * 100
						})
					}
        })
			}

			if (!co2) {
				co2DataEmpty.push(restaurantName)
			}
			if (!waste) {
				wasteDataEmpty.push(restaurantName)
			}
			if (!platewaste) {
				platewasteDataEmpty.push(restaurantName)
			}
			if (!activity) {
				logsCurrentDataEmpty.push(restaurantName)
				logsComparisonDataEmpty.push(restaurantName)
			}
		})

		co2Data.sort((a, b) => {
	    if (a.amount > b.amount) return 1
	    if (a.amount < b.amount) return -1
	    return 0
	  })

	  wasteData.sort((a, b) => {
	    if (a.amount > b.amount) return 1
	    if (a.amount < b.amount) return -1
	    return 0
	  })

	  platewasteData.sort((a, b) => {
	    if (a.amount > b.amount) return 1
	    if (a.amount < b.amount) return -1
	    return 0
	  })

	  logsCurrentData.sort((a, b) => {
	    if (a.percentage < b.percentage) return 1
	    if (a.percentage > b.percentage) return -1
	    return 0
	  })

	  logsComparisonData.sort((a, b) => {
	    if (a.percentage < b.percentage) return 1
	    if (a.percentage > b.percentage) return -1
	    return 0
	  })

		co2Data.forEach(function (data) {
			co2Html.push(<li>{data.name}: <span className={data.className} style={{fontWeight: '700'}}>{data.amount} %</span></li>)
		})

		wasteData.forEach(function (data) {
			wasteHtml.push(<li>{data.name}: <span className={data.className} style={{fontWeight: '700'}}>{data.amount} %</span></li>)
		})

		platewasteData.forEach(function (data) {
			platewasteHtml.push(<li>{data.name}: <span className={data.className} style={{fontWeight: '700'}}>{data.amount} %</span></li>)
		})

		logsCurrentData.forEach(function (data) {
			logsCurrentHtml.push(<li>{data.name}: <span style={{fontWeight: '700'}}>{Math.round(data.percentage)} % ({data.servings}/{data.servings + data.zeroes})</span></li>)
		})

		logsComparisonData.forEach(function (data) {
			logsComparisonHtml.push(<li>{data.name}: <span style={{fontWeight: '700'}}>{Math.round(data.percentage)} % ({data.servings}/{data.servings + data.zeroes})</span></li>)
		})


		co2DataEmpty.forEach(function (name) {
			co2Html.push(<li>{name}: <span>---</span></li>)
		})

		wasteDataEmpty.forEach(function (name) {
			wasteHtml.push(<li>{name}: <span>---</span></li>)
		})

		platewasteDataEmpty.forEach(function (name) {
			platewasteHtml.push(<li>{name}: <span>---</span></li>)
		})

		logsCurrentDataEmpty.forEach(function (name) {
			logsCurrentHtml.push(<li>{name}: <span>---</span></li>)
		})

		logsComparisonDataEmpty.forEach(function (name) {
			logsComparisonHtml.push(<li>{name}: <span>---</span></li>)
		})

		return (<div>
			{ selectedTime === 'week' && (
		      <h2 className="title is-2 is-size-4-mobile is-uppercase" style={{marginBottom: '0'}}>{t('Viikko')} {moment().subtract(1, 'week').isoWeek()} ({t('Edellinen viikko')}) {t('vs')} {t('Viikko')} {moment().subtract(2, 'week').isoWeek()}</h2>
		    )}
		    { selectedTime === 'month' && (
		      <h2 className="title is-2 is-size-4-mobile is-uppercase" style={{marginBottom: '0'}}>{moment().subtract(1, 'month').format('MMMM')} ({t('Edellinen kuukausi')}) {t('vs')} {moment().subtract(2, 'month').format('MMMM')}</h2>
		    )}
			<br />
			<div style={{display: 'block'}}>
				<div className="insightlist">
					<h2>{t('CO2')}</h2>
					<ul>
						{co2Html}
					</ul>
				</div>
				<div className="insightlist">
					<h2>{t('Kokonaishävikki')}</h2>
					<ul>
						{wasteHtml}
					</ul>
				</div>
				<div className="insightlist">
					<h2>{t('Lautashävikki')}</h2>
					<ul>
						{platewasteHtml}
					</ul>
				</div>
			</div>
			<br />
			<div style={{display: 'block'}}>
				<div className="insightlist">
					<h2>{t('Kirjaus')} % {selectedTime === 'week' ? (t('Viikko') + ' ' + moment().subtract(1, 'week').isoWeek()) : moment().subtract(1, 'month').format('MMMM')}</h2>
					<ul>
						{logsCurrentHtml}
					</ul>
				</div>
			</div>
		</div>)
	}
  
  return (<div>
  	<h1 className="title is-1 is-size-3-mobile is-uppercase">
      {t('Yleiskatsaus ravintoloittain')}
    </h1>
  	<button
      type="button"
      className={'button is-small' + (selectedTime === 'week' ? ' is-primary-background' : '')}
      style={{margin: '5px'}}
      onClick={() => {
        setSelectedTime('week')
      }}
    >
      <Trans>Viikko</Trans>
    </button>
    <button
      type="button"
      className={'button is-small' + (selectedTime === 'month' ? ' is-primary-background' : '')}
      style={{margin: '5px'}}
      onClick={() => {
        setSelectedTime('month')
      }}
    >
      <Trans>Kuukausi</Trans>
    </button>

    {InsightHtml()}
  </div>)
}

export default Insights

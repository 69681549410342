import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import noSleep from 'nosleep.js'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { HUKKAPROFILES_URL, patch } from '../../utility/api'
import { updateHukkaProfiles } from '../../actions'
import HukkaSelect from '../hukkaSelect'
import Carousel from './carousel'

const PlateWaste = () => {
  const dispatch = useDispatch()
  const {
    mealTypes,
    restaurants,
    hukkaProfiles,
    hukkaServices,
    hukkaServiceSubscriptions
  } = useSelector(state => state)
  const { t } = useTranslation()
  const chartRef = useRef(null)
  const { id, loggedIn, rou:readOnlyUser, token } = useSelector(state => state.user) || { loggedIn: false, readOnlyUser: false }
  const [selectedMealTypes, setSelectedMealTypes] = useState([])
  const [infoSlideText, setInfoSlideText] = useState('')
  const [earlierInfoSlideText, setEarlierInfoSlideText] = useState('')
  
  let noSleepObj = new noSleep()

  const saveInfoSlideText = async () => {
    let userData = hukkaProfiles.find(hp => hp.id === id) ? JSON.parse(JSON.stringify(hukkaProfiles.find(hp => hp.id === id).data)) : null
    let plateWasteData = {}
    if (userData && userData['platewaste']) {
      plateWasteData = userData['platewaste']
    }

    plateWasteData['infoSlideText'] = infoSlideText

    if (userData === null) {
      userData = {
        platewaste: plateWasteData
      }
    } else {
      userData['platewaste'] = plateWasteData
    }

    const body = {
      data: userData
    }
    
    const resp = await patch(HUKKAPROFILES_URL, id, body)

    if (resp.status === 201) {
      dispatch(updateHukkaProfiles(resp.data))
    } else {
      console.log({saveInfoSlideText: resp.data})
    }
  }

  const fullscreen = () => {
    if (infoSlideText !== earlierInfoSlideText) {
      saveInfoSlideText()
    }

    let elem = document.getElementById('fullscreenElement')
    elem.style.display = 'block'

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem = window.top.document.body; //To break out of frame in IE
      elem.msRequestFullscreen();
    }
    
    noSleepObj.enable()
  }

  const mealTypeOptions = mealTypes && mealTypes.slice()
    .sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    .map(r => ({
      value: r.id,
      label: t(r.name),
    }))
    .concat([
      {
        value: 'all',
        label: t('Valitse kaikki ateriat'),
      },
    ])


  const plateWasteView = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '85vh',
      }}
      ref={chartRef}
    >
      <div style={{width: '100%'}}>
        <div className="title is-3 uppercase" style={{ margin: 0 }}>
          {restaurants && restaurants[0].name}
        </div>
        <div style={{width: '100%', maxWidth: '800px', margin: 'auto'}}>
          <h2>{t('Näytettävät ateriatyypit')}</h2>
          <HukkaSelect
            options={mealTypeOptions ? mealTypeOptions : []}
            value={selectedMealTypes ? selectedMealTypes : []}
            isMulti
            disableText
            style={{width: '100%'}}
            placeholder={t('Valitse näytettävät ateriat')}
            onChange={e => {
              if (e && e.find(r => r.value === 'all')) {
                setSelectedMealTypes(mealTypeOptions.filter(mto => mto.value !== 'all'))
              } else {
                setSelectedMealTypes(e)
              }
            }}
          />
        </div>
        <br />
      </div>
      <button
        type="button"
        disabled={!selectedMealTypes || (selectedMealTypes && selectedMealTypes.length <= 0)}
        className={'hukkaBtn' + ((!selectedMealTypes || (selectedMealTypes && selectedMealTypes.length <= 0)) ? ' hukkaBtn--disabled' : '')}
        onClick={() => fullscreen()}
      >
        {
          ((!selectedMealTypes || (selectedMealTypes && selectedMealTypes.length <= 0)) ? (t('Käynnistä lautashävikki') + ' (' + t('ei aterioita valittuna') + ')') : t('Käynnistä lautashävikki'))
        }
      </button>

      <br />
      <br />
      <br />
      <br />

      <div>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            let copyText = 'https://' + window.location.hostname + '/plate-waste-view?token=' + token
            document.addEventListener('copy', function(e) {
              e.clipboardData.setData('text/plain', copyText);
              e.preventDefault();
            }, true);
            document.execCommand('copy');
          }}
        >
          {t('Kopioi suora url infonäyttöjä varten')}
        </button>
      </div>

      <br />

      <div id="fullscreenElement" style={{position: 'fixed', top: '-100%'}}>
        <Carousel selectedMealTypes={selectedMealTypes}/>
      </div>
    </div>
  )

  const nonReadOnlyView = () => {
    return (<div></div>)
  }

  const showPlateWasteView = () => {
    let hasPlateWasteService = false
    if (hukkaServices && hukkaServiceSubscriptions) {
      let plateWasteService = hukkaServices.find(hs => hs.name === 'Lautashävikki')
      if (hukkaServiceSubscriptions.find(hss => plateWasteService && hss.service === plateWasteService.id && moment(new Date()) < moment(hss.validUntil))) {
        hasPlateWasteService = true
        let profile = hukkaProfiles.find(hp => hp.id === id)
        if (profile) {
          if (profile.data !== null && profile.data.platewaste && profile.data.platewaste.infoSlideText) {
            setInfoSlideText(profile.data.platewaste.infoSlideText)
            setEarlierInfoSlideText(profile.data.platewaste.infoSlideText)
          }
        }
      }
    }
    return hasPlateWasteService ? plateWasteView() : (<p className="is-size-2 is-size-3-mobile is-uppercase" style={{marginTop: '30px'}}>{t('Hei! Valitettavasti sinulla ei ole oikeuksia tämän palvelun käyttöön, mutta jos haluat tietää lisää, niin ota yhteyttä mailitse')}:<br /><a href="mailto:asiakaspalvelu@hukka.ai">asiakaspalvelu@hukka.ai</a></p>)
  }


  /* General */

  return (
    <>
      {!hukkaProfiles && (<p className="is-size-2 is-size-3-mobile is-uppercase">{t('Ladataan')}...</p>)}
      {loggedIn && hukkaProfiles && hukkaServices && hukkaServiceSubscriptions && !readOnlyUser ? nonReadOnlyView() : ''}
      {loggedIn && hukkaProfiles && hukkaServices && hukkaServiceSubscriptions && readOnlyUser ? showPlateWasteView() : ''}
    </>
  )
}

export default PlateWaste

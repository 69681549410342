import React, { useState, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { get, post, patch, del, FORMFIELDTEMPLATES_URL, FORMTEMPLATES_URL, FIELDORDERS_URL, FORMS_URL, FORMROWS_URL, FORMFIELDS_URL } from '../../utility/api'
import { useTranslation } from 'react-i18next';
import Modal from '../modal'
import HukkaSelect from '../hukkaSelect'
import { updateFormFieldTemplates, updateFormTemplates, updateForms, updateFieldOrders } from '../../actions'

const Forms = () => {
  const { t } = useTranslation()
  const {orgs, restaurants, formFieldTemplates, formTemplates, fieldOrders, forms, formRows, hukkaProfiles} = useSelector(
    state => state
  )
  const { id } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [newFormTemplateModalActive, setNewFormTemplateModalActive] = useState(false)
  const [newFormFields, setNewFormFields] = useState([])
  const [newFormName, setNewFormName] = useState('')
  const [newFormActive, setNewFormActive] = useState(true)
  const [newFormGracePeriod, setNewFormGracePeriod] = useState('')
  const [newFormFieldRender, setNewFormFieldRender] = useState(0)
  const [newFormSelectedRestaurants, setNewFormSelectedRestaurants] = useState([])
  const [newField, setNewField] = useState(false)
  const [newFieldName, setNewFieldName] = useState('')
  const [newFieldType, setNewFieldType] = useState(null)
  const [editForm, setEditForm] = useState(null)
  const [cronSelectorType, setCronSelectorType] = useState({label: t('Viikonpäivävalinta'), value: 'weekday'})
  const [cronSelectedHours, setCronSelectedHours] = useState([])
  const [cronSelectedDays, setCronSelectedDays] = useState([])
  const [cronSelectedMonths, setCronSelectedMonths] = useState([])
  const [cronSelectedWeekDays, setCronSelectedWeekDays] = useState([])
  const [errors, setErrors] = useState('')
  const [onlyViewActiveForms, setOnlyViewActiveForms] = useState(true)

  const newFormSelectedFields = newFormFields && newFormFields.length > 0 ? newFormFields.map(nff => nff.value) : []
  const formFieldOptions = formFieldTemplates && formFieldTemplates.length > 0 ? formFieldTemplates.filter(fft => newFormSelectedFields.indexOf(fft.id) === -1).map(fft => {
    let type = ''
    if (fft.type === 'text') {
      type = 'teksti'
    } else if (fft.type === 'float') {
      type = 'numero'
    }
    return {
      value: fft.id,
      label: fft.name + ' (' + t(type) + ')'
    }
  }) : []

  const getRestaurantOptions = useCallback(
    () =>
      [{ value: 'allWaste', label: '--- ' + t('Lisää kaikki ravintolat') + ' ---' }]
      .concat(restaurants.slice()
      .sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      })
      .map(r => ({
        value: r.id,
        label: r.name,
      }))),
    [restaurants, t]
  )

  const removeDublicatesAndNonExist = (arr) => {
    let uniqueIds = []
    let uniqueExist = []
    arr.forEach(function (a) {
      if (uniqueIds.indexOf(a.value) === -1) {
        uniqueIds.push(a.value)
        if (restaurants.find(r => r.id === a.value)) {
          uniqueExist.push(a)
        }
      }
    })
    return uniqueExist
  }

  const restaurantGroupOptions = hukkaProfiles && hukkaProfiles.find(hp => hp.id === id).data &&
  hukkaProfiles.find(hp => hp.id === id).data.restaurantGroups
    .map(rg => ({
      value: rg.restaurants,
      label: rg.name,
    }))

  const restaurantGroupSelect = (<>
    <div className="select is-fullwidth hukkaselect">
      <HukkaSelect
        isDisabled={newField !== false ? true : false}
        options={restaurantGroupOptions ? restaurantGroupOptions : []}
        value={''}
        placeholder={t('Valitse ravintolaryhmä')}
        onChange={e => {
          if (newFormSelectedRestaurants) {
            let selected = removeDublicatesAndNonExist(newFormSelectedRestaurants.concat(e.value))
            setNewFormSelectedRestaurants(selected)
          } else {
            setNewFormSelectedRestaurants(e.value)
          }
        }}
      />
    </div>
    <i style={{textAlign: 'left', display: 'block', margin: '-10px 0 10px 0'}}>{t('Lisää/Muokkaa ryhmiä')} <a href="/profile">{t('profiilisivulla')}</a></i>
  </>)

  const fieldTypes = [
    {
      value: 'text',
      label: t('Teksti')
    },
    {
      value: 'float',
      label: t('Numero')
    }
  ]

  const cronMonthOptions = [
    {
      value: 1,
      label: t('tammikuu')
    },
    {
      value: 2,
      label: t('helmikuu')
    },
    {
      value: 3,
      label: t('marraskuu')
    },
    {
      value: 4,
      label: t('huhtikuu')
    },
    {
      value: 5,
      label: t('toukokuu')
    },
    {
      value: 6,
      label: t('kesäkuu')
    },
    {
      value: 7,
      label: t('heinäkuu')
    },
    {
      value: 8,
      label: t('elokuu')
    },
    {
      value: 9,
      label: t('syyskuu')
    },
    {
      value: 10,
      label: t('lokakuu')
    },
    {
      value: 11,
      label: t('marraskuu')
    },
    {
      value: 12,
      label: t('joulukuu')
    }
  ]

  const cronWeekDayOptions = [
    {
      value: 1,
      label: t('maanantai')
    },
    {
      value: 2,
      label: t('tiistai')
    },
    {
      value: 3,
      label: t('keskiviikko')
    },
    {
      value: 4,
      label: t('torstai')
    },
    {
      value: 5,
      label: t('perjantai')
    },
    {
      value: 6,
      label: t('lauantai')
    },
    {
      value: 0,
      label: t('sunnuntai')
    }
  ]

  const cronWeekDayNoWeekends = [
    {
      value: 1,
      label: t('maanantai')
    },
    {
      value: 2,
      label: t('tiistai')
    },
    {
      value: 3,
      label: t('keskiviikko')
    },
    {
      value: 4,
      label: t('torstai')
    },
    {
      value: 5,
      label: t('perjantai')
    }
  ]

  const cronDayOptions = () => {
    let maxDays = 31

    let selectedMonths = cronSelectedMonths.map(csm => csm.value)


    if (selectedMonths.indexOf(2) !== -1) {
      maxDays = 28
    } else if (selectedMonths.indexOf(4) !== -1 || selectedMonths.indexOf(6) !== -1 || selectedMonths.indexOf(9) !== -1 || selectedMonths.indexOf(11) !== -1) {
      maxDays = 30
    }

    let dayArr = []

    for (let i = 0; i < maxDays; i++) {
      dayArr.push({
        label: i + 1,
        value: i + 1
      })
    }

    dayArr.push({
      label: t('Kuun viimeinen päivä'),
      value: 'L'
    })

    return dayArr
  }

  const cronHourOptions = () => {
    let maxHours = 24

    let hourArr = []

    for (let i = 0; i < maxHours; i++) {
      hourArr.push({
        label: i < 10 ? '0' + i : i,
        value: i
      })
    }

    return hourArr
  }

  const createNewFieldTemplate = async () => {
    let payload = {
      name: newFieldName,
      type: newFieldType.value,
      org: orgs[0].id
    }
    const resp = await post(FORMFIELDTEMPLATES_URL, payload)

    if (resp.status === 201) {
      const resp2 = await get(FORMFIELDTEMPLATES_URL)
      
      if (resp2.status === 200) {
        dispatch(updateFormFieldTemplates(resp2.data))
        let fields = newFormFields
        fields[newField] = {
          value: resp.data.id,
          label: resp.data.name + ' (' + t(resp.data.type) + ')'
        }

        setNewFormFields(fields)
        setNewFormFieldRender(newFormFieldRender + 1)
        setNewField(false)
      } else {
        console.log({error: resp2.data})
      }
    } else {
      console.log({error: resp.data})
    }
    setNewFieldName('')
    setNewFieldType('')
  }
  
  const createNewFormTemplate = async () => {
    let cronMinutes = 0
    let cronHours = cronSelectedHours.length > 0 ? cronSelectedHours.map(c => c.value).join(',') : '*'
    let cronDays = cronSelectedDays.length > 0 && cronSelectorType.value === 'day' ? cronSelectedDays.map(c => c.value).join(',') : (cronSelectorType.value === 'lastday' ? 'L' : '*')
    let cronMonths = cronSelectedMonths.length > 0 ? cronSelectedMonths.map(c => c.value).join(',') : '*'
    let cronWeekDays = cronSelectedWeekDays.length > 0 && cronSelectorType.value === 'weekday' ? cronSelectedWeekDays.map(c => c.value).join(',') : '*'

    let payload = {
      name: newFormName,
      org: orgs[0].id,
      active: newFormActive,
      cron: cronMinutes + ' ' + cronHours + ' ' + cronDays + ' ' + cronMonths + ' ' + cronWeekDays,
      grace_period: newFormGracePeriod * 60
    }
    const resp = await post(FORMTEMPLATES_URL, payload)

    if (resp.status === 201) {
      let formId = resp.data.id
      if (newFormSelectedFields.length > 0) {
        let payload2 = []
        newFormSelectedFields.forEach(function (field, index) {
          if (field && typeof field !== 'undefined' && field !== '') {
            payload2.push({
              field_template: field,
              form_template: formId,
              order: index
            })
          }
        })
        const resp2 = await post(FIELDORDERS_URL, payload2)
        if (resp2.status !== 201) {  
          console.log({error: resp2.data}) 
        }
      }

      if (newFormSelectedRestaurants.length > 0) {
        let payload3 = []
        newFormSelectedRestaurants.forEach(function (restaurant) {
          payload3.push({
            restaurant: restaurant.value,
            template: formId,
            active: true
          })
        })
        const resp3 = await post(FORMS_URL, payload3)
        if (resp3.status !== 201) {  
          console.log({error: resp3.data})
        }
      }

    } else {
      console.log({error: resp.data})
    }

    const formTemplateResp = await get(FORMTEMPLATES_URL)
    if (formTemplateResp.status === 200) {
      dispatch(updateFormTemplates(formTemplateResp.data))
      
      const fieldOrderResp = await get(FIELDORDERS_URL)
      if (fieldOrderResp.status === 200) {
        dispatch(updateFieldOrders(fieldOrderResp.data))

        const formsResp = await get(FORMS_URL)
        if (formsResp.status === 200) {
          dispatch(updateForms(formsResp.data))
        } else {
          console.log({error: formsResp.data})
        }
      } else {
        console.log({error: fieldOrderResp.data})
      }
    } else {
      console.log({error: formTemplateResp.data})
    }

    setNewFormTemplateModalActive(false)
    setNewFormName('')
    setNewFormActive(true)
    setNewFormGracePeriod('')
    setNewFormFields([])
    setNewFormSelectedRestaurants([])
  }

  const editFormTemplate = async () => {
    let activeFieldIds = newFormFields.map(nff => nff.value)
    let savedFields = fieldOrders.filter(fo => fo.formTemplate === editForm)

    let cronMinutes = 0
    let cronHours = cronSelectedHours.length > 0 ? cronSelectedHours.map(c => c.value).join(',') : '*'
    let cronDays = cronSelectedDays.length > 0 && cronSelectorType.value === 'day' ? cronSelectedDays.map(c => c.value).join(',') : (cronSelectorType.value === 'lastday' ? 'L' : '*')
    let cronMonths = cronSelectedMonths.length > 0 ? cronSelectedMonths.map(c => c.value).join(',') : '*'
    let cronWeekDays = cronSelectedWeekDays.length > 0 && cronSelectorType.value === 'weekday' ? cronSelectedWeekDays.map(c => c.value).join(',') : '*'

    let newFields = []
    let fieldOrder = 0

    activeFieldIds.forEach(function (id) {
      newFields.push({
          field_template: id,
          form_template: editForm,
          order: fieldOrder
      })
      fieldOrder++
    })
    let deleteFields = savedFields.map(sf => sf.id)

    let activeRestaurantIds = newFormSelectedRestaurants.map(r => r.value)
    let savedRestaurantForms = forms.filter(f => f.template === editForm)
    let savedRestaurantFormIds = savedRestaurantForms.map(f => f.restaurant)

    let newRestaurantForms = []
    let activateRestaurantForms = []

    activeRestaurantIds.forEach(function (id) {
      if (savedRestaurantFormIds.indexOf(id) === -1) {
        newRestaurantForms.push(id)
      } else if (savedRestaurantForms.find(srf => srf.restaurant === id).active === false) {
        activateRestaurantForms.push(savedRestaurantForms.find(srf => srf.restaurant === id).id)
      }
    })
    let deactivateRestaurantForms = savedRestaurantForms.filter(srf => activeRestaurantIds.indexOf(srf.restaurant) === -1).map(srf => srf.id)

    let payload = {
      name: newFormName,
      org: orgs[0].id,
      active: newFormActive,
      cron: cronMinutes + ' ' + cronHours + ' ' + cronDays + ' ' + cronMonths + ' ' + cronWeekDays,
      grace_period: newFormGracePeriod * 60
    }
    const resp = await patch(FORMTEMPLATES_URL, editForm, payload)

    if (resp.status === 200) {

      if (deleteFields.length > 0) {
        await Promise.all(deleteFields.map(async field => {
          const promise = await del(FIELDORDERS_URL, field)
          return promise
        })).then(results => {
          if (results.status !== 204) {
            console.log({error: results.data})
          }
        })
      }

      if (newFields.length > 0) {
        await Promise.all(newFields.map(async field => {
          const promise = await post(FIELDORDERS_URL, field)
          return promise
        })).then(results => {
          if (results.status !== 201) {
            console.log({error: results.data})
          }
        })
      }

      if (newRestaurantForms.length > 0) {
        await Promise.all(newRestaurantForms.map(async restaurant => {
          const promise = await post(FORMS_URL, {restaurant, template: editForm, active: true})
          return promise
        })).then(results => {
          if (results.status !== 201) {
            console.log({error: results.data})
          }
        })
      }

      if (activateRestaurantForms.length > 0) {
        await Promise.all(activateRestaurantForms.map(async form => {
          const promise = await patch(FORMS_URL, form, {active: true})
          return promise
        })).then(results => {
          if (results.status !== 200) {
            console.log({error: results.data})
          }
        })
      }

      if (deactivateRestaurantForms.length > 0) {
        await Promise.all(deactivateRestaurantForms.map(async form => {
          const promise = await patch(FORMS_URL, form, {active: false})
          return promise
        })).then(results => {
          if (results.status !== 200) {
            console.log({error: results.data})
          }
        })
      }

      const formTemplateResp = await get(FORMTEMPLATES_URL)
      if (formTemplateResp.status === 200) {
        dispatch(updateFormTemplates(formTemplateResp.data))
      }

      const fieldOrderResp = await get(FIELDORDERS_URL)
      if (fieldOrderResp.status === 200) {
        dispatch(updateFieldOrders(fieldOrderResp.data))
      }

      const formsResp = await get(FORMS_URL)
      if (formsResp.status === 200) {
        dispatch(updateForms(formsResp.data))
      }
    } else {
      console.log({error: resp.data})
    }

    setNewFormTemplateModalActive(false)
    setNewFormName('')
    setNewFormActive(true)
    setNewFormGracePeriod('')
    setNewFormFields([])
    setNewFormSelectedRestaurants([])
    setEditForm(null)
  }

  const cronSelector = (<div>
    <HukkaSelect
      isDisabled={newField !== false ? true : false}
      options={[
        {
          label: t('Viikonpäivävalinta'),
          value: 'weekday'
        },
        {
          label: t('Päivävalinta'),
          value: 'day'
        }
      ]}
      value={cronSelectorType}
      onChange={e => {
        setCronSelectorType(e)
      }}
    />

    <br />
    <br />

    {cronSelectorType && (<>
      <h2>{t('Kuukaudet')}</h2>
      <HukkaSelect
        isDisabled={newField !== false ? true : false}
        options={[{
          value: 'all',
          label: '--- ' + t('Valitse kaikki') + ' ---'
        }].concat(cronMonthOptions)}
        value={cronSelectedMonths}
        placeholder=""
        isMulti
        onChange={e => {
          if (e && e.find(arr => arr.value === 'all')) {
            setCronSelectedMonths(cronMonthOptions)
          } else {
            setCronSelectedMonths(e)
          }
        }}
      />

      {cronSelectorType.value === 'weekday' && (<>
        <h2>{t('Viikonpäivät')}</h2>
        <HukkaSelect
          isDisabled={newField !== false ? true : false}
          options={[{
            value: 'noWeekends',
            label: '--- ' + t('Valitse arkipäivät') + ' ---'
          },{
            value: 'all',
            label: '--- ' + t('Valitse kaikki') + ' ---'
          }].concat(cronWeekDayOptions)}
          value={cronSelectedWeekDays}
          placeholder=""
          isMulti
          onChange={e => {
            if (e && e.find(arr => arr.value === 'all')) {
              setCronSelectedWeekDays(cronWeekDayOptions)
            } else if (e && e.find(arr => arr.value === 'noWeekends')) {
              setCronSelectedWeekDays(cronWeekDayNoWeekends)
            } else {
              setCronSelectedWeekDays(e)
            }
          }}
        />
      </>)}

      {cronSelectorType.value === 'day' && (<>
        <h2>{t('Päivät')}</h2>
        <HukkaSelect
          isDisabled={newField !== false ? true : false}
          options={[{
            value: 'all',
            label: '--- ' + t('Valitse kaikki') + ' ---'
          }].concat(cronDayOptions())}
          value={cronSelectedDays}
          placeholder=""
          isMulti
          onChange={e => {
            if (e && e.find(arr => arr.value === 'all')) {
              setCronSelectedDays(cronDayOptions())
            } else {
              setCronSelectedDays(e)
            }
          }}
        />
      </>)}

      <h2>{t('Klo')}</h2>
      <HukkaSelect
        isDisabled={newField !== false ? true : false}
        options={cronHourOptions()}
        value={cronSelectedHours}
        placeholder=""
        isMulti
        onChange={e => {
          setCronSelectedHours(e)
        }}
      />
    </>)}

  </div>)

  const formFieldsHtml = () => {
    let fields = []

    newFormFields.forEach(function (field, index) {
      fields.push(<div
        key={'newFormField'+newFormFieldRender+field.value+newFormFields.length+index}
        style={{margin: '5px 0'}}
      >
        <div style={{width: '200px', display: 'inline-block', margin: '0 5px'}}>
            <HukkaSelect
              options={formFieldOptions ? formFieldOptions : []}
              isDisabled={newField !== false}
              placeholder={t('Valitse kenttä')}
              value={field ? field : ''}
              onChange={e => {
                if (e.value === 'newField') {
                  setNewField(index)
                } else {
                  setNewField(false)
                }
                let fields = newFormFields
                fields[index] = e
                setNewFormFields(fields)
                setNewFormFieldRender(newFormFieldRender + 1)
              }}
              formatCreateLabel={input => t('Luo uusi kenttä')+': '+input}
              onCreateOption={label => {
                setNewFieldName(label)
                setNewField(index)
                let fields = newFormFields
                fields[index] = { value: 'newField', label: '--- ' + t('Luo uusi kenttä') + ' ---' }
                setNewFormFields(fields)
                setNewFormFieldRender(newFormFieldRender + 1)
              }}
            />
            <br />
            <div
              className="fas fa-times"
              style={{cursor: 'pointer', fontSize: '25px', display: 'block', marginTop: '-10px'}}
              onClick={() => {
                let fields = newFormFields
                fields.splice(index, 1)
                setNewFormFields(fields)
                setNewFormFieldRender(newFormFieldRender + 1)
                setNewField(false)
                setNewFieldName('')
                setNewFieldType('')
              }}
            />
          </div>
        {newField === index && (
          <div>
            <h2>{t('Kentän nimi')}</h2>
            <input
              className="input has-text-primary"
              placeholder=""
              style={{ fontSize: '16px', flex: 1, margin: '5px 0'}}
              type="text"
              value={
                newFieldName ? newFieldName : ''
              }
              onChange={e => {
                setNewFieldName(e.target.value)
              }}
            />
            <h2>{t('Kentän tyyppi')}</h2>
            <HukkaSelect
              options={fieldTypes}
              placeholder={t('Valitse kentän tyyppi')}
              value={newFieldType}
              onChange={e => {
                setNewFieldType(e)
              }}
            />
            <button
              type="button"
              disabled={newFieldName === '' || newFieldType === null || newFieldType === false}
              className={'button is-small is-primary-background'}
              style={{margin: '5px'}}
              onClick={() => {
                createNewFieldTemplate()
              }}
            >
              <Trans>Lisää kenttä</Trans>
            </button>
          </div>
        )}
      </div>)
    })

    fields.push(<button
        type="button"
        className={'button is-small'}
        disabled={newField !== false ? true : false}
        style={{margin: '20px', marginBottom: '30px'}}
        onClick={() => {
          let fields = newFormFields
          let order = fields.length
          fields.push({
            order: order,
            field: {}
          })
          setNewFormFields(fields)
          setNewFormFieldRender(newFormFieldRender + 1)
        }}
      >
        <Trans>Lisää sarake</Trans>
      </button>)

    return (<div style={{display: 'flex', justifyContent: 'center'}}>
      {fields}
    </div>)
  }

  const newFormTemplateModal = () => {
    return (
      <Modal
        fullWidth
        isActive={newFormTemplateModalActive}
        key={'modal'+newFormFieldRender}
        title={t('Uusi lomakepohja')}
        closeModalCallBack={() => {
          setNewFormTemplateModalActive(false)
          setNewFormName('')
          setNewFormActive(true)
          setNewFormGracePeriod('')
          setNewFormFields([])
          setEditForm(null)
        }}
        submitCallback={() => {
          if (editForm) {
            editFormTemplate()
          } else {
            createNewFormTemplate()
          }
        }}
        hideModalSubmit={newField !== false || !newFormGracePeriod || newFormGracePeriod < 0 || !cronSelectedMonths || !cronSelectedHours || (cronSelectorType.value === 'weekday' && !cronSelectedWeekDays) || (cronSelectorType.value === 'day' && !cronSelectedDays)}
      >
        <div>
          <div style={{maxWidth: '800px', margin: 'auto', borderBottom: '1px solid #000'}}>
            <h1
              className="title is-3 is-size-4-mobile is-uppercase"
              style={{lineHeight: '1'}}
            >
              {t('Nimi')}
            </h1>
            <input
              disabled={newField !== false ? true : false}
              className="input has-text-primary"
              type="text"
              placeholder=""
              style={{marginBottom: '30px'}}
              value={newFormName}
              onChange={e => {
                setNewFormName(e.target.value)
              }}
            />

            <div style={{margin: '0 0 20px 0'}}>
              <label>
                <input
                  disabled={newField !== false ? true : false}
                  type="checkbox"
                  checked={newFormActive}
                  style={{marginRight: '5px'}}
                  onChange={e => {
                    setNewFormActive(e.target.checked)
                  }}
                />
                {t('Aktiivinen')}
              </label>
            </div>
          </div>

          <h1
            className="title is-3 is-size-4-mobile is-uppercase"
            style={{lineHeight: '1', marginTop: '30px'}}
          >
            {t('Lomakkeen sarakkeet')}
          </h1>

          <div>
            {formFieldsHtml()}
          </div>

          <div style={{maxWidth: '800px', margin: 'auto'}}>
            <h1
              className="title is-3 is-size-4-mobile is-uppercase"
              style={{lineHeight: '1', paddingTop: '30px', marginTop: '20px', borderTop: '1px solid #000'}}
            >
              {t('Ravintolat')}
            </h1>
            {restaurantGroupSelect}
            <HukkaSelect
              isDisabled={newField !== false ? true : false}
              options={getRestaurantOptions()}
              placeholder={t('Valitse ravintolat')}
              value={newFormSelectedRestaurants}
              isMulti
              onChange={e => {
                if (e && e.find(r => r.value === 'allWaste')) {
                  setNewFormSelectedRestaurants(getRestaurantOptions().filter(ro => ro.value !== 'allWaste'))
                } else {
                  setNewFormSelectedRestaurants(e)
                }
              }}
            />

            <h1
              className="title is-3 is-size-4-mobile is-uppercase"
              style={{lineHeight: '1', paddingTop: '30px', marginTop: '40px', borderTop: '1px solid #000'}}
            >
              {t('Toistuvuus')}
            </h1>
            {cronSelector}
            <br />
            <h2
              style={{lineHeight: '1'}}
            >
              {t('Muistutusaika tunteina')}
            </h2>
            <p>{t('Määritys, kuinka monta tuntia aikaisemmin lomake on kirjattavissa.')}<br />
            {t('Huom! Älä aseta tiheämpää muistutusaikaa, kuin mitä lomakkeen toistuvuus on!')}</p>
            <input
              disabled={newField !== false ? true : false}
              type="number"
              style={{marginBottom: '30px'}}
              value={newFormGracePeriod}
              onChange={e => {
                if (e.target.value > 168) {
                  setNewFormGracePeriod(168)
                } else {
                  setNewFormGracePeriod(e.target.value)
                }
              }}
            /> h
          </div>
        </div>
      </Modal>
    )
  }

  const formTemplatesHtml = () => {
    let html = []
    let templates = []

    if (onlyViewActiveForms) {
      templates = formTemplates.filter(ft => ft.active === true)
    } else {
      templates = formTemplates
    }

    templates.slice().sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    .forEach(function (template) {
      let restaurantCount = forms.filter(f => f.template === template.id && f.active === true).length
      html.push(<tr key={template.id} className='forms__formtemplate'>
        <td>{template.name}</td>
        <td>{template.fields.length}</td>
        <td>{restaurantCount}</td>
        <td>{template.active ? t('Kyllä') : t('Ei')}</td>
        <td>
          <div
            className="fas fa-edit"
            style={{cursor: 'pointer'}}
            onClick={() => {
              setEditForm(template.id)
              setNewFormTemplateModalActive(true)
              setNewFormName(template.name)
              setNewFormActive(template.active)
              setNewFormGracePeriod(template.gracePeriod ? (template.gracePeriod / 60) : 12)
              
              let fields = []
              fieldOrders.filter(fo => fo.formTemplate === template.id).slice().sort((a, b) => {
                if (a.order > b.order) return 1
                if (a.order < b.order) return -1
                return 0
              }).forEach(function (field) {
                let option = formFieldOptions.find(ffo => ffo.value === field.fieldTemplate)
                if (option && option.value) {
                  fields.push({
                    value: field.fieldTemplate,
                    label: option.label
                  })
                }
              })
              setNewFormFields(fields)

              let currentRestaurants = forms.filter(f => f.template === template.id && f.active === true).map(f => f.restaurant)
              let restaurantSelection = []
              getRestaurantOptions().forEach(function (ro) {
                if (currentRestaurants.indexOf(ro.value) !== -1) {
                  restaurantSelection.push(ro)
                }
              })
              setNewFormSelectedRestaurants(restaurantSelection)
              
              if (template.cron && template.cron.length > 8) {
                let cronArr = template.cron.split(' ')

                let cronArrSelectedMonths = []
                if (cronArr[3] !== '*') {
                  cronArr[3].split(',').forEach(function (val) {
                    let option = cronMonthOptions.find(opt => opt.value === parseInt(val))
                    if (option) {
                      cronArrSelectedMonths.push(option)
                    }
                  })
                }
                setCronSelectedMonths(cronArrSelectedMonths)
                
                let cronArrSelectedWeekDays = []
                if (cronArr[4] !== '*') {
                  cronArr[4].split(',').forEach(function (val) {
                    let option = cronWeekDayOptions.find(opt => opt.value === parseInt(val))
                    if (option) {
                      cronArrSelectedWeekDays.push(option)
                    }
                  })
                }
                setCronSelectedWeekDays(cronArrSelectedWeekDays)
                
                let cronArrSelectedDays = []
                if (cronArr[2] !== '*') {
                  cronArr[2].split(',').forEach(function (val) {
                    let option = cronDayOptions().find(opt => opt.value === parseInt(val))
                    if (option) {
                      cronArrSelectedDays.push(option)
                    }
                  })
                  setCronSelectorType({label: t('Päivävalinta'), value: 'day'})
                }
                setCronSelectedDays(cronArrSelectedDays)
                
                let cronArrSelectedHours = []
                if (cronArr[1] !== '*') {
                  cronArr[1].split(',').forEach(function (val) {
                    let option = cronHourOptions().find(opt => opt.value === parseInt(val))
                    if (option) {
                      cronArrSelectedHours.push(option)
                    }
                  })
                }
                setCronSelectedHours(cronArrSelectedHours)
              }
            }}
          />
        </td>
      </tr>)
    })
    
    return (<div>
      <button
	      type="button"
	      className={'button is-small is-primary-background'}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setNewFormTemplateModalActive(true)
	      }}
	    >
	      <Trans>Lisää uusi lomakepohja</Trans>
	    </button>
      <br />
      <label>
        <input
          type="checkbox"
          checked={onlyViewActiveForms}
          style={{marginRight: '5px'}}
          onChange={e => {
            setOnlyViewActiveForms(e.target.checked)
          }}
        />
        {t('Näytä vain aktiiviset lomakkeet')}
      </label>
      <table className="table is-striped is-bordered" style={{margin: '10px auto'}}>
        <thead>
          <tr>
            <td><b>Nimi</b></td>
            <td><b>Kentät</b></td>
            <td><b>Ravintolat</b></td>
            <td><b>Aktiivinen</b></td>
            <td><b></b></td>
          </tr>
        </thead>
        <tbody>
          {html}
        </tbody>
      </table>
    </div>)
  }

  return (<div>
  	<h1
    className="title is-1 is-size-3-mobile is-uppercase"
    style={{margin: '0'}}
    >
        <Trans>Lomakkeet</Trans>
    </h1>

    <h2>
      <Trans>Lomakepohjat</Trans>
    </h2>

    {formTemplatesHtml()}

    {newFormTemplateModal()}

  </div>)
}

export default Forms

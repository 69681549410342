import React from 'react'
import { node, oneOfType, arrayOf } from 'prop-types'
import { useLocation } from 'react-router-dom'
import Nav from '../nav'
import SideNav from '../nav/side'

const Layout = ({ children }) => {
  const location = useLocation()
  
  return (
    <div id="app" className="is-marginless">
      { location.pathname !== '/plate-waste-view' && location.pathname !== '/magic-forest-view' && (
        <>
        <Nav />

        <div style={{ display: 'flex', height: '100vh' }}>
          <SideNav />
          <div className="container" id="hukka-content">
            <div>
              <div className="content-wrapper">
                {children}
              </div>
              <footer className="footer">
                <div className="footer-image">
                  <img src="/img/hukka_logo_slogan.png" alt="Hukka logo" />
                </div>
              </footer>
            </div>
          </div>
        </div>

        </>
      )}
      { (location.pathname === '/plate-waste-view' || location.pathname === '/magic-forest-view') && (
        <>
          {children}
        </>
      )}
    </div>
  )
}

Layout.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
}

export default Layout

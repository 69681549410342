import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { patch, ORGS_URL } from '../../utility/api'
import { setOrgs } from '../../actions'
import ErrorMessage from '../errorMessage'

const Restaurants = () => {
  const dispatch = useDispatch()
  const { orgs } = useSelector(state => state)
  const [error, setError] = useState(null)
  const { t } = useTranslation()
  const orgValues = orgs && orgs[0]
  const defaultWasteTargetPercentage = orgValues ? (orgValues.wasteTargetPercentage ? orgValues.wasteTargetPercentage : 0) : 0
  const defaultWasteAlarmPercentage = orgValues ? (orgValues.wasteAlarmPercentage ? orgValues.wasteAlarmPercentage : 0) : 0

  const [wasteTargetPercentage, setWasteTargetPercentage] = useState(defaultWasteTargetPercentage)
  const [wasteAlarmPercentage, setWasteAlarmPercentage] = useState(defaultWasteAlarmPercentage)
  const [saving, setSaving] = useState(false)

  const saveValues = async () => {
    setError(null)
    const resp = await patch(ORGS_URL, orgValues.id, {
      waste_target_percentage: parseFloat(wasteTargetPercentage),
      waste_alarm_percentage: parseFloat(wasteAlarmPercentage)
    })

    if (resp.status === 200) {
      dispatch(setOrgs(resp.data))
    } else {
      setError(resp.data)
    }
    setSaving(false)
  }

  return (
    <div className="organization">
      <h1 className="title is-1 is-size-3-mobile is-uppercase "><Trans>Organisaation asetukset</Trans></h1>

      {orgValues && (<div>
        <h2 className="title is-3 is-size-4-mobile is-uppercase"><Trans>Raportoinnin tavoiterajat</Trans></h2>
        <h3 className="title is-4 is-size-5-mobile is-uppercase">{t('Positiivinen hävikkiprosentti')}</h3>
        <p style={{margin: '-40px 0 20px 0'}}>{t('Tämän rajan alittavat hävikkiprosentit merkitään vihreällä')}</p>
        <input
          type="range"
          name="range-a"
          defaultValue={defaultWasteTargetPercentage}
          className="range range--target"
          min="0"
          max="100"
          step="1"
          disabled={saving}
          onChange={e => {
            setWasteTargetPercentage(e.target.value)
          }}
        />
        <br />
        {wasteTargetPercentage}%
        <br />
        <br />
        <h3 className="title is-4 is-size-5-mobile is-uppercase">{t('Negatiivinen hävikkiprosentti')}</h3>
        <p style={{margin: '-40px 0 20px 0'}}>{t('Tämän rajan ylittävät hävikkiprosentit merkitään punaisella')}</p>
        <input
          type="range"
          name="range-b"
          defaultValue={defaultWasteAlarmPercentage}
          className="range range--alarm"
          min="0"
          max="100"
          step="1"
          disabled={saving}
          onChange={e => {
            setWasteAlarmPercentage(e.target.value)
          }}
        />
        <br />
        {wasteAlarmPercentage}%
        <br />
        <br />
        {error && (
          <div style={{margin: '10px 0'}}>
            <ErrorMessage msg={error} />
          </div>
        )}
        <button
          type="button"
          className={'button is-small'}
          style={{margin: '5px'}}
          disabled={saving}
          onClick={() => {
            setSaving(true)
            saveValues()
          }}
        >
          <Trans>Tallenna</Trans>
        </button>

      </div>)}
    </div>
  )
}

export default Restaurants

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next';
import { renewMealServings, renewMealServingLogs } from '../../actions'
import { MEALSERVINGS_URL, MEALSERVINGLOGS_URL, get } from '../../utility/api'
import HukkaSelect from '../hukkaSelect'
import NewMealservingForm from './newMealservingForm'
import { updateAddWasteForm } from '../../actions'

const ChooseServingForm = () => {
  const { restaurants, mealServings, mealTypes, addWasteForm } = useSelector(
    state => state
  )
  const { selectedMealServing, selectedRestaurant } = addWasteForm || {}
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [date, setDate] = useState(moment())
  const [datePickerFocused, setDatePickerFocused] = useState(false)

  const resetSelectedMealServing = () => {
    dispatch(updateAddWasteForm('selectedMealServing', null))
  }

  const getMealServings = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServings(resp.data))
    }
  }
  const getMealServingLogs = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGLOGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServingLogs(resp.data))
    }
  }

  const restaurantOptions =
    restaurants &&
    restaurants.slice()
    .sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    .map(r => ({
      value: r.id,
      label: r.name,
    }))

  const selectedMealservingInfos =
    selectedMealServing &&
    mealServings &&
    mealServings.find(ms => ms.id === selectedMealServing)
  
  const selectedRestaurantAttr =
    selectedRestaurant &&
    restaurants.filter(r => r.id === selectedRestaurant)[0]

  // Filter mealservings for selected restaurant for selected day
  const selectedRestaurantMealservingOptions = mealServings
    .filter(ms => ms.restaurant === selectedRestaurant)
    .filter(ms => moment(ms.servingDatetime).isSame(date, 'day'))
    .map(ms => ({
      value: ms.id,
      label: moment(ms.servingDatetime).format('DD.MM.YYYY HH:mm')+' '+t((mealTypes.find(mt => mt.id === ms.mealType).name))
    }))
    .concat([
      {
        value: 'new',
        label: (t('Uusi kattaus') + ': ' + moment(date).format('DD.MM.YYYY 12:00')),
      },
    ])

  useEffect(() => {
    if (restaurants.length === 1 && (selectedRestaurant === null || typeof selectedRestaurant === 'undefined')) {
      dispatch(updateAddWasteForm('selectedRestaurant', restaurants[0].id))
      getMealServings(date.format('YYYY-MM-DD'), date.clone().add(1, 'days').format('YYYY-MM-DD'))
      getMealServingLogs(date.format('YYYY-MM-DD'), date.clone().add(1, 'days').format('YYYY-MM-DD'))
    }
    if (selectedRestaurantMealservingOptions.length === 2 && (selectedMealServing === null || typeof selectedMealServing === 'undefined')) {
      dispatch(updateAddWasteForm('selectedMealServing', selectedRestaurantMealservingOptions[0].value))
    }
  })

  return (
    <div className="choose-serving-form">
      <div className="control">
        <div className="select is-large is-fullwidth hukkaselect">
          <HukkaSelect
            options={restaurantOptions}
            value={restaurantOptions.find(
              ro => ro.value === selectedRestaurant
            )}
            placeholder={t('Valitse ravintola')}
            onChange={e => {
              dispatch(updateAddWasteForm('selectedRestaurant', e.value))
              dispatch(updateAddWasteForm('selectedFood', ''))
              dispatch(updateAddWasteForm('selectedWasteType', ''))
              dispatch(updateAddWasteForm('selectedCourse', ''))
              getMealServings(date.format('YYYY-MM-DD'), date.clone().add(1, 'days').format('YYYY-MM-DD'))
              getMealServingLogs(date.format('YYYY-MM-DD'), date.clone().add(1, 'days').format('YYYY-MM-DD'))
              resetSelectedMealServing()
            }}
          />
        </div>
      </div>

      <div style={{ marginBottom: '15px' }}>
        <SingleDatePicker
          date={date}
          onDateChange={newDate => {
            setDate(newDate)
            dispatch(updateAddWasteForm('selectedFood', ''))
            dispatch(updateAddWasteForm('selectedWasteType', ''))
            dispatch(updateAddWasteForm('selectedCourse', ''))
            getMealServings(newDate.format('YYYY-MM-DD'), newDate.clone().add(1, 'days').format('YYYY-MM-DD'))
            getMealServingLogs(newDate.format('YYYY-MM-DD'), newDate.clone().add(1, 'days').format('YYYY-MM-DD'))
            resetSelectedMealServing()
          }}
          focused={datePickerFocused}
          onFocusChange={({ focused }) => setDatePickerFocused(focused)}
          id="addWasteDatePicker"
          numberOfMonths={1}
          displayFormat="DD.MM.YYYY"
          withPortal
          block
          isOutsideRange={() => false}
          disabled={!selectedRestaurant}
        />
      </div>

      <div className="field">
        <div className="control">
          <div className="select is-large is-fullwidth hukkaselect">
            <HukkaSelect
              options={selectedRestaurantMealservingOptions}
              placeholder={t('Valitse tai luo uusi kattaus')}
              value={selectedRestaurantMealservingOptions.find(
                e => e.value === selectedMealServing
              )}
              onChange={e => {
                dispatch(updateAddWasteForm('selectedMealServing', e.value))
                dispatch(updateAddWasteForm('selectedFood', ''))
                dispatch(updateAddWasteForm('selectedWasteType', ''))
                dispatch(updateAddWasteForm('selectedCourse', ''))
              }}
              isDisabled={!selectedRestaurant || !date}
            />
          </div>
        </div>
      </div>

      {selectedMealservingInfos && (
        <div>
          <h2 className="title is-5">
            {moment(selectedMealservingInfos.servingDatetime).format('LLL')}
          </h2>
        </div>
      )}

      {selectedMealServing === 'new' && (
        <NewMealservingForm
          mealTypes={mealTypes}
          date={date}
          restaurant={selectedRestaurant}
          disabledMealTypes={selectedRestaurantAttr && selectedRestaurantAttr.mealType}
        />
      )}
    </div>
  )
}

export default ChooseServingForm

import React, { useState } from 'react'
import { shape, arrayOf, string, instanceOf } from 'prop-types'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import flatten from 'lodash/flatten'
import { useTranslation, Trans } from 'react-i18next';
import ServingBox from './servingBox'
import Modal from '../modal'
import NewMealservingForm from '../addWaste/newMealservingForm'

const DATE_FORMAT = 'DD.MM.'

const DateRow = ({
  date,
  mealServings,
  mealTypes,
  selectedRestaurants,
  mealServingLogs,
  restaurants,
  monday
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()
  const dateStr = date.format(DATE_FORMAT)
  const dayName = date.format('ddd')
  const weekDay = date.weekday() === 5 || date.weekday() === 6
  const mealServingsAmount = mealServings.length

  /**
   * Customer wants us to group mealservings by content to boxes
   * This is problematic since we can't compare any id's since they are always different
   * I ended up generating a str key for servings with info about serving and all logs related
   * Then we can compare these strings and see if they are similar
   */

  const disabledMealTypesOfSelected = () => {
    let mealTypes = []
    if (selectedRestaurants) {
      selectedRestaurants.forEach(element => {
        let rest = restaurants.find(r => r.id === element.value)
        if (rest && rest.hasOwnProperty('mealType')) {
          rest.mealType.forEach(mealTypeEl => {
            if (mealTypes.indexOf(mealTypeEl)) {
              mealTypes.push(mealTypeEl)
            }
          })
        }
      })
    }
    return mealTypes
  }

  const openDayContent = () => {
    const mealServingsWithKeys = mealServings.map(ms => {
      const mealServingLogsForServing = mealServingLogs.filter(
        msl => msl.mealServing === ms.id
      )
      const mealTypeId = mealTypes.find(mt => mt.id === ms.mealType).id
      const mealTypeName = mealTypes.find(mt => mt.id === ms.mealType).name

      const mealServingKey = `${
        ms.servingDatetime
      }${mealTypeName}${ms.servingNotes || 'N/A'}`
      const mealServingLogsKey = mealServingLogsForServing
        .map(msl => `${msl.food}${msl.course || 'N/A'}`)
        .join(',')
      const key = mealServingKey.concat(mealServingLogsKey)

      return { mealServing: ms, mealServingLogsForServing, mealTypeId, mealTypeName, key }
    })

    const groupedMealServings = groupBy(mealServingsWithKeys, 'key')

    return Object.entries(groupedMealServings).map(([key, servingsData]) => {
      // Take first mealServing to represent all of them since they have same data
      const ms = servingsData[0]
      const restaurantNames = servingsData
        .map(s => s.mealServing.restaurantName)
        .join(', ')
      const { mealTypeId, mealTypeName, mealServingLogsForServing } = ms
      const groupMealServingIds = servingsData.map(s => s.mealServing.id)
      const groupMealServingLogs = flatten(
        servingsData.map(sd => sd.mealServingLogsForServing)
      )

      return (
        <div style={{ margin: '35px' }} key={key}>
          <div
            className="is-size-3 has-text-primary"
            style={{ marginBottom: '25px' }}
          >
            {restaurantNames}
          </div>

          <ServingBox
            key={key}
            mealServing={ms}
            mealTypeId={mealTypeId}
            mealTypeName={mealTypeName}
            mealServingLogs={mealServingLogsForServing}
            groupMealServingIds={groupMealServingIds}
            groupMealServingLogs={groupMealServingLogs}
            monday={monday}
            selectedRestaurants={selectedRestaurants}
          />
        </div>
      )
    })
  }

  const handleOpen = () => {
    setIsOpen(!isOpen)
  }

  const newMealServingFormModal = (
    <Modal
      isActive={modalOpen}
      hideModalSubmit
      title={t('Luo uusi kattaus')}
      closeModalCallBack={() => setModalOpen(false)}
    >
      <div className="is-size-3" style={{ marginBottom: '15px' }}>
        <Trans>Ravintolat</Trans>: {selectedRestaurants.map(r => r.label).join(', ')}
      </div>

      <NewMealservingForm
        isInModal
        restaurant={selectedRestaurants.map(r => r.value)}
        date={date}
        submitCallback={() => setModalOpen(false)}
        disabledMealTypes={disabledMealTypesOfSelected()}
        hideCustomerInput
        hideNotesInput
        fromDate={monday.format('YYYY-MM-DD')}
        toDate={monday.clone().add(7, 'days').format('YYYY-MM-DD')}
      />
    </Modal>
  )

  return (
    <div
      style={{
        display: 'flex',
        background: weekDay ? '#fabfab' : '#fbfbfb',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      className="date-row-container"
    >
      <div
        className="column is-full"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: isOpen ? '1px solid' : '',
        }}
        onKeyPress={handleOpen}
        onClick={handleOpen}
        role="button"
        tabIndex="0"
      >
        <div className="is-size-4">
          {dayName} {dateStr}
        </div>

        <div className="is-size-4">
          {mealServingsAmount}{' '}
          {mealServingsAmount === 1 ? t('kattaus') : t('kattausta')}
        </div>
      </div>

      {isOpen && (
        <div
          className="serving-box-container"
          style={{ width: '100%', padding: '10px' }}
        >
          {openDayContent()}

          <button
            type="button"
            className="button is-primary"
            onClick={() => setModalOpen(true)}
          >
            <Trans>Lisää kattaus</Trans>
          </button>
          {newMealServingFormModal}
        </div>
      )}
    </div>
  )
}

DateRow.propTypes = {
  date: instanceOf(moment).isRequired,
  mealServings: arrayOf(shape({ id: string })).isRequired,
  mealTypes: arrayOf(shape({ id: string, name: string })).isRequired,
  selectedRestaurants: arrayOf(shape({ value: string, label: string }))
    .isRequired,
  mealServingLogs: arrayOf(shape({ id: string })).isRequired,
}

export default DateRow

import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid
} from 'recharts'
import moment from 'moment'
import { MEALSERVINGS_URL, MEALSERVINGLOGS_URL, AVGWASTE_URL, get } from '../../utility/api'
import { renewMealServings, renewMealServingLogs, tokenLogin, updateAvgWaste } from '../../actions'

import { lastFiveWeekDays } from '../../utility/helpers'

const DATE_FORMAT = 'DD.MM.'

const PlateWaste = ({
  selectedMealTypes
}) => {
  const dispatch = useDispatch()
  const {
    mealServings,
    mealServingLogs,
    wasteTypes,
    mealTypes,
    restaurants,
    hukkaProfiles,
    courses,
    avgWaste
  } = useSelector(state => state)
  const [restaurantId, setRestaurantId] = useState(null)
  const [chartData, setChartData] = useState(null)
  const [fullscreenActive, setFullscreenActive] = useState(false)
  const { t } = useTranslation()
  const [chartReady, setChartReady] = useState(false)
  const chartRef = useRef(null)
  const { loggedIn, rou:readOnlyUser } = useSelector(state => state.user) || { loggedIn: false, readOnlyUser: false }
  const [init, setInit] = useState(false)
  const [autoStart, setAutoStart] = useState(false)
  const [fetchedData, setFetchedData] = useState([])

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const getMealServings = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServings(resp.data))
      let fetchedDataArr = fetchedData
      fetchedDataArr.push('mealServings')
      setFetchedData(fetchedDataArr)
    }
  }
  const getMealServingLogs = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGLOGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServingLogs(resp.data))
      let fetchedDataArr = fetchedData
      fetchedDataArr.push('mealServingLogs')
      setFetchedData(fetchedDataArr)
    }
  }
  const getAvgWaste = async (restaurant, from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(AVGWASTE_URL + restaurant + '/', payload)

    if (resp.status === 200) {
      dispatch(updateAvgWaste(resp.data))
      let fetchedDataArr = fetchedData
      fetchedDataArr.push('avgWaste')
      setFetchedData(fetchedDataArr)
    }
  }

  if (urlParams.get('token') !== null) {
    dispatch(tokenLogin(urlParams.get('token')))
    urlParams.delete('token')
    window.location.href = `${window.location.pathname}?${urlParams}${window.location.hash}`
  } else if (loggedIn && readOnlyUser && !init && restaurants.length > 0 ) {
    const startingDate = moment().add('days', -7)
    getMealServings(startingDate.format('YYYY-MM-DD'), startingDate.clone().add(14, 'days').format('YYYY-MM-DD'))
    getMealServingLogs(startingDate.format('YYYY-MM-DD'), startingDate.clone().add(14, 'days').format('YYYY-MM-DD'))
    getAvgWaste(restaurants[0].id, moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'))
    setInit(true)
  }

  /* Readonly -users */
  const lastFiveDaysMenu = () => {
    let html = []
    if (restaurantId) {
      let currentWeekDates = []
      let curr = moment()
      let first = curr.clone().subtract(7, 'days')
      let last = curr.clone().subtract(1, 'days')
      let currentDay = first
      let lastDay = last
      while (currentDay <= lastDay) {
        if (new Date(currentDay).getDay() !== 0 && new Date(currentDay).getDay() !== 6) {
          currentWeekDates.push(moment(currentDay).format())
        }
        currentDay = moment(currentDay).add(1, 'days')
      }

      let selectedMealTypeIds = (selectedMealTypes && Array.isArray(selectedMealTypes)) ? selectedMealTypes.map(smt => smt.value) : []
      let currentRestaurantMS = mealServings.filter(ms => ms.restaurant === restaurantId && selectedMealTypeIds.indexOf(ms.mealType) !== -1)
      let lineWasteId = wasteTypes.find(wt => wt.name === 'line').id
      let courseIds = courses.map(c => c.id)

      currentWeekDates.forEach((cwd, index) => {
        let foodsHtml = []
        let servings = currentRestaurantMS.filter(ms => moment(new Date(ms.servingDatetime)).format('YYYY-MM-DD') === moment(new Date(cwd)).format('YYYY-MM-DD')).map(ms => ms.id)
        if (servings.length > 0) {
          servings.forEach((s, sIndex) => {
            let logs = mealServingLogs.filter(msl => msl.mealServing === s && msl.food && msl.wasteType === lineWasteId)
            .sort((a, b) => {
              if (courseIds.indexOf(a.course) > courseIds.indexOf(b.course)) return 1
              if (courseIds.indexOf(a.course) < courseIds.indexOf(b.course)) return -1
              return 0
            })
            if (logs.length > 0) {
              logs.forEach((log, logIndex) => {
                foodsHtml.push(<div key={'food-'+sIndex+'-'+logIndex}>{log.foodName}</div>)
              })
            }
          })
        }
        html.push(<div key={'menuDay-'+index} className="lastFiveMenu__day">
          {foodsHtml}
        </div>)
      })
    }
    return (
      <div className="lastFiveMenu">
        {html}
      </div>
    )
  }


  const handleDataFetch = () => {
    const plateWasteTypeId = wasteTypes.find(wt => wt.name === 'plate').id

    const filteredMealServingIds = mealServings
      .filter(mealServing => mealServing.restaurant === restaurantId)
      .map(ms => ms.id)

      
    const filteredMealServingLogs = mealServingLogs.filter(mealServingLog =>
      filteredMealServingIds.includes(mealServingLog.mealServing)
      )
        
    const plateWasteMealServingLogs = filteredMealServingLogs.filter(
      msl => msl.wasteType === plateWasteTypeId
    )

    const plateWasteDataGroupedByDate = plateWasteMealServingLogs.reduce(
      (grpObj, acc) => {
        let currentMs = mealServings.find(ms => ms.id === acc.mealServing)
        const date = moment(
          currentMs.servingDatetime
        ).format(DATE_FORMAT)

        const weekDays = lastFiveWeekDays()
        const includedInLastFiveWeekDays = weekDays.includes(date)

        if (!includedInLastFiveWeekDays) {
          return grpObj
        }
        
        const wasteGrams = acc.wasteGrams / currentMs.customers

        if (grpObj[date]) {
          return { ...grpObj, [date]: [...grpObj[date], wasteGrams] }
        }

        return { ...grpObj, [date]: [wasteGrams] }
      },
      {}
    )

    const blankLastFiveWeekdaysObj = lastFiveWeekDays().reduce(
      (o, date) => ({ ...o, [date]: [0] }),
      {}
    )

    const plateWasteLastFiveWeekdays = {
      ...blankLastFiveWeekdaysObj,
      ...plateWasteDataGroupedByDate,
    }

    const plateWasteInRechartsFormat = Object.entries(
      plateWasteLastFiveWeekdays
    ).map(([date, wasteValueList]) => ({
      date,
      waste: wasteValueList.reduce((sum, acc) => sum + acc, 0),
      average: avgWaste && avgWaste.plateAvg > 0 ? (avgWaste.plateAvg / avgWaste.avgCustomers) : 35
    }))

    const sortedPlateWasteData = plateWasteInRechartsFormat.sort((a, b) =>
      moment(a.date, DATE_FORMAT).isBefore(moment(b.date, DATE_FORMAT))
    )

    sortedPlateWasteData.unshift({
      average: avgWaste && avgWaste.plateAvg > 0 ? (avgWaste.plateAvg / avgWaste.avgCustomers) : 35
    })

    sortedPlateWasteData.push({
      average: avgWaste && avgWaste.plateAvg > 0 ? (avgWaste.plateAvg / avgWaste.avgCustomers) : 35
    })
    setChartData(
      sortedPlateWasteData.sort((a, b) =>
        moment(a.date, DATE_FORMAT).isBefore(moment(b.date, DATE_FORMAT))
          ? -1
          : 1
      )
    )
  }

  if (autoStart === true && chartReady === true && fullscreenActive === false) {
   setFullscreenActive(true)
  }

  const mealTypeOptions = mealTypes && mealTypes.slice()
    .sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    .map(r => ({
      value: r.id,
      label: r.name,
    }))
    .concat([
      {
        value: 'all',
        label: t('Valitse kaikki ateriat'),
      },
    ])

  useEffect(() => {
    if (autoStart === false && mealTypeOptions.length > 1) {
      setAutoStart(true)
    }
  }, [autoStart, mealTypeOptions])

  const plateWasteView = () => (
    <div
      style={{
        display: 'block',
        alignItems: 'center',
        height: '100vh',
        width: '100vw'
      }}
      ref={chartRef}
    >
      <div style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}>
        <div id="plateWasteFullscreen" className="carousel" style={{background: '#fff', padding: '1vh 1vh', overflow: 'hidden'}}>
          <img id="fullscreen-hukka-logo" src="/img/hukka_logo_slogan.png" alt="Hukka logo" />
          <div className="ribbonTitle" style={{left: '-50px', width: 'calc(50% + 50px)', maxWidth: '600px'}}>
            <Trans>Lautashävikki</Trans>
          </div>
          { (avgWaste && avgWaste.plateAvg > 0) && (
            <span className="lastMonthAvg">{t('Edellisen kuukauden keskiarvo')}: <b>{(avgWaste.plateAvg / avgWaste.avgCustomers).toFixed(0)} g</b></span>
          )}
          { (!avgWaste || avgWaste.plateAvg <= 0) && (
            <span className="lastMonthAvg">{t('Yleinen vertailuarvo')}: <b>35g</b></span>
          )}
          <ResponsiveContainer width="99%" height="80%">
            <ComposedChart
              width={500}
              height={500}
              data={chartData}
              margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tick={{fontSize: '1vw'}} />
              <YAxis tickFormatter={label => `${label} g`} tick={{fontSize: '1vw'}} />
              <Line dataKey="average" stroke="#000000" dot={false} />
              <Bar dataKey="waste">
                {
                  chartData && chartData.map((entry, index) => (
                    <Cell fill={entry.waste > (avgWaste && (avgWaste.plateAvg > 0 ? (avgWaste.plateAvg / avgWaste.avgCustomers) : 35)) ? '#000c34' : '#2bdbac' } key={('cell-'+index) } />
                  ))
                }
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
          {lastFiveDaysMenu()}
        </div>
      </div>
    </div>
  )

  if (readOnlyUser) {
    if (restaurantId === null && hukkaProfiles && hukkaProfiles.length > 0) {
      setRestaurantId(hukkaProfiles[0].restaurants[0])
    } else if (restaurantId !== null && chartReady === false && fetchedData.indexOf('mealServings') !== -1 && fetchedData.indexOf('mealServingLogs') !== -1 && fetchedData.indexOf('avgWaste') !== -1) {
      handleDataFetch()
      setChartReady(true)
    }
  }

  /* General */

  return plateWasteView()
}

export default PlateWaste

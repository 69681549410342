import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import HukkaSelect from '../hukkaSelect'
import { patch, HUKKAPROFILES_URL, post, del } from '../../utility/api'
import { updateHukkaProfiles, removeFromHukkaProfiles } from '../../actions'
import ErrorMessage from '../errorMessage'

const UserProfiles = () => {
  const dispatch = useDispatch()
  const { hukkaProfiles, orgs, restaurants } = useSelector(state => state)
  const [search, setSearch] = useState('')
  const [profileToEdit, setProfileToEdit] = useState(null)
  const [profileToAdd, setProfileToAdd] = useState(null)
  const [profileToDelete, setProfileToDelete] = useState(null)
  const [errors, setErrors] = useState({})
  const { t } = useTranslation()

  const setEditValue = target => e => {
    setProfileToEdit({ ...profileToEdit, [target]: e.target.value })
  }

  const setAddValue = target => e => {
    setProfileToAdd({ ...profileToAdd, [target]: e.target.value })
  }

  const handleProfileDelete = async () => {
    const resp = await del(HUKKAPROFILES_URL, profileToDelete.id)

    if (resp.status === 204) {
      dispatch(removeFromHukkaProfiles(profileToDelete.id))
      setProfileToDelete(null)
    } else {
      setErrors(resp.data)
    }
  }

  const handleProfileEdit = async () => {
    const {
      lastName,
      firstName,
      orgAdmin,
      restaurants: profileRestaurants,
      id,
    } = profileToEdit

    const body = {
      ...profileToEdit,
      last_name: lastName,
      first_name: firstName,
      org_admin: !!parseInt(orgAdmin, 10),
      restaurants:
        (profileRestaurants && profileRestaurants.map(r => r.value)) || [],
    }

    const resp = await patch(HUKKAPROFILES_URL, id, body)

    if (resp.status === 201) {
      dispatch(updateHukkaProfiles(resp.data))
      setProfileToEdit(null)
    } else {
      setErrors({ edit: resp.data })
    }
  }

  const handleProfileAdd = async () => {
    const {
      orgAdmin,
      restaurants: profileRestaurants,
      email,
      password,
      org,
    } = profileToAdd

    const body = {
      org_admin: !!parseInt(orgAdmin, 10),
      restaurants:
        (profileRestaurants && profileRestaurants.map(r => r.value)) || [],
      email,
      username: email,
      org,
      password,
    }

    const resp = await post(HUKKAPROFILES_URL, { ...body })

    if (resp.status === 201) {
      dispatch(updateHukkaProfiles(resp.data))
      setProfileToAdd(null)
    } else {
      setErrors({ add: resp.data.detail })
    }
  }

  const userRows =
    hukkaProfiles &&
    [...hukkaProfiles]
      .filter(hp => hp.rou === 0 && (search === '' || hp.username.toLowerCase().includes(search.toLowerCase())))
      .sort((a, b) => {
        if (a.username.toLowerCase() > b.username.toLowerCase()) return 1
        if (a.username.toLowerCase() < b.username.toLowerCase()) return -1
        return 0
      })
      .map(hp => (
        <div key={hp.id} className="level levelRow">
          <div className="level-left">
            <div className="is-fullwidth has-text-left-desktop has-text-dark">
              <h4 className="subtitle is-4 is-marginless has-text-primary levelRowTitle">
                <i
                  className={
                    hp.orgAdmin ? 'fas fa-crown' : 'fas fa-user-circle'
                  }
                  style={{ marginRight: '5px' }}
                />
                {`${hp.username}`}
                <small style={{ marginLeft: '15px' }}>
                  {orgs && orgs.find(o => o.id === hp.org).name}
                </small>
              </h4>
            </div>
          </div>

          <div className="level-right">
            <div>
              <button
                className="button is-green is-large"
                type="button"
                onClick={() =>
                  setProfileToEdit({
                    ...hp,
                    orgAdmin: hp.orgAdmin ? '1' : '0',
                    restaurants: hp.restaurants.map(rId => {
                      const restaurant = restaurants.find(r => r.id === rId)
                      return { label: restaurant.name, value: restaurant.id }
                    }),
                  })
                }
              >
                <Trans>Muokkaa</Trans>
              </button>
              <button
                className="button is-danger is-large"
                type="button"
                onClick={() => setProfileToDelete(hp)}
              >
                <Trans>Poista</Trans>
              </button>
            </div>
          </div>
        </div>
      ))

  const editProfileModal = profileToEdit && (
    <div key={profileToEdit} className={`modal ${profileToEdit ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card" style={{ minHeight: '95%' }}>
        <header className="modal-card-head">
          <p className="modal-card-title"><Trans>Muokkaa profiilia</Trans></p>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <div className="label"><Trans>Sähköposti</Trans></div>
            <div className="control">
              <input
                placeholder={t('Sähköposti')}
                disabled
                className="input is-large is-fullwidth"
                type="text"
                onChange={setEditValue('email')}
                value={profileToEdit.email}
              />
            </div>
          </div>

          <div className="field">
            <div className="label"><Trans>Käyttäjätyyppi</Trans></div>
            <div className="select is-large is-fullwidth">
              <select
                onChange={setEditValue('orgAdmin')}
                value={profileToEdit.orgAdmin}
              >
                <option value="0">{t('Normaali')}</option>
                <option value="1">{t('Organisaation ylläpitäjä')}</option>
              </select>
            </div>
          </div>

          <div className="field">
            <div className="label"><Trans>Organisaatio</Trans></div>
            <div className="control">
              <div className="select is-large is-fullwidth">
                <select
                  onChange={setEditValue('org')}
                  value={profileToEdit.org}
                >
                  <option value="">{t('Valitse')}</option>
                  {orgs &&
                    orgs.map(o => (
                      <option key={o.id} value={o.id}>
                        {o.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          <div className="field">
            <div className="label"><Trans>Ravintola(t) joihin käyttöoikeus</Trans></div>
            <div className="control">
              <div className="select is-multiple is-large is-fullwidth">
                <HukkaSelect
                  isMulti
                  onChange={newRestaurants =>
                    setProfileToEdit({
                      ...profileToEdit,
                      restaurants: newRestaurants,
                    })
                  }
                  placeholder={t('Valitse')}
                  value={profileToEdit.restaurants ? profileToEdit.restaurants : []}
                  options={
                    (restaurants &&
                      restaurants.slice().sort((a, b) => {
                      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
                      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
                      return 0
                    }).map(r => ({ label: r.name, value: r.id }))) ||
                    []
                  }
                />
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <ErrorMessage msg={errors.edit} />

          <button
            type="button"
            className="button is-success is-large"
            onClick={handleProfileEdit}
            disabled={profileToEdit.org === '' || (profileToEdit.orgAdmin === '0' && (profileToEdit.restaurants && profileToEdit.restaurants.length === 0))}
          >
            <Trans>Tallenna</Trans>
          </button>
          <button
            type="button"
            className="button is-large"
            onClick={() => setProfileToEdit(null)}
          >
            <Trans>Peruuta</Trans>
          </button>
        </footer>
      </div>
    </div>
  )

  const deleteProfileModal = profileToDelete && (
    <div className={`modal ${profileToDelete ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title"><Trans>Vahvista poisto</Trans></p>
        </header>
        <section className="modal-card-body">
          { t('Haluatko varmasti poistaa profiilin') + ': ' + profileToDelete.email + '?'}
        </section>

        <ErrorMessage msg={errors.del} />

        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-success is-danger is-large"
            onClick={handleProfileDelete}
          >
            <Trans>Kyllä</Trans>
          </button>
          <button
            type="button"
            className="button is-large"
            onClick={() => setProfileToDelete(null)}
          >
            <Trans>Ei</Trans>
          </button>
        </footer>
      </div>
    </div>
  )

  const addNewProfileModal = profileToAdd && (
    <div key={profileToAdd} className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card" style={{ minHeight: '95%' }}>
        <header className="modal-card-head">
          <p className="modal-card-title"><Trans>Lisää profiili</Trans></p>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <div className="label"><Trans>Sähköposti</Trans></div>
            <div className="control">
              <input
                placeholder={t('Sähköposti')}
                className="input is-large is-fullwidth"
                type="text"
                onChange={setAddValue('email')}
              />
            </div>
          </div>

          <div className="field">
            <div className="label"><Trans>Salasana</Trans></div>
            <div className="control">
              <input
                className="input is-large is-fullwidth"
                type="password"
                onChange={setAddValue('password')}
              />
            </div>
          </div>

          <div className="field">
            <div className="label"><Trans>Käyttäjätyyppi</Trans></div>
            <div className="select is-large is-fullwidth">
              <select onChange={setAddValue('orgAdmin')}>
                <option value="0">{t('Normaali')}</option>
                <option value="1">{t('Organisaation ylläpitäjä')}</option>
              </select>
            </div>
          </div>

          <div className="field">
            <div className="label"><Trans>Organisaatio</Trans></div>
            <div className="control">
              <div className="select is-large is-fullwidth">
                <select onChange={setAddValue('org')}>
                  <option value="">{t('Valitse')}</option>
                  {orgs &&
                    orgs.map(o => (
                      <option key={o.id} value={o.id}>
                        {o.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          {profileToAdd.org && (
            <div className="field">
              <div className="label"><Trans>Ravintola(t) joihin käyttöoikeus</Trans></div>
              <div className="control">
                <div className="select is-multiple is-large is-fullwidth">
                  <HukkaSelect
                    isMulti
                    onChange={newRestaurants =>
                      setProfileToAdd({
                        ...profileToAdd,
                        restaurants: newRestaurants,
                      })
                    }
                    placeholder={t('Valitse')}
                    value={profileToAdd.restaurants}
                    options={
                      (restaurants &&
                        profileToAdd.org &&
                        restaurants
                          .filter(r => r.org === profileToAdd.org)
                          .slice().sort((a, b) => {
                            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
                            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
                            return 0
                          }).map(r => ({ label: r.name, value: r.id }))) || []
                    }
                  />
                </div>
              </div>
            </div>
          )}

        </section>

        <ErrorMessage msg={errors.add} />

        <footer className="modal-card-foot">
          { profileToAdd && (
            <button
              type="button"
              className="button is-success is-large"
              onClick={handleProfileAdd}
              disabled={profileToAdd.email === '' || profileToAdd.password === '' || profileToAdd.org === '' || (profileToAdd.orgAdmin === '0' && profileToAdd.restaurants.length === 0)}
            >
              <Trans>Lisää</Trans>
            </button>
          )}
          <button
            type="button"
            className="button is-large"
            onClick={() => setProfileToAdd(null)}
          >
            <Trans>Peruuta</Trans>
          </button>
        </footer>
      </div>
    </div>
  )

  return (
    <div className=" profiles">
      <h1 className="title is-1 is-size-3-mobile is-uppercase "><Trans>Käyttäjäprofiilit</Trans></h1>
      <button
        className="button is-large is-fullwidth is-primary"
        type="button"
        onClick={() => setProfileToAdd({
          email: '',
          password: '',
          org: '',
          restaurants: [],
          orgAdmin: '0'
        })}
      >
        <Trans>Lisää profiili</Trans>
      </button>
      <div style={{ marginTop: '60px' }}></div>
      <input
        className="input has-text-primary"
        type="text"
        placeholder={t('Hae käyttäjää') + '...'}
        value={search}
        onChange={e => {
          setSearch(e.target.value)
        }}
      />
      <div style={{ marginTop: '20px' }}>
      {userRows}
      </div>
      {editProfileModal}
      {addNewProfileModal}
      {deleteProfileModal}
    </div>
  )
}

export default UserProfiles

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PulseLoader from 'react-spinners/PulseLoader'
import { login } from '../../utility/api'
import { loginRequestSuccess } from '../../actions'
import { useTranslation, Trans } from 'react-i18next'

const Login = () => {
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [fetching, setFetching] = useState(false)
  const { t } = useTranslation()
  const [errors, setErrors] = useState({
    password: false,
    username: false,
    nonFieldErrors: false,
  })

  // Fetch token first and then fetch user info
  // set token to axios defaults
  const handleLoginSubmit = async e => {
    e.preventDefault()

    setFetching(true)

    const resp = await login(username, password)
    if (resp.status === 200) {
      dispatch(loginRequestSuccess(resp))
    } else {
      setErrors({ ...resp.data })
      setFetching(false)
    }
  }

  return (
    <form onSubmit={handleLoginSubmit}>
      <h1 className="title is-1 is-size-3-mobile is-uppercase ">
        <Trans>Kirjaudu sisään</Trans>
      </h1>

      <div className="field">
        <div className="control">
          <input
            className="input is-large is-input-middle"
            autoComplete="username"
            type="text"
            placeholder={t('Käyttäjätunnus')}
            onChange={e => setUsername(e.target.value)}
          />
        </div>
        {errors.username && <p className="help is-danger">{errors.username}</p>}
      </div>
      <div className="field">
        <div className="control">
          <input
            onChange={e => setPassword(e.target.value)}
            autoComplete="password"
            className="input is-large is-input-middle"
            type="password"
            placeholder={t('Salasana')}
          />
        </div>
        {errors.password && <p className="help is-danger">{errors.password}</p>}
      </div>
      {errors.nonFieldErrors && (
        <div className="notification is-danger is-fullwidth">
          {errors.nonFieldErrors}
        </div>
      )}
      <div className="field">
        <div className="control">
          <button
            type="submit"
            className="button is-large is-fullwidth is-green"
          >
            {!fetching && t('Kirjaudu')}
            <PulseLoader
              sizeUnit="rem"
              size={1.5}
              color="#ff5722"
              loading={fetching}
            />
          </button>
        </div>
      </div>
    </form>
  )
}

export default Login

import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { patch, HUKKAPROFILES_URL } from '../../utility/api'
import {
	updateHukkaProfiles
} from '../../actions'
import HukkaSelect from '../hukkaSelect'
import Modal from '../modal'
import ErrorMessage from '../errorMessage'

const Groups = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
	const [selectedRestaurants, setSelectedRestaurants] = useState([])
	const [restaurantGroupName, setRestaurantGroupName] = useState('')
  const [restaurantGroupAddModalActive, setRestaurantGroupAddModalActive] = useState(false)
  const [restaurantGroupEditModalActive, setRestaurantGroupEditModalActive] = useState(false)
  const [restaurantGroupEditing, setRestaurantGroupEditing] = useState(-1)
  const [selectedEditRestaurants, setSelectedEditRestaurants] = useState([])
  const [errors, setErrors] = useState(null)
  const {
		hukkaProfiles,
		restaurants
	} = useSelector(state => state)
	const { id } = useSelector(state => state.user)

	const restaurantOptions = useCallback(
    () =>
      restaurants && restaurants.slice()
      .sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      })
      .map(r => ({
        value: r.id,
        label: r.name,
      }))
      .concat([{ value: 'allWaste', label: t('Lisää kaikki ravintolat') }]),
    [restaurants, t]
  )

  const handleGroupAdd = async () => {
    let userData = JSON.parse(JSON.stringify(hukkaProfiles.find(hp => hp.id === id).data))
    let groups = []
    if (userData && userData['restaurantGroups']) {
      groups = userData['restaurantGroups']
    }

    groups.push({
      name: restaurantGroupName,
      restaurants: selectedRestaurants
    })

    if (userData === null) {
      userData = {
        restaurantGroups: groups
      }
    } else {
      userData['restaurantGroups'] = groups
    }

    const body = {
      data: userData
    }
    
    const resp = await patch(HUKKAPROFILES_URL, id, body)

    if (resp.status === 201) {
      dispatch(updateHukkaProfiles(resp.data))
      setRestaurantGroupAddModalActive(false)
      setRestaurantGroupName('')
    } else {
      setErrors(resp.data.detail)
    }
  }

  const restaurantGroupAdd = (
    <Modal
      isActive={restaurantGroupAddModalActive}
      hideModalSubmit={
        restaurantGroupName === '' || restaurantGroupName === null
      }
      title={t('Lisää uusi ravintolaryhmä')}
      closeModalCallBack={() => {
        setRestaurantGroupAddModalActive(false)
        setRestaurantGroupName('')
      }}
      submitCallback={handleGroupAdd}
    >
      <Modal.Field label={t('Ryhmän nimi')}>
        <input
          className="input is-large is-fullwidth"
          type="text"
          onChange={e => setRestaurantGroupName(e.target.value)}
          value={restaurantGroupName}
        />
      </Modal.Field>
      <h2>Ravintolat</h2>
      <div className="control">
				<div className="select is-fullwidth hukkaselect">
					<HukkaSelect
						options={restaurantOptions()}
						placeholder={t('Valitse ravintola')}
						value={selectedRestaurants}
						onChange={e => {
							if (e && e.find(r => r.value === 'allWaste')) {
								setSelectedRestaurants(restaurantOptions().filter(ro => ro.value !== 'allWaste'))
							} else {
								setSelectedRestaurants(e)
							}
						}}
						isMulti
					/>
				</div>
			</div>
    </Modal>
  )

  const restaurantGroupOptions = hukkaProfiles && hukkaProfiles.find(hp => hp.id === id).data &&
  hukkaProfiles.find(hp => hp.id === id).data.restaurantGroups.slice()
  .sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    return 0
  }).map(rg => ({
    value: rg.restaurants,
    label: rg.name,
  }))

  const removeRestaurantGroup = async (groupIndex) => {
    let userData = JSON.parse(JSON.stringify(hukkaProfiles.find(hp => hp.id === id).data))
    let groups = []
    if (userData && userData['restaurantGroups']) {
      groups = userData['restaurantGroups'].slice()
      .sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      })
    }

    groups.splice(groupIndex, 1)

    if (userData === null) {
      userData = {
        restaurantGroups: groups
      }
    } else {
      userData['restaurantGroups'] = groups
    }

    const body = {
      data: userData
    }
    
    const resp = await patch(HUKKAPROFILES_URL, id, body)

    if (resp.status === 201) {
      dispatch(updateHukkaProfiles(resp.data))
      setRestaurantGroupAddModalActive(false)
      setRestaurantGroupName('')
    } else {
      setErrors(resp.data.detail)
    }
  }

  const handleGroupEdit = async () => {
    let userData = JSON.parse(JSON.stringify(hukkaProfiles.find(hp => hp.id === id).data))
    let groups = []
    if (userData && userData['restaurantGroups']) {
      groups = userData['restaurantGroups'].slice()
      .sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      })
      groups[restaurantGroupEditing]['restaurants'] = selectedEditRestaurants
      userData['restaurantGroups'] = groups

      const body = {
        data: userData
      }
      
      const resp = await patch(HUKKAPROFILES_URL, id, body)

      if (resp.status === 201) {
        dispatch(updateHukkaProfiles(resp.data))
        setRestaurantGroupAddModalActive(false)
        setRestaurantGroupName('')
      } else {
        setErrors(resp.data.detail)
      }
    }
    setRestaurantGroupEditing(-1)
  }

  const listRestauranGroups = restaurantGroupOptions &&
    restaurantGroupOptions.map((rgo, index) => (
      <div key={rgo.label+index} className="level restaurantGroupsEdit" style={{position: 'relative'}}>
        <div style={{width: '100%'}}>
          <div className="is-fullwidth has-text-left-desktop has-text-dark">
            <h4 className="subtitle is-4 is-marginless has-text-primary levelRowTitle">
              {rgo.label}
            </h4>
            { restaurantGroupEditing !== index && (
              <p style={{padding: '0 1rem'}}>
                {rgo.value && rgo.value.map(val => {
                  return val.label
                }).join(', ')}
              </p>
            )}
            { restaurantGroupEditing === index && (
              <HukkaSelect
                options={restaurantOptions ? restaurantOptions() : []}
                value={selectedEditRestaurants}
                isMulti
                placeholder={t('Valitse ravintola')}
                onChange={e => {
                  if (e && e.find(r => r.value === 'all')) {
                    setSelectedEditRestaurants(
                      restaurantOptions.filter(ro => ro.value !== 'all')
                    )
                  } else {
                    setSelectedEditRestaurants(e)
                  }
                }}
              />
            )}
          </div>
        </div>
        { restaurantGroupEditing !== index && (
          <>
            <div
              className="editBtn fas fa-edit"
              onClick={() => {
                setSelectedEditRestaurants(rgo.value)
                setRestaurantGroupEditing(index)
              }}
            />
            <div
                className="removeBtn fas fa-times"
                onClick={() => removeRestaurantGroup(index)}
              />
          </>
        )}
        { restaurantGroupEditing === index && (
          <button
            type="button"
            className="button is-primary is-small rightCornerBtn"
            onClick={() => {
              handleGroupEdit()
            }}
          >
            <Trans>Tallenna</Trans>
          </button>
        )}
      </div>
    ))

  const restaurantGroupEdit = (
    <div className={`modal ${restaurantGroupEditModalActive ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card" style={{ minHeight: '95%' }}>
        <header className="modal-card-head">
          <p className="modal-card-title is-uppercase">{t('Hallinnoi ravintolaryhmiä')}</p>
        </header>

        <section className="modal-card-body">
          {listRestauranGroups}
        </section>

        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-success is-large"
            disabled={restaurantGroupEditing !== -1}
            onClick={() => setRestaurantGroupEditModalActive()}
          >
            <Trans>Ok</Trans>
          </button>
        </footer>
      </div>
    </div>
  )

	return (<div>
		<br />
		{errors && (
			<div style={{margin: '10px 0'}}>
				<ErrorMessage msg={errors} />
			</div>
		)}

		<button
      type="button"
      className="button is-small is-primary"
      style={{ margin: '15px', minWidth: '125px', display: 'inline-block' }}
      onClick={() => setRestaurantGroupAddModalActive(true)}
    >
      <Trans>Luo uusi ryhmä</Trans>
    </button>
    <br />
    <h2>{t('Ryhmät')}</h2>

    <div
    	style={{width: '100%', maxWidth: '1000px', margin: 'auto'}}
    >
    	{listRestauranGroups}
    </div>
		
		{restaurantGroupAdd}
    {restaurantGroupEdit}
	</div>)
}

export default Groups

import moment from 'moment'
import {
  get,
  RESTAURANTS_URL,
  RESTAURANTTYPES_URL,
  MEALTYPES_URL,
  WASTETYPES_URL,
  ORGFOODS_URL,
  FOODPRICES_URL,
  ORGS_URL,
  HUKKAPROFILES_URL,
  COURSES_URL,
  GENERICSURVEYS_URL,
  GENERICSURVEYLINES_URL,
  GENERICSURVEYLOGS_URL,
  HUKKASERVICES_URL,
  HUKKASERVICESUBSCRIPTIONS_URL,
  HUKKATAGS_URL,
  SIMPLEINSIGHT_URL,
  FORMFIELDTEMPLATES_URL,
  FORMTEMPLATES_URL,
  FIELDORDERS_URL,
  FORMS_URL,
  FORMROWS_URL,
  FORMFIELDS_URL,
  MONITORINGPLANS_URL
} from '../utility/api'

export const HYDRATE_USER = 'HYDRATE_USER'
export const hydrateUser = (user) => ({
  type: HYDRATE_USER,
  payload: user,
})

export const FETCH_ALL_DATA = 'FETCH_ALL_DATA'
export const FETCH_ALL_DATA_SUCCESS = 'FETCH_ALL_DATA_SUCCESS'
export const FETCH_ALL_DATA_ERROR = 'FETCH_ALL_DATA_ERROR'

const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD')
const weekAgo = moment().subtract(1, 'weeks').format('YYYY-MM-DD')

const lastWeekStart = moment()
.subtract(1, 'weeks')
.startOf('isoWeek').format('YYYY-MM-DD')
const lastWeekEnd = moment()
.subtract(1, 'weeks')
.endOf('isoWeek').format('YYYY-MM-DD')

const comparisonWeekStart = moment()
.subtract(2, 'weeks')
.startOf('isoWeek').format('YYYY-MM-DD')
const comparisonWeekEnd = moment()
.subtract(2, 'weeks')
.endOf('isoWeek').format('YYYY-MM-DD')

const lastMonthStart = moment()
.subtract(1, 'month')
.startOf('month').format('YYYY-MM-DD')
const lastMonthEnd = moment()
.subtract(1, 'month')
.endOf('month').format('YYYY-MM-DD')

const comparisonMonthStart = moment()
.subtract(2, 'month')
.startOf('month').format('YYYY-MM-DD')
const comparisonMonthEnd = moment()
.subtract(2, 'month')
.endOf('month').format('YYYY-MM-DD')


export const fetchAllData = () => async (dispatch) => {
  if (window.location.pathname.indexOf('plate-waste-view') !== -1 || window.location.pathname.indexOf('magic-forest-view') !== -1) {
    const responses = await Promise.all(
      [
        ORGS_URL,
        RESTAURANTS_URL,
        MEALTYPES_URL,
        WASTETYPES_URL,
        HUKKAPROFILES_URL,
        COURSES_URL,
        HUKKASERVICES_URL,
        HUKKASERVICESUBSCRIPTIONS_URL,
      ].map((url) => get(url))
    )
  
    const responseDatas = responses.map((r) => r.data)
  
    const [
      orgs,
      restaurants,
      mealTypes,
      wasteTypes,
      hukkaProfiles,
      courses,
      hukkaServices,
      hukkaServiceSubscriptions
    ] = responseDatas
  
    dispatch({
      type: FETCH_ALL_DATA_SUCCESS,
      payload: {
        orgs,
        restaurants,
        mealTypes,
        wasteTypes,
        hukkaProfiles,
        courses,
        hukkaServices,
        hukkaServiceSubscriptions,
      },
    })
  } else {
  }
  const responses = await Promise.all(
    [
      RESTAURANTS_URL,
      RESTAURANTTYPES_URL,
      MEALTYPES_URL,
      WASTETYPES_URL,
      ORGFOODS_URL,
      FOODPRICES_URL,
      ORGS_URL,
      HUKKAPROFILES_URL,
      COURSES_URL,
      GENERICSURVEYS_URL,
      GENERICSURVEYLINES_URL,
      GENERICSURVEYLOGS_URL,
      HUKKASERVICES_URL,
      HUKKASERVICESUBSCRIPTIONS_URL,
      HUKKATAGS_URL,
      SIMPLEINSIGHT_URL + '?from=' + lastWeekStart + '&to=' + lastWeekEnd + '&comparison_from=' + comparisonWeekStart + '&comparison_to=' + comparisonWeekEnd,
      SIMPLEINSIGHT_URL + '?from=' + lastMonthStart + '&to=' + lastMonthEnd + '&comparison_from=' + comparisonMonthStart + '&comparison_to=' + comparisonMonthEnd,
      FORMFIELDTEMPLATES_URL,
      FORMTEMPLATES_URL,
      FIELDORDERS_URL,
      FORMS_URL,
      FORMROWS_URL + '?from=' + weekAgo + '&to=' + tomorrow,
      FORMFIELDS_URL + '?from=' + weekAgo + '&to=' + tomorrow,
      MONITORINGPLANS_URL
    ].map((url) => get(url))
  )

  const responseDatas = responses.map((r) => r.data)

  const [
    restaurants,
    restaurantTypes,
    mealTypes,
    wasteTypes,
    orgFoods,
    foodPrices,
    orgs,
    hukkaProfiles,
    courses,
    genericSurveys,
    genericSurveyLines,
    genericSurveyLogs,
    hukkaServices,
    hukkaServiceSubscriptions,
    hukkaTags,
    simpleinsight,
    monthSimpleinsight,
    formFieldTemplates,
    formTemplates,
    fieldOrders,
    forms,
    formRows,
    formFields,
    monitoringPlans
  ] = responseDatas

  dispatch({
    type: FETCH_ALL_DATA_SUCCESS,
    payload: {
      restaurants,
      restaurantTypes,
      mealTypes,
      wasteTypes,
      orgFoods,
      foodPrices,
      orgs,
      hukkaProfiles,
      courses,
      genericSurveys,
      genericSurveyLines,
      genericSurveyLogs,
      hukkaServices,
      hukkaServiceSubscriptions,
      hukkaTags,
      simpleinsight,
      monthSimpleinsight,
      formFieldTemplates,
      formTemplates,
      fieldOrders,
      forms,
      formRows,
      formFields,
      monitoringPlans
    },
  })
}

export const TOGGLE_SIDENAV = 'TOGGLE_SIDENAV'
export const toggleSideNav = (sideNav) => ({
  type: TOGGLE_SIDENAV,
  payload: sideNav,
})

export const TOGGLE_PROFILEMENU = 'TOGGLE_PROFILEMENU'
export const toggleProfileMenu = (profileMenu) => ({
  type: TOGGLE_PROFILEMENU,
  payload: profileMenu,
})

export const TOGGLE_LANGMENU = 'TOGGLE_LANGMENU'
export const toggleLangMenu = (langMenu) => ({
  type: TOGGLE_LANGMENU,
  payload: langMenu,
})

export const RENEW_MEALSERVINGS = 'RENEW_MEALSERVINGS'
export const renewMealServings = (mealServings) => ({
  type: RENEW_MEALSERVINGS,
  payload: mealServings,
})

export const UPDATE_MEALSERVINGS = 'UPDATE_MEALSERVINGS'
export const updateMealServings = (mealServing) => ({
  type: UPDATE_MEALSERVINGS,
  payload: mealServing,
})

export const REMOVE_MEALSERVING = 'REMOVE_MEALSERVING'
export const removeMealServing = (mealServingId) => ({
  type: REMOVE_MEALSERVING,
  payload: mealServingId,
})

export const RENEW_MEALSERVINGLOGS = 'RENEW_MEALSERVINGLOGS'
export const renewMealServingLogs = (mealServingLogs) => ({
  type: RENEW_MEALSERVINGLOGS,
  payload: mealServingLogs,
})

export const ADD_FOODPRICE = 'ADD_FOODPRICE'
export const addFoodPrice = (foodPrice) => ({
  type: ADD_FOODPRICE,
  payload: foodPrice,
})

export const UPDATE_MEALSERVING_LOGS = 'UPDATE_MEALSERVING_LOGS'
export const updateMealServingLogs = (mealServingLog) => ({
  type: UPDATE_MEALSERVING_LOGS,
  payload: mealServingLog,
})

export const REMOVE_MEALSERVING_LOG = 'REMOVE_MEALSERVING_LOG'
export const removeMealServingLog = (mealServingLogId) => ({
  type: REMOVE_MEALSERVING_LOG,
  payload: mealServingLogId,
})

export const UPDATE_RESTAURANTS = 'UPDATE_RESTAURANTS'
export const updateRestaurants = (restaurant) => ({
  type: UPDATE_RESTAURANTS,
  payload: restaurant,
})

export const REMOVE_FROM_RESTAURANTS = 'REMOVE_FROM_RESTAURANTS'
export const removeFromRestaurants = (restaurant) => ({
  type: REMOVE_FROM_RESTAURANTS,
  payload: restaurant,
})

export const UPDATE_ADD_WASTE_FORM = 'UPDATE_ADD_WASTE_FORM'
export const updateAddWasteForm = (target, value) => ({
  type: UPDATE_ADD_WASTE_FORM,
  payload: [target, value],
})

export const EMPTY_ADD_WASTE_FORM = 'EMPTY_ADD_WASTE_FORM'
export const emptyAddWasteForm = () => ({
  type: EMPTY_ADD_WASTE_FORM,
})

export const UPDATE_HUKKA_PROFILES = 'UPDATE_HUKKA_PROFILES'
export const updateHukkaProfiles = (profile) => ({
  type: UPDATE_HUKKA_PROFILES,
  payload: profile,
})

export const REMOVE_FROM_HUKKAPROFILES = 'REMOVE_FROM_HUKKAPROFILES'
export const removeFromHukkaProfiles = (id) => ({
  type: REMOVE_FROM_HUKKAPROFILES,
  payload: id,
})

export const UPDATE_GENERICSURVEYLINES = 'UPDATE_GENERICSURVEYLINES'
export const updateGenericSurveyLines = (genericSurveyLine) => ({
  type: UPDATE_GENERICSURVEYLINES,
  payload: genericSurveyLine,
})

export const UPDATE_GENERICSURVEYS = 'UPDATE_GENERICSURVEYS'
export const updateGenericSurveys = (genericSurvey) => ({
  type: UPDATE_GENERICSURVEYS,
  payload: genericSurvey,
})

export const UPDATE_AVGWASTE = 'UPDATE_AVGWASTE'
export const updateAvgWaste = (avgWaste) => ({
  type: UPDATE_AVGWASTE,
  payload: avgWaste,
})

export const UPDATE_SCORES = 'UPDATE_SCORES'
export const updateScores = (scores) => ({
  type: UPDATE_SCORES,
  payload: scores,
})

export const SET_MENUORDERESTIMATE = 'SET_MENUORDERESTIMATE'
export const setMenuOrderEstimate = (estimate) => ({
  type: SET_MENUORDERESTIMATE,
  payload: estimate,
})

export const SET_WEEKSERVINGSTATS = 'SET_WEEKSERVINGSTATS'
export const setWeekServingStats = (weekServingStats) => ({
  type: SET_WEEKSERVINGSTATS,
  payload: weekServingStats,
})

export const SET_YEARSERVINGSTATS = 'SET_YEARSERVINGSTATS'
export const setYearServingStats = (yearServingStats) => ({
  type: SET_YEARSERVINGSTATS,
  payload: yearServingStats,
})

export const SET_FOODSTATS = 'SET_FOODSTATS'
export const setFoodStats = (foodStats) => ({
  type: SET_FOODSTATS,
  payload: foodStats,
})

export const UPDATE_ORGFOODS = 'UPDATE_ORGFOODS'
export const updateOrgFoods = (orgFoods) => ({
  type: UPDATE_ORGFOODS,
  payload: orgFoods,
})

export const RENEW_ORGFOODS = 'RENEW_ORGFOODS'
export const renewOrgFoods = (orgFoods) => ({
  type: RENEW_ORGFOODS,
  payload: orgFoods,
})

export const SET_ORGS = 'SET_ORGS'
export const setOrgs = (orgs) => ({
  type: SET_ORGS,
  payload: orgs,
})

export const SET_RESTAURANTS = 'SET_RESTAURANTS'
export const setRestaurants = (restaurants) => ({
  type: SET_RESTAURANTS,
  payload: restaurants,
})

export const SET_SERVINGPREDICTIONS = 'SET_SERVINGPREDICTIONS'
export const setServingPredictions = (servingPrediction) => ({
  type: SET_SERVINGPREDICTIONS,
  payload: servingPrediction,
})

export const SET_SERVINGPREDICTIONSTATUSES = 'SET_SERVINGPREDICTIONSTATUSES'
export const setServingPredictionStatuses = (servingPredictionStatus) => ({
  type: SET_SERVINGPREDICTIONSTATUSES,
  payload: servingPredictionStatus,
})

export const UPDATE_FORMFIELDTEMPLATES = 'UPDATE_FORMFIELDTEMPLATES'
export const updateFormFieldTemplates = (formFieldTemplates) => ({
  type: UPDATE_FORMFIELDTEMPLATES,
  payload: formFieldTemplates,
})

export const UPDATE_FORMTEMPLATES = 'UPDATE_FORMTEMPLATES'
export const updateFormTemplates = (formTemplates) => ({
  type: UPDATE_FORMTEMPLATES,
  payload: formTemplates,
})

export const UPDATE_FORMS = 'UPDATE_FORMS'
export const updateForms = (forms) => ({
  type: UPDATE_FORMS,
  payload: forms,
})

export const UPDATE_FIELDORDERS = 'UPDATE_FIELDORDERS'
export const updateFieldOrders = (fieldOrders) => ({
  type: UPDATE_FIELDORDERS,
  payload: fieldOrders,
})

export const UPDATE_FORMROWS = 'UPDATE_FORMROWS'
export const updateFormRows = (formRows) => ({
  type: UPDATE_FORMROWS,
  payload: formRows,
})

export const UPDATE_FORMFIELDS = 'UPDATE_FORMFIELDS'
export const updateFormFields = (formFields) => ({
  type: UPDATE_FORMFIELDS,
  payload: formFields,
})

export const UPDATE_MONITORINGPLANS = 'UPDATE_MONITORINGPLANS'
export const updateMonitoringPlans = (monitoringPlans) => ({
  type: UPDATE_MONITORINGPLANS,
  payload: monitoringPlans,
})

export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS'
export const loginRequestSuccess = (respData) => (dispatch) => {
  dispatch({
    type: LOGIN_REQUEST_SUCCESS,
    payload: { userInfo: respData.data, token: respData.token },
  })
  dispatch(fetchAllData())
}

export const ADD_FOODCOURSEDEFAULT = 'ADD_FOODCOURSEDEFAULT'
export const addFoodCourseDefault = (formData) => ({
  type: ADD_FOODCOURSEDEFAULT,
  payload: formData
})

export const UPDATE_FOODCOURSEDEFAULT = 'UPDATE_FOODCOURSEDEFAULT'
export const updateFoodCourseDefault = (formData) => ({
  type: UPDATE_FOODCOURSEDEFAULT,
  payload: formData
})

export const TOKEN_LOGIN = 'TOKEN_LOGIN'
export const tokenLogin = (token) => (dispatch) => {
  dispatch({
    type: TOKEN_LOGIN,
    payload: { token },
  })
}

export const SET_REPORTS_TOGGLE = 'SET_REPORTS_TOGGLE'
export const setReportsToggle = (toggle) => (dispatch) => {
  dispatch({
    type: 'SET_REPORTS_TOGGLE',
    payload: toggle
  })
}

export const LOGOUT = 'LOGOUT'
export const logout = () => ({
  type: LOGOUT,
})

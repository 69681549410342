import React from 'react'

const SuccessMessage = ({ msg = '' }) => {
  const message = (
    <div className="message is-success">
      <div className="message-body">{msg}</div>
    </div>
  )

  return msg ? message : null
}

export default SuccessMessage

import React from 'react'
import { useSelector } from 'react-redux'

import Login from './login'
import Lander from './lander'

const LanderPage = () => {
  const { loggedIn } = useSelector(state => state.user)

  const renderComponent = loggedIn ? <Lander /> : <Login />

  return renderComponent
}

export default LanderPage

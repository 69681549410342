import React from 'react'
import './sass/index.scss'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'

import Lander from './components/lander'
import AddWaste from './components/addWaste'
import store from './reducers/createStore'
import Layout from './components/layout'
import PrivateRoute, { SCOPES } from './privateRoute'
import WasteReport from './components/wasteReport'
import SurveyManagement from './components/surveys/management'
import SurveyReports from './components/surveys/report'
import Info from './components/info'
import Users from './components/profiles/users'
import Restaurants from './components/profiles/restaurants'
import Organizations from './components/profiles/organization'
import PlateWaste from './components/plateWaste'
import PlateWasteView from './components/plateWasteView'
import Survey from './components/survey'
import Menu from './components/menu/menu'
import FoodPrices from './components/menu/foods'
import UserProfile from './components/userProfile'
import SingleFoodEstimation from './components/foodOrderEstimation/singleFood'
import WeekFoodsEstimation from './components/foodOrderEstimation/weekFoods'
import WeekFoodsAiEstimation from './components/foodOrderEstimation/weekFoodsAi'
import Addons from './components/addons'
import Reports from './components/reports'
import ScaleView from './components/scaleView'
import Insights from './components/insights'
import MagicForest from './components/magicForest2'
import Monitoring from './components/monitoring'
import MagicForestView from './components/magicForestView'

const App = () => {
  const { ORG_ADMIN, STAFF_ADMIN } = SCOPES
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Layout>
            <Route exact path="/">
              <Lander />
            </Route>

            <Route exact path="/plate-waste">
              <PlateWaste />
            </Route>

            <Route exact path="/plate-waste-view">
              <PlateWasteView />
            </Route>

            <Route exact path="/magic-forest-2">
              <MagicForest />
            </Route>

            <Route exact path="/magic-forest-view">
              <MagicForestView />
            </Route>

            <Route exact path="/survey">
              <Survey />
            </Route>

            <Route exact path="/addons">
              <Addons />
            </Route>

            <Route exact path="/monitoring">
              <Monitoring />
            </Route>

            <PrivateRoute exact path="/add-waste">
              <AddWaste />
            </PrivateRoute>

            <PrivateRoute exact path="/profile">
              <UserProfile />
            </PrivateRoute>

            <PrivateRoute exact path="/waste-report">
              <WasteReport />
            </PrivateRoute>

            <PrivateRoute exact path="/survey-management">
              <SurveyManagement />
            </PrivateRoute>

            <PrivateRoute exact path="/survey-reports">
              <SurveyReports />
            </PrivateRoute>

            <PrivateRoute exact path="/info">
              <Info />
            </PrivateRoute>

            <PrivateRoute exact path="/menu">
              <Menu />
            </PrivateRoute>

            <PrivateRoute exact path="/food-prices">
              <FoodPrices />
            </PrivateRoute>

            <PrivateRoute exact path="/scale-view">
              <ScaleView />
            </PrivateRoute>

            <PrivateRoute exact path="/insights">
              <Insights />
            </PrivateRoute>

            <PrivateRoute exact path="/food-order-estimation-single">
              <SingleFoodEstimation />
            </PrivateRoute>
            <PrivateRoute exact path="/food-order-estimation-week">
              <WeekFoodsEstimation />
            </PrivateRoute>
            <PrivateRoute exact path="/food-order-estimation-ml">
              <WeekFoodsAiEstimation />
            </PrivateRoute>

            <PrivateRoute scopes={[ORG_ADMIN, STAFF_ADMIN]} exact path="/organizations">
              <Organizations />
            </PrivateRoute>

            <PrivateRoute scopes={[ORG_ADMIN, STAFF_ADMIN]} exact path="/restaurants">
              <Restaurants />
            </PrivateRoute>

            <PrivateRoute scopes={[ORG_ADMIN, STAFF_ADMIN]} exact path="/users">
              <Users />
            </PrivateRoute>

            <PrivateRoute exact path="/reports">
              <Reports />
            </PrivateRoute>
          </Layout>
        </Switch>
      </Router>
    </Provider>
  )
}

export default App

import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import Password from './password'
import Groups from './groups'

const UserProfile = () => {
	const [selectedTab, setSelectedTab] = useState('groups')
	const {hukkaProfiles, restaurants} = useSelector(
    state => state
  )
  const { t } = useTranslation()
	if (!hukkaProfiles || !restaurants) {
    return (<p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>)
	}
  return (<div>
  	<div>
	  	<button
	      type="button"
	      className={'button is-small' + (selectedTab === 'groups' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('groups')
	      }}
	    >
	      <Trans>Ryhmien hallinta</Trans>
	    </button>
	    <button
	      type="button"
	      className={'button is-small' + (selectedTab === 'password' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('password')
	      }}
	    >
	      <Trans>Salasanan vaihto</Trans>
	    </button>
  	</div>
  	<div>
  	{ selectedTab === 'groups' && (<Groups />) }
  	{ selectedTab === 'password' && (<Password />) }
  	</div>
  </div>)
}

export default UserProfile

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { SingleDatePicker } from 'react-dates'
import { useTranslation, Trans } from 'react-i18next'
import Modal from '../modal'
import HukkaSelect from '../hukkaSelect'
import { renewMealServings, updateAddWasteForm, updateGenericSurveys, updateGenericSurveyLines } from '../../actions'
import { get, post, patch, MEALSERVINGS_URL, GENERICSURVEYS_URL, GENERICSURVEYLINES_URL } from '../../utility/api'

const SurveyManagement = () => {
  const { restaurants, mealServings, mealServingLogs, mealTypes, addWasteForm, genericSurveys, genericSurveyLines } = useSelector(
    state => state
  )
  const { selectedRestaurant } = addWasteForm || {}
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [date, setDate] = useState(moment())
  const [datePickerFocused, setDatePickerFocused] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalError, setModalError] = useState(null)
  const [editId, setEditId] = useState('')
  const [editTitle, setEditTitle] = useState('')
  const [editDescription, setEditDescription] = useState('')
  const defaultTitle = t('Miten onnistuimme tänään?')

  const resetSelectedMealServing = () => {
    dispatch(updateAddWasteForm('selectedMealServing', null))
  }

  const getMealServings = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServings(resp.data))
    }
  }

  useEffect(() => {
    if (restaurants.length === 1) {
      dispatch(updateAddWasteForm('selectedRestaurant', restaurants[0].id))
    }
  })

  const restaurantOptions =
    restaurants &&
    restaurants.slice()
    .sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    .map(r => ({
      value: r.id,
      label: r.name,
    }))

  const handleModalSubmit = async () => {
    const resp = await patch(GENERICSURVEYLINES_URL, editId, {
      description: editDescription
    })

    if (resp.status === 200) {
      dispatch(updateGenericSurveyLines(resp.data))
      setModalOpen(false)
      setEditId('')
      setEditTitle('')
      setEditDescription('')
    } else {
      setModalError(resp.details)
    }
  }

  const createSurvey = async (survey, relatedServing) => {
    if (!survey) {
      const response = await post(GENERICSURVEYS_URL, {
        valid_until: date,
        restaurant: selectedRestaurant
      })
  
      if (response.status === 201) {
        dispatch(updateGenericSurveys(response.data))

        const resp = await post(GENERICSURVEYLINES_URL, {
          survey: response.data.id,
          related_serving: relatedServing,
          description: defaultTitle
        })
    
        if (resp.status === 201) {
          dispatch(updateGenericSurveyLines(resp.data))
        } else {
          setModalError(resp.details)
        }

      } else {
        setModalError(response.details)
      }
    } else {
      const resp = await post(GENERICSURVEYLINES_URL, {
        survey,
        related_serving: relatedServing,
        description: defaultTitle
      })
  
      if (resp.status === 201) {
        dispatch(updateGenericSurveyLines(resp.data))
        setModalOpen(false)
      } else {
        setModalError(resp.details)
      }
    }
  }

  const editModal = () => {
    return (
      <Modal
        isActive={modalOpen}
        closeModalCallBack={() => {
          setEditId('')
          setEditTitle('')
          setEditDescription('')
          setModalOpen(false)
        }}
        errors={modalError}
        title={editTitle}
        submitCallback={handleModalSubmit}
      >
        <Modal.Field label={t('Kyselyn otsikko')}>
          <div className="control has-icons-left is-large has-text-primary is-clearfix">
            <input
              type="text"
              className="input is-large has-text-primary"
              value={editDescription}
              onChange={e => setEditDescription(e.target.value)}
            />
            <span className="icon is-left">
              <i className="mdi mdi-user mdi-24px" />
            </span>
          </div>
        </Modal.Field>
      </Modal>
    )
  }

  const mealServingsList = () => {
    let html = []

    let selectedRestaurantMealservingOptions = selectedRestaurant ? mealServings
    .filter(ms => ms.restaurant === selectedRestaurant)
    .filter(ms => moment(ms.servingDatetime).isSame(date, 'day')) : []

    let surveys = selectedRestaurant ? genericSurveys.filter(gs => gs.restaurant === selectedRestaurant && moment(gs.validUntil).format('DD.MM.YYYY') === moment(date).format('DD.MM.YYYY')).map(gs => gs.id) : []
    let surveyLines = selectedRestaurant ? genericSurveyLines.filter(gsl => surveys.indexOf(gsl.survey) !== -1) : []

    selectedRestaurantMealservingOptions.forEach(srmso => {
      let innerHtml = []
      let serving = t(mealTypes.find(mt => mt.id === srmso.mealType).name) + ' ' + moment(srmso.servingDatetime).format('DD.MM.YYYY')
      innerHtml.push(<div key={srmso.id} className="title is-5">{serving}</div>)
      let logs = mealServingLogs.filter(msl => msl.mealServing === srmso.id && msl.food !== '')
      innerHtml.push(<div key={srmso.id+'_list'}><b>{t('Ruokalista')}:</b></div>)
      logs.forEach(l => {
        innerHtml.push(<div key={srmso.id+l.id}>{l.foodName}</div>)
      })
      let line = surveyLines.find(sl => sl.relatedServing === srmso.id)
      let editDescription = (
        <div>
          <div style={{'marginTop': '30px'}}><b>{t('Kyselyn otsikko')}:</b></div>
          <h2 className="title is-5">
            {line && line.description}
          </h2>
          <button
          type="button"
          className="button is-large"
          onClick={() => {
            setEditId(line.id)
            setEditTitle(serving)
            setEditDescription(line.description)
            setModalOpen(true)
          }}
        >
          {t('Muokkaa kyselyn otsikkoa')}
        </button>
        </div>
      )
      let createNew = (
        <button
          type="button"
          className="button is-large"
          style={{'marginTop': '30px'}}
          onClick={() => {
            let surv = surveys.length > 0 ? surveys[0] : ''
            createSurvey(surv, srmso.id)
          }}
        >
          {t('Luo uusi kysely')}
        </button>
      )
      innerHtml.push(<div key={'line_'+srmso.id}>{
        typeof line !== 'undefined' ? editDescription : createNew
      }</div>)

      html.push(
        <div key={srmso.id+'_box'} className="box">{innerHtml}</div>
      )
    })
    return (
      <div>
        {html}
      </div>
    )
  }

  return (
    <div className="choose-serving-form">
      <h1 className="title is-1 is-size-3-mobile is-uppercase">
        <Trans>Kyselyiden hallinta</Trans>
      </h1>
      <div className="control">
        <div className="select is-large is-fullwidth hukkaselect">
          <HukkaSelect
            options={restaurantOptions}
            value={restaurantOptions.find(
              ro => ro.value === selectedRestaurant
            )}
            placeholder={t('Valitse ravintola')}
            onChange={e => {
              getMealServings(date.format('YYYY-MM-DD'), date.clone().add(1, 'days').format('YYYY-MM-DD'))
              dispatch(updateAddWasteForm('selectedRestaurant', e.value))
              dispatch(updateAddWasteForm('selectedFood', ''))
              dispatch(updateAddWasteForm('selectedWasteType', ''))
              dispatch(updateAddWasteForm('selectedCourse', ''))
              resetSelectedMealServing()
            }}
          />
        </div>
      </div>

      <div style={{ marginBottom: '15px' }}>
        <SingleDatePicker
          date={date}
          onDateChange={newDate => {
            setDate(newDate)
            getMealServings(newDate.format('YYYY-MM-DD'), newDate.clone().add(1, 'days').format('YYYY-MM-DD'))
            dispatch(updateAddWasteForm('selectedFood', ''))
            dispatch(updateAddWasteForm('selectedWasteType', ''))
            dispatch(updateAddWasteForm('selectedCourse', ''))
            resetSelectedMealServing()
          }}
          focused={datePickerFocused}
          onFocusChange={({ focused }) => setDatePickerFocused(focused)}
          id="addWasteDatePicker"
          numberOfMonths={1}
          displayFormat="DD.MM.YYYY"
          withFullScreenPortal
          block
          isOutsideRange={() => false}
          disabled={!selectedRestaurant}
        />
      </div>

      <div>
        {mealServingsList()}
        {editModal()}
      </div>
    </div>
  )
}

export default SurveyManagement

import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import { hydrateTokenHeader } from '../utility/api'
import { fetchAllData, hydrateUser } from '../actions'
import reducer, { initialState } from '.'

// Keep user info persisted in localStorage
const persistedUser = localStorage.getItem('persistedUser')
const hydratedUser =
  persistedUser !== 'undefined' && persistedUser
    ? JSON.parse(persistedUser)
    : null

// Log redux only on development
const middlewares = [thunk]
if (process.env.NODE_ENV === `development`) {
  middlewares.push(createLogger())
}

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middlewares)
)
if (hydratedUser) {
  store.dispatch(hydrateUser(hydratedUser))
  if (hydratedUser.loggedIn) {
    hydrateTokenHeader(hydratedUser.token)
    store.dispatch(fetchAllData())
  }
}

store.subscribe(() => {
  if (hydratedUser !== JSON.stringify(store.getState().user)) {
    localStorage.setItem('persistedUser', JSON.stringify(store.getState().user))
  }
})

export default store

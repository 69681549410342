import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import HukkaSelect from '../hukkaSelect'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import noSleep from 'nosleep.js'
import { post, GENERICSURVEYLOGS_URL } from '../../utility/api'

const Survey = () => {
  const { hukkaProfiles, mealServingLogs, genericSurveys, genericSurveyLines, hukkaServices, hukkaServiceSubscriptions } = useSelector(
    state => state
  )
  const { loggedIn, rou:readOnlyUser } = useSelector(state => state.user) || { loggedIn: false, readOnlyUser: false }
  const [selectedSurveyLine, setSelectedSurveyLine] = useState(false)
  const [selectedServing, setSelectedServing] = useState(false)
  const [selectedDescription, setSelectedDescription] = useState(false)
  const [selectedRating, setSelectedRating] = useState(0)
  const [thankYouMsg, setThankYouMsg] = useState(false)
  const [surveyLogComment, setSurveyLogComment] = useState('')
  const [fullscreenActive, setFullscreenActive] = useState(false)
  const [currentAnimation, setCurrentAnimation] = useState('like')
  const { t } = useTranslation()
  const [commentTimeout, setCommentTimeout] = useState(false)

  const webm = Math.floor(Math.random() * 4.9)
  const webmChoises = ['kettu', 'siili', 'pesukarhu', 'karhu', 'pingviini']

  let noSleepObj = new noSleep()

  const fullscreen = () => {
    let elem = document.getElementById('surveyFullscreen')

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem = window.top.document.body; //To break out of frame in IE
      elem.msRequestFullscreen();
    }
    setFullscreenActive(true)
    noSleepObj.enable()
  }

  const chooseSurveyLine = () => {
    let surveys = genericSurveys && genericSurveys.filter(gs => moment(gs.validUntil).format('DD.MM.YYYY') === moment(new Date()).format('DD.MM.YYYY')).map(gs => gs.id)
    let surveyLines = genericSurveyLines && genericSurveyLines.filter(gsl => surveys.indexOf(gsl.survey) !== -1).map(gsl => ({
      value: gsl.id,
      label: t(gsl.description),
      mealServing: gsl.relatedServing,
      description: t(gsl.description)
    }))
    return (
      <div>
        <div className="control">
          <div className="select is-large is-fullwidth hukkaselect">
            <HukkaSelect
              options={surveyLines ? surveyLines : []}
              value={selectedSurveyLine ? surveyLines.find(
                ro => ro.value === selectedSurveyLine
              ) : ''}
              placeholder={t('Valitse kysely')}
              onChange={e => {
                setSelectedSurveyLine(e.value)
                setSelectedServing(e.mealServing)
                setSelectedDescription(e.description)
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const handleFeedbackSubmit = async (includeMessage) => {
    await post(GENERICSURVEYLOGS_URL, {
      meal_serving: selectedServing,
      line: selectedSurveyLine,
      score: selectedRating,
      comment: includeMessage ? surveyLogComment : ''
    })

    setSelectedRating(0)
    setSurveyLogComment('')
    setThankYouMsg(false)
  }

  useEffect(() => {
    if (fullscreenActive) {
      const interval = setInterval(() => {
        if (currentAnimation === 'like' && document.getElementById('like_button')) {
          document.getElementById('like_button').play()
          setCurrentAnimation('dontlike')
        } else if (currentAnimation === 'dontlike' && document.getElementById('dislike_button')) {
          document.getElementById('dislike_button').play()
          setCurrentAnimation('like')
        }
      }, 5000)
      return () => clearInterval(interval)
    }
    if (!thankYouMsg) {
      return () => clearTimeout(commentTimeout)
    }
  })

  const surveyButtons = () => {
    let logs = selectedServing && mealServingLogs.filter(msl => msl.mealServing === selectedServing && msl.food !== '')
    let html = []
    html.push(<div key={selectedServing+'_list'}><b>{t('Kyselyn ruokalista')}:</b></div>)
    logs.forEach(l => {
      html.push(<div key={selectedServing+l.id}>{l.foodName}</div>)
    })
    return (
      <div className="surveyButtons">
        {html}
        <button
          type="button"
          className="hukkaBtn"
          style={{'marginTop': '20px'}}
          onClick={() => fullscreen()}
        >
          {t('Käynnistä kysely')}
        </button>
        <div style={{position: 'fixed', top: '-100%'}}>
          <div id="surveyFullscreen" className="survey" style={{'background': '#fff', padding: '30px'}}>
            <img id="fullscreen-hukka-logo" src="/img/hukka_logo_slogan.png" alt="Hukka logo" />
            {
              !thankYouMsg && (
                <>
                  <div className="ribbonTitle" style={{width: '100%', maxWidth: '600px', margin: 'auto'}}>
                    {selectedDescription}
                  </div>
                  <div className="survey__buttonArea">
                    <div
                      className="survey__button survey__button--like"
                      onClick={() => {
                        setSelectedRating(100)
                        setThankYouMsg(true)
                        setCommentTimeout(setTimeout(function () {
                          handleFeedbackSubmit(false)
                          setThankYouMsg(false)
                        }, 10000))
                      }}
                    >
                      <video id="like_button">
                        <source src={'/webm/feedback/' + webmChoises[webm] + '_like.webm'} type="video/webm" />
                      </video>
                    </div>
                    <div
                      className="survey__button survey__button--dontlike"
                      onClick={() => {
                        setSelectedRating(0)
                        setThankYouMsg(true)
                        setCommentTimeout(setTimeout(function () {
                          handleFeedbackSubmit(false)
                          setThankYouMsg(false)
                        }, 10000))
                      }}
                    >
                      <video id="dislike_button">
                        <source src={'/webm/feedback/' + webmChoises[webm] + '_dislike.webm'} type="video/webm" />
                      </video>
                    </div>
                  </div>
                </>
              )
            }
            {
              thankYouMsg && (
                <div className="survey__thankyou">
                  <div className="ribbonTitle" style={{width: '100%', maxWidth: '800px', margin: 'auto auto 20px auto'}}>
                    {t('Kiitos palautteesta')}
                  </div>
                  <p className="text-primary-color" style={{marginBottom: '-20px'}}>{t('Haluatko jättää meille myös kirjallista palautetta')}?</p>
                  <br />
                  <textarea
                    className="textarea"
                    style={{minHeight: '200px'}}
                    value={surveyLogComment}
                    onChange={e => {
                      setSurveyLogComment(e.target.value)
                      clearTimeout(commentTimeout)
                      setCommentTimeout(setTimeout(function () {
                        handleFeedbackSubmit(true)
                        setThankYouMsg(false)
                      }, 10000))
                    }}
                  />
                  <br />
                  <button
                    type="button"
                    className="button is-primary"
                    onClick={() => {
                      handleFeedbackSubmit(true)
                      setThankYouMsg(false)
                      clearTimeout(commentTimeout)
                    }}
                  >
                    {t('Lähetä kirjallinen palaute')}
                  </button>
                  <br />
                  <button
                    type="button"
                    className="button"
                    style={{'marginTop': '10px'}}
                    onClick={() => {
                      handleFeedbackSubmit(false)
                      setThankYouMsg(false)
                      clearTimeout(commentTimeout)
                    }}
                  >
                    {t('En halua antaa kirjallista palautetta')}
                  </button>
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }

  const showSurveyView = () => {
    let hasPlateWasteService = false
    if (hukkaServices && hukkaServiceSubscriptions) {
      let plateWasteService = hukkaServices.find(hs => hs.name === 'Kysely')
      if (hukkaServiceSubscriptions.find(hss => hss.service === plateWasteService.id && moment(new Date()) < moment(hss.validUntil))) {
        hasPlateWasteService = true
      }
    }
    return hasPlateWasteService ? (
      <div className="survey">
        <button
            type="button"
            className="button is-primary is-small"
            style={{margin: '20px auto'}}
            onClick={() => {
              window.location.reload()
            }}
          >
            {t('Päivitä kyselyt')}
          </button>
        {chooseSurveyLine()}
        {selectedSurveyLine && surveyButtons()}
      </div>  
    ) : (<p className="is-size-2 is-size-3-mobile is-uppercase" style={{marginTop: '30px'}}>{t('Hei! Valitettavasti sinulla ei ole oikeuksia tämän palvelun käyttöön, mutta jos haluat tietää lisää, niin ota yhteyttä mailitse')}:<br /><a href="mailto:asiakaspalvelu@hukka.ai">asiakaspalvelu@hukka.ai</a></p>)
  }
  
  return (
    <>
      {!hukkaProfiles && (<p className="is-size-2 is-size-3-mobile is-uppercase">{t('Ladataan')}...</p>)}
      {loggedIn && hukkaProfiles && hukkaProfiles.length > 0 && readOnlyUser && showSurveyView()}
    </>
  )
}

export default Survey

import React from 'react'
import { useSelector } from 'react-redux'
import {
  instanceOf,
  arrayOf,
  string,
  shape,
  oneOfType,
  number,
} from 'prop-types'
import moment from 'moment'
import { useTranslation, Trans } from 'react-i18next'

const WasteReportTable = ({ tableData, startDate, endDate, customers }) => {
  const { t } = useTranslation()
  const {
    wasteTypes
  } = useSelector(state => state)

  const tableRows = () => {
    let html = []
    let totalWaste = 0
    let wasteTypeNames = []
    let wasteTypeValues = []
    let wasteTypesArr = wasteTypes.map(wt => wt.id)
    let totalConsumption = 0

    tableData.forEach(function (td) {
      if (wasteTypesArr.indexOf(td.data[7]) !== -1) {
        let nameIndex = wasteTypeNames.indexOf(td.data[7])
        if (nameIndex !== -1) {
          wasteTypeValues[nameIndex] = wasteTypeValues[nameIndex] + td.data[1]
        } else {
          wasteTypeNames.push(td.data[7])
          wasteTypeValues.push(td.data[1])
        }
        totalConsumption = totalConsumption + td.data[3]
        totalWaste = totalWaste + td.data[1]
      }
    })

    html.push(
      <tr key={'total-consumption'}>
        <th>
          {t('Kokonaismenekki')} (kg)
        </th>
        <td>
          {totalConsumption.toFixed(1)}
        </td>
      </tr>
    )
    html.push(
      <tr key={'total-consumption-per-customer'}>
        <th>
          {t('Kokonaismenekki') + ' ' + t('per asiakas')} (kg)
        </th>
        <td>
          {(totalConsumption / customers).toFixed(2)}
        </td>
      </tr>
    )

    html.push(
      <tr key={'total-waste'}>
        <th>
          {t('Kokonaishävikki')} (kg)
        </th>
        <td>
          {totalWaste.toFixed(1)}
        </td>
      </tr>
    )
    html.push(
      <tr key={'total-waste-per-customer'}>
        <th>
          {t('Kokonaishävikki') + ' ' + t('per asiakas')} (kg)
        </th>
        <td>
          {(totalWaste / customers).toFixed(2)}
        </td>
      </tr>
    )

    wasteTypeNames.forEach(function (wtId, index) {
      let name = wasteTypes.find(wt => wt.id === wtId).name
      if (name === 'plate' || name === 'Lautashävikki') {
        name = 'Lautashävikki'
      } else if (name === 'line' || name === 'Linjastohävikki') {
        name = 'Linjastohävikki'
      }
      html.push(
        <tr key={wtId+index}>
          <th>
            {t(name)} (kg)
          </th>
          <td>
            {wasteTypeValues[index].toFixed(1)}
          </td>
        </tr>   
      )
      html.push(
        <tr key={wtId+index+'per-customer'}>
          <th>
            {t(name)} {t('per asiakas')} (kg)
          </th>
          <td>
            {(wasteTypeValues[index] / customers).toFixed(2)}
          </td>
        </tr>   
      )
    })



    return (<>{html}</>)
  }

  return (
    <div>
      <div className="title is-4"><Trans>Hävikkidata</Trans></div>

      <div style={{ textAlign: '-webkit-center' }}>
        <table className="table is-striped is-bordered">
          <tbody>
            <tr>
              <th><Trans>Alkupäivämäärä</Trans></th>
              <td>{startDate.format('L')}</td>
            </tr>
            <tr>
              <th><Trans>Loppupäivämäärä</Trans></th>
              <td>{endDate.format('L')}</td>
            </tr>
          </tbody>

          <tbody>
            {tableRows()}
          </tbody>
        </table>
      </div>

      <p className="is-primary"><Trans>Taulukko on suoraan kopioitavissa exceliin</Trans></p>
    </div>
  )
}

WasteReportTable.propTypes = {
  tableData: arrayOf(shape({ data: arrayOf(oneOfType([string, number])) }))
    .isRequired,
  startDate: instanceOf(moment).isRequired,
  endDate: instanceOf(moment).isRequired,
}

export default WasteReportTable

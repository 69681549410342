import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DateRangePicker } from 'react-dates'
import { useTranslation, Trans } from 'react-i18next'
import { renewMealServings, renewMealServingLogs } from '../../actions'
import { MEALSERVINGS_URL, MEALSERVINGLOGS_URL, get } from '../../utility/api'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import {
  VERTICAL_ORIENTATION,
  HORIZONTAL_ORIENTATION,
} from 'react-dates/constants'
import moment from 'moment'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import groupBy from 'lodash/groupBy'

import HukkaSelect from '../hukkaSelect'
import ReportTable from './reportTable'
import WasteReportTable from './wasteReportTable'

// Not sure if this is enough colors, but its easy to add them later
const COLORS = [
  '#a6cee3',
  '#1f78b4',
  '#b2df8a',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#fdbf6f',
  '#ff7f00',
  '#cab2d6',
  '#6a3d9a',
  '#b15928',
  '#8dd3c7',
  '#ffffb3',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#d9d9d9',
  '#bc80bd',
  '#ccebc5',
  '#ffed6f',
  '#b3e2cd',
  '#fdcdac',
  '#cbd5e8',
  '#f4cae4',
  '#e6f5c9',
  '#fff2ae',
  '#f1e2cc',
  '#cccccc',
  '#66c2a5',
  '#fc8d62',
  '#8da0cb',
  '#e78ac3',
  '#a6d854',
  '#ffd92f',
  '#e5c494',
  '#b3b3b3',
  '#7fc97f',
  '#beaed4',
  '#fdc086',
  '#386cb0',
  '#f0027f',
  '#bf5b17',
  '#1b9e77',
  '#d95f02',
  '#7570b3',
  '#e7298a',
  '#66a61e',
  '#e6ab02',
  '#a6761d',
  '#666666',
]

const WasteReport = () => {
  const {
    restaurants,
    mealServings,
    mealServingLogs,
    wasteTypes,
    mealTypes,
    orgFoods,
    foodPrices,
    courses,
    hukkaProfiles
  } = useSelector(state => state)
  const { id } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [selectedRestaurants, setSelectedRestaurants] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const [chartData, setChartData] = useState(null)
  const [filter, setFilter] = useState('allWaste')
  const [filterType, setFilterType] = useState('')
  const [scope, setScope] = useState('courses')
  const [startDate, setStartDate] = useState(moment().startOf('month'))
  const [endDate, setEndDate] = useState(moment().endOf('month'))
  const [tableData, setTableData] = useState(null)
  const [selectedFoods, setSelectedFoods] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [chartServings, setChartServings] = useState(null)
  const [selectedChartServing, setSelectedChartServing] = useState(null)
  const [totalCustomers, setTotalCustomers] = useState(null)
  const [chartDates, setChartDates] = useState([])
  const [noteDates, setNoteDates] = useState([])
  const chartRef = useRef(null)
  const { t } = useTranslation()
  const [fetch, setFetch] = useState(true)
  const [loading, setLoading] = useState(false)

  const removeDublicatesAndNonExist = (arr) => {
    let uniqueIds = []
    let uniqueExist = []
    arr.forEach(function (a) {
      if (uniqueIds.indexOf(a.value) === -1) {
        uniqueIds.push(a.value)
        if (restaurants.find(r => r.id === a.value)) {
          uniqueExist.push(a)
        }
      }
    })
    return uniqueExist
  }

  const getMealServings = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServings(resp.data))
    } else {
    }
  }
  const getMealServingLogs = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGLOGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServingLogs(resp.data))
    } else {
    }
    setLoading(false)
  }

  // Allows us to use this function in an useEffect -function without triggering re-render on every update
  const getRestaurantOptions = useCallback(
    () =>
      restaurants.slice()
      .sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      })
      .map(r => ({
        value: r.id,
        label: r.name,
      }))
      .concat([{ value: 'allWaste', label: t('Lisää kaikki ravintolat') }]),
    [restaurants, t]
  )

  const getFoodOptions = useCallback(
    () =>
      orgFoods && orgFoods.slice()
      .filter(f => f.name !== null && (Object.keys(f.canon).length === 0 || f.id === f.canon.food))
      .sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      }).map(f => ({
        value: f.id,
        label: f.name,
      })),
    [orgFoods]
  )

  const restaurantGroupOptions = hukkaProfiles && hukkaProfiles.find(hp => hp.id === id).data &&
  hukkaProfiles.find(hp => hp.id === id).data.restaurantGroups
    .map(rg => ({
      value: rg.restaurants,
      label: rg.name,
    }))

  const restaurantGroupSelect = (<>
    <div className="select is-fullwidth hukkaselect">
      <HukkaSelect
        options={restaurantGroupOptions ? restaurantGroupOptions : []}
        value={''}
        placeholder={t('Valitse ravintolaryhmä')}
        onChange={e => {
          if (selectedRestaurants) {
            let selected = removeDublicatesAndNonExist(selectedRestaurants.concat(e.value))
            setSelectedRestaurants(selected)
          } else {
            setSelectedRestaurants(e.value)
          }
        }}
      />
    </div>
    <i style={{textAlign: 'left', display: 'block', margin: '-10px 0 10px 0'}}>{t('Lisää/Muokkaa ryhmiä')} <a href="/profile">{t('profiilisivulla')}</a></i>
  </>)

  const addSelectedFoods = food => {
    const foodOption = getFoodOptions().find(f => f.label === food)
    if (!foodOption) return
    if (selectedFoods) {
      const foodIds = selectedFoods.map(fo => fo.value)
      if (foodIds.includes(foodOption.value)) return

      setSelectedFoods([...selectedFoods, foodOption])
    } else {
      setSelectedFoods([foodOption])
    }
  }

  const datePickerOrientation = window.matchMedia('(max-width: 768px)').matches
    ? VERTICAL_ORIENTATION
    : HORIZONTAL_ORIENTATION

  const location = useLocation()
  const history = useHistory()

  // This is at time the best way to block useEffect at initialMount
  // https://reactjs.org/docs/hooks-faq.html#can-i-run-an-effect-only-on-updates
  const isInitialMount = useRef(true)
  const QUERY_STRING_DATE_FORMAT = 'DD.MM.YYYY'

  // Keep updating user's selections
  useEffect(() => {
    // Block this from happening "onMount" and before restaurants are loaded
    if (!isInitialMount.current) {
      const restaurantsQueryString =
        selectedRestaurants && selectedRestaurants.map(r => r.value).join(',')

      let foodsQueryString = ''
      if (selectedFoods) {
        foodsQueryString =
          selectedFoods && ('&foods=' + selectedFoods.map(r => r.value).join(','))
      }

      let startDateQueryString = ''
      let endDateQueryString = ''
      if (endDate && startDate) {
        startDateQueryString = startDate.format(QUERY_STRING_DATE_FORMAT)
        endDateQueryString = endDate.format(QUERY_STRING_DATE_FORMAT)
      }

      history.push({
        ...history.location,
        search: `restaurants=${restaurantsQueryString}&startDate=${startDateQueryString}&endDate=${endDateQueryString}${foodsQueryString}&filterType=${filterType}&filter=${filter}&scope=${scope}`,
      })
    }
  }, [
    selectedRestaurants,
    selectedFoods,
    startDate,
    endDate,
    filter,
    filterType,
    scope,
    history
  ])

  useEffect(() => {
    // Show normal view to the point restaurants are loaded
    // after that we can populate selections from url params
    // and let further changes flow to the url
    // If this is not blocked the initial values will flow to url
    // but the original url-values are not considered at all
    if (restaurants.length > 0) {
      const {
        endDate: endDateQS,
        startDate: startDateQS,
        filter: filterQS,
        filterType: filterTypeQS,
        scope: scopeQS,
        restaurants: restaurantsQS,
        foods: foodsQS
      } = queryString.parse(location.search, {
        arrayFormat: 'comma',
      })

      if (filterQS) {
        setFilter(filterQS)
      }
      if (filterTypeQS) {
        setFilterType(filterTypeQS)
      }
      if (scopeQS) {
        setScope(scopeQS)
      }

      if (endDateQS && startDateQS) {
        setEndDate(moment(endDateQS, QUERY_STRING_DATE_FORMAT))
        setStartDate(moment(startDateQS, QUERY_STRING_DATE_FORMAT))
        if (fetch) {
          setLoading(true)
          getMealServings(moment(startDateQS, QUERY_STRING_DATE_FORMAT).format('YYYY-MM-DD'), moment(endDateQS, QUERY_STRING_DATE_FORMAT).format('YYYY-MM-DD'))
          getMealServingLogs(moment(startDateQS, QUERY_STRING_DATE_FORMAT).format('YYYY-MM-DD'), moment(endDateQS, QUERY_STRING_DATE_FORMAT).format('YYYY-MM-DD'))
          setFetch(false)
        }
      }

      if (foodsQS) {
        setSelectedFoods(
          getFoodOptions().filter(fo => foodsQS.includes(fo.value))
        )
      }

      if (restaurantsQS) {
        setSelectedRestaurants(
          getRestaurantOptions().filter(ro => restaurantsQS.includes(ro.value))
        )
      }

      isInitialMount.current = false
    }
  }, [restaurants, location.search, getRestaurantOptions, getFoodOptions])

  // calculates the data for Recharts
  useEffect(() => {
    if (!selectedRestaurants) {
      return
    }

    const selectedRestaurantIds = selectedRestaurants.map(sr => sr.value)

    const mealServingIdsBetweenDates = mealServings
      .filter(mealServing =>
        selectedRestaurantIds.includes(mealServing.restaurant)
      )
      .filter(mealServing => {
        if (filterType === 'meal') {
          return mealServing.mealType === filter
        } else {
          return true
        }
      })
      .map(d => d.id)

    const mealServingsBetweenDates = mealServings
      .filter(mealServing =>
        selectedRestaurantIds.includes(mealServing.restaurant)
      )
      .filter(mealServing => {
        if (filterType === 'meal') {
          return mealServing.mealType === filter
        } else {
          return true
        }
      })

    const mealServingLogsFilteredBySelectedRestaurantsWithFoodName = mealServingLogs
      .filter(mealServingLog =>
        mealServingIdsBetweenDates.includes(mealServingLog.mealServing)
      )
      .map(log => {
        if (log.foodName) {
          return log
        }

        if (log.wasteType !== wasteTypes.find(wt => wt.name === 'line').id) {
          return { ...log, foodName: wasteTypes.find(wt => wt.id === log.wasteType).name }
        }

        return { ...log, foodName: null }
      })
      .filter(log => log.foodName)
      .filter(log => {
        const { wasteType } = log

        if (filter === 'allWaste') {
          return true
        } else if (filterType === 'waste' && filter !== '') {
          return wasteType === filter
        } else {
          return (wasteType !== wasteTypes.find(wt => wt.name === 'Keittiöhävikki').id && wasteType !== wasteTypes.find(wt => wt.name === 'plate').id)
        }
      })
      .filter(log => {
        const selectedFoodIds =
          (selectedFoods && selectedFoods.map(f => f.value)) || []
        if (selectedFoodIds.length > 0) {
          return selectedFoodIds.includes(log.food)
        }
        return true
      })
      .map(log => {
        const consumptionGrams = log.preparedAmountGrams - log.wasteGrams
        const course = courses.find(c => c.id === log.course)
        const courseName = course ? course.name : 'N/A'

        return { ...log, consumptionGrams, course: courseName, customers: mealServingsBetweenDates.find(ms => ms.id === log.mealServing).customers }
      })

    const mealServingLogIds = mealServingLogsFilteredBySelectedRestaurantsWithFoodName.map(ms => ms.mealServing)

    let totalCustomersAmount = 0
    let chartDatesArr = []
    let noteDatesArr = []

    mealServings.filter(mealServing =>
      selectedRestaurantIds.includes(mealServing.restaurant)
    )
    .filter(mealServing => {
      if (filterType === 'meal') {
        return mealServing.mealType === filter
      } else {
        return true
      }
    }).forEach(function (ms) {
      if (ms.servingNotes !== '' && ms.servingNotes !== null && noteDatesArr.indexOf(moment(ms.servingDatetime).format('ddd DD.MM.')) === -1) {
        noteDatesArr.push(moment(ms.servingDatetime).format('ddd DD.MM.'))
      }
      if (mealServingLogIds.indexOf(ms.id) !== -1) {
        if (ms.customers > 1) {
          totalCustomersAmount = totalCustomersAmount + ms.customers
        }
        if (chartDatesArr.indexOf(moment(ms.servingDatetime).format('YYYY-MM-DD')) === -1) {
          chartDatesArr.push(moment(ms.servingDatetime).format('YYYY-MM-DD'))
        }
      }
    })

    setNoteDates(noteDatesArr)
    setTotalCustomers(totalCustomersAmount)
    setChartDates(chartDatesArr)

    const mealServingLogDigest = mealServingLogsFilteredBySelectedRestaurantsWithFoodName.map(
      ({
        wasteGrams,
        foodName,
        food,
        mealServing,
        preparedAmountGrams,
        consumptionGrams,
        course,
        customers,
        wasteType
      }) => ({
        date: moment(
          mealServings.find(ms => ms.id === mealServing).servingDatetime
        ).format('YYYY-MM-DD'),
        data: [
          foodName,
          wasteGrams / 1000,
          preparedAmountGrams / 1000,
          consumptionGrams / 1000,
          course,
          food,
          customers,
          wasteType
        ],
      })
    )

    setTableData(mealServingLogDigest)

    const groupedByDate = mealServingLogDigest.reduce(
      (acc, { data, date }) => {
        const [foodName, waste, prepared, consumption, course, foodId] = data

        let dataKey
        let foodData
        if (filter === wasteTypes.find(wt => wt.name !== 'line').id) {
          dataKey = foodName
          foodData = waste
        } else {
          switch (scope) {
            case 'courses':
              dataKey = t(course)
              break
            case 'foods':
              dataKey = foodName
              break
            case 'euro':
              dataKey = foodName
              break
            default:
              dataKey = foodName
          }
          switch (filter) {
            case 'prepared':
              foodData = prepared
              break
            case 'consumption':
              foodData = consumption
              break
            default:
              foodData = waste
          }
        }

        let foodPrice = 0
        if (scope === 'euro') {
          if (foodId !== null) {
            let prices = foodPrices.filter(fp => fp.food === foodId)
            if (prices.length > 0) {
              foodPrice = prices[prices.length - 1].price
            }
          }
        }


        if (acc.data[date]) {
          if (acc.data[date][dataKey]) {
            if (scope === 'euro') {
              acc.data[date][dataKey] = (parseFloat(acc.data[date][dataKey]) + parseFloat(foodPrice * foodData)).toFixed(2)
            } else {
              acc.data[date][dataKey] = parseFloat((acc.data[date][dataKey] + parseFloat(foodData.toFixed(1))).toFixed(2))
            }
          } else {
            if (scope === 'euro') {
              acc.data[date] = {
                ...acc.data[date],
                [dataKey]: (foodPrice * foodData).toFixed(2),
              }
            } else {
              acc.data[date] = {
                ...acc.data[date],
                [dataKey]: parseFloat(foodData.toFixed(1)),
              }
            }
          }

          acc.allFoodNames.add(foodName)

          return acc
        }

        let foodType = dataKey

        if (scope === 'euro') {
          acc.data[date] = { [foodType]: (foodPrice * foodData).toFixed(2) }
        } else {
          acc.data[date] = { [foodType]: parseFloat(foodData.toFixed(2)) }
        }
        acc.allFoodNames.add(foodName)

        return acc
      },
      { data: {}, allFoodNames: new Set() }
    )

    const data = Object.entries(groupedByDate.data).map(([date, foodData]) => ({
      date,
      ...foodData,
    }))

    const { allFoodNames } = groupedByDate
    setChartData({ data, allFoodNames })

    const servings =
      mealServings &&
      mealServingIdsBetweenDates &&
      mealServings.filter(td => mealServingIdsBetweenDates.indexOf(td.id) !== -1)

    setChartServings(servings)
  }, [
    mealServingLogs,
    mealServings,
    selectedRestaurants,
    foodPrices,
    startDate,
    endDate,
    filter,
    filterType,
    wasteTypes,
    courses,
    scope,
    selectedFoods,
  ])

  const bars = () => {
    if (filter === wasteTypes.find(wt => wt.name === 'plate').id || filter === wasteTypes.find(wt => wt.name === 'Keittiöhävikki').id) {
      return [...chartData.allFoodNames].map((foodName, i) => {
        const color = COLORS[i % COLORS.length]

        return (
          <Bar key={foodName} dataKey={foodName} stackId="a" fill={color} />
        )
      })
    } else {
      if (scope === 'courses') {
        return courses
          .map(c => c.name)
          .map((courseName, i) => {
            const color = COLORS[i % COLORS.length]

            return (
              <Bar
                key={courseName}
                dataKey={t(courseName)}
                stackId="a"
                fill={color}
              />
            )
          })
      } else {
        return [...chartData.allFoodNames].map((foodName, i) => {
          const color = COLORS[i % COLORS.length]

          return (
            <Bar
              key={foodName}
              dataKey={foodName}
              stackId="a"
              fill={color}
            />
          )
        })
      }
    }

    return []
  }

  // Dirty hacks to avoid JS mutating sort...
  const sortFn = list =>
    [...list].sort((a, b) => {
      if (a.date.toLowerCase() > b.date.toLowerCase()) return 1
      if (a.date.toLowerCase() < b.date.toLowerCase()) return -1
      return 0
    })

  const generateDateWasteRows = () => {
    const data =
      tableData &&
      tableData.filter(td => td.date === selectedDate).map(td => td.data)

    const rowDatas = groupBy(data, e => e[4])

    return (
      rowDatas &&
      Object.entries(rowDatas).map(([course, rows]) => (
        <div className="column is-one-quarter" key={course}>
          <div
            className="column is-size-3  has-text-primary"
            style={{ textAlign: 'left' }}
          >
            {course}
          </div>
          {rows
            .map(r => r[0])
            .reduce((acc, curr) => {
              if (!acc.includes(curr)) {
                return acc.concat([curr])
              }
              return acc
            }, [])
            .map(r => {
              if (r === 'lautashävikki') return null
              return (
                <div
                  className="column is-size-4"
                  style={{ textAlign: 'left', textDecoration: 'underline' }}
                  key={`${r}${Math.floor(Math.random() * 1000)}`}
                >
                  <div
                    style={{ cursor: 'pointer' }}
                    role="button"
                    onClick={() => addSelectedFoods(r)}
                    onKeyPress={() => addSelectedFoods(r)}
                    tabIndex={0}
                  >
                    {r}
                  </div>
                </div>
              )
            })
            .filter(r => r)}
        </div>
      ))
    )
  }

  const daysWithServingNotesHtml = () => {
    let datesWithNotesHtml = []
    let datesWithNotes = []
    chartServings.forEach((element) => {
      let date = moment(element.servingDatetime).format('YYYY-MM-DD')
      if (typeof datesWithNotes.find(dwn => dwn.label === moment(date).format('ddd DD.MM.')) === 'undefined' && chartDates.indexOf(date) !== -1) {
        datesWithNotes.push({
          label: moment(date).format('ddd DD.MM.'),
          dateTime: element.servingDatetime,
        })
      }
    })
    datesWithNotes.sort((a,b) => moment(new Date(a.dateTime) - moment(new Date(b.dateTime))))
    datesWithNotes.forEach((element, index) => {
      datesWithNotesHtml.push(<button key={'noteDate'+index} type="button" style={{margin: '4px', borderWidth: '2px'}} className={'button is-uppercase is-rounded' + (selectedChartServing === element.label ? ' is-primary' : '') + (noteDates.indexOf(element.label) !== -1 ? ' has-note' : '')} onClick={() => {
        if (selectedChartServing !== element.label) {
          setSelectedChartServing(element.label)
        } else {
          setSelectedChartServing('')
        }
      }}>{ element.label }</button>)
    })

    return (
      <div>
        { datesWithNotesHtml }
      </div>
    )
  }

  const servingNotesHtml = () => {
    let notes = []
    let noteRestaurants = []
    let customerAmount = []
    chartServings.forEach(element => {
      let date = moment(element.servingDatetime).format('ddd DD.MM.')
      if (date === selectedChartServing) {
        let restaurantIndex = noteRestaurants.indexOf(element.restaurant)
        if (restaurantIndex === -1) {
          let restaurantName = element.restaurant
          noteRestaurants.push(restaurantName)
          customerAmount.push(element.customers)
          notes.push([])
          if (element.servingNotes !== '' && element.servingNotes !== null) {
            notes[(noteRestaurants.length - 1)].push(element.servingNotes)
          }
        } else if (element.servingNotes !== '' && element.servingNotes !== null) {
          notes[restaurantIndex].push(element.servingNotes)
        }
      }
    })
    let html = []
    noteRestaurants.forEach((element, index) => {
      html.push(<div key={'noteRestaurant'+index}><b>{restaurants.filter(r => r.id === element)[0].name}</b></div>)
      html.push(<div key={'customerAmount'+index}>{t('Asiakasmäärä')}: {customerAmount[index]}</div>)
      if (notes[index].length > 0) {
        notes[index].forEach((noteElement, noteIndex) => {
          html.push(<li key={'servingNote'+index+'_'+noteIndex} style={{color: 'red'}}>{noteElement}</li>)
        })
      }
      html.push(<br key={'br'+index} />)
    })

    return (
      <div>
        { html }
      </div>
    )
  }

  const filterButtons = () => {
    let html = []

    wasteTypes.forEach(function (wt) {
      let name = wt.name
      if (name === 'line') {
        name = 'Linjastohävikki'
      } else if (name === 'plate') {
        name = 'Lautashävikki'
      }
      html.push(
        <button
          key={wt.id}
          type="button"
          className={`button is-small is-rounded is-uppercase ${
            filter === wt.id ? 'is-primary' : null
          }`}
          onClick={() => {
            setFilterType('waste')
            setFilter(wt.id)
          }}
          style={{ margin: '5px' }}
        >
          <Trans>{name}</Trans>
        </button>
      )
    })

    mealTypes.forEach(function (mt) {
      let name = mt.name
      html.push(
        <button
          key={mt.id}
          type="button"
          className={`button is-small is-rounded is-uppercase ${
            filter === mt.id ? 'is-primary' : null
          }`}
          onClick={() => {
            setFilterType('meal')
            setFilter(mt.id)
          }}
          style={{ margin: '5px' }}
        >
          <Trans>{name}</Trans>
        </button>
      )
    })

    return html
  }

  if (!restaurants || !mealServings || !mealServingLogs || !wasteTypes || !mealTypes || !orgFoods || !foodPrices || !courses || !hukkaProfiles) {
    return (<p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>)
  }

  return (
    <div className="waste-reports">
      <h1 className="title is-1 is-size-3-mobile is-uppercase">
        <Trans>Hävikin seuranta</Trans>
      </h1>

      <DateRangePicker
        startDate={startDate}
        startDateId="reportWasteStartDate"
        endDate={endDate}
        endDateId="reportWasteEndDate"
        onDatesChange={({ startDate: sd, endDate: ed }) => {
          setFetch(true)
          if (new Date(moment(sd).format()) < new Date(moment(ed).format())) {
            setEndDate(ed)
            setStartDate(sd)
          } else {
            setEndDate(sd)
            setStartDate(sd)
          }
        }}
        focusedInput={focusedInput}
        onFocusChange={newFocusedInput => setFocusedInput(newFocusedInput)}
        block
        orientation={datePickerOrientation}
        withPortal
        displayFormat="DD.MM.YYYY"
        isOutsideRange={() => false}
      />

      <div
        style={{marginBottom: '30px'}}
      />

      {restaurantGroupSelect}

      <div className="field" ref={chartRef}>
        <div className="control">
          <div className="select is-fullwidth hukkaselect">
            <HukkaSelect
              options={getRestaurantOptions()}
              placeholder={t('Valitse ravintola')}
              value={selectedRestaurants}
              onChange={e => {
                if (e && e.find(r => r.value === 'allWaste')) {
                  setSelectedRestaurants(getRestaurantOptions().filter(ro => ro.value !== 'allWaste'))
                } else {
                  setSelectedRestaurants(e)
                }
              }}
              isMulti
            />
          </div>
        </div>
      </div>

      <div className="field" ref={chartRef}>
        <div className="control">
          <div className="select is-fullwidth hukkaselect">
            <HukkaSelect
              options={getFoodOptions()}
              placeholder={t('Valitse ruoka')}
              value={selectedFoods}
              onChange={e => setSelectedFoods(e)}
              isMulti
            />
          </div>
        </div>
      </div>

      {loading && (
        <p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>
      )}

      {!loading && chartData && chartData.data && selectedRestaurants && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <div>
              <h3 className="title is-3 is-uppercase"><Trans>Suodata</Trans></h3>
              <div>
                <button
                  type="button"
                  className={`button is-small is-rounded is-uppercase ${
                    filter === 'allWaste' ? 'is-primary' : null
                  }`}
                  onClick={() => {
                    setFilter('allWaste')
                    setFilterType('')
                  }}
                  style={{ margin: '5px' }}
                >
                  <Trans>Kaikki hävikki</Trans>
                </button>

                <button
                  type="button"
                  className={`button is-small is-rounded is-uppercase ${
                    filter === 'prepared' ? 'is-primary' : null
                  }`}
                  onClick={() => {
                    setFilter('prepared')
                    setFilterType('')
                  }}
                  style={{ margin: '5px' }}
                >
                  <Trans>Tarjoilumäärä</Trans>
                </button>

                <button
                  type="button"
                  className={`button is-small is-rounded is-uppercase ${
                    filter === 'consumption' ? 'is-primary' : null
                  }`}
                  style={{ margin: '5px' }}
                  onClick={() => {
                    setFilter('consumption')
                    setFilterType('')
                  }}
                >
                  <Trans>Menekki</Trans>
                </button>

                {filterButtons()}
              </div>
            </div>

            <div>
              <h3 className="title is-3 is-uppercase"><Trans>Näytä</Trans></h3>
              <div>
                <button
                  type="button"
                  className={`button is-small is-rounded is-uppercase ${
                    scope === 'foods' ? 'is-primary' : null
                  }`}
                  onClick={() => setScope('foods')}
                  style={{ margin: '5px' }}
                >
                  <Trans>Ruokanimikkeet</Trans>
                </button>

                <button
                  type="button"
                  className={`button is-small is-rounded is-uppercase ${
                    scope === 'courses' ? 'is-primary' : null
                  }`}
                  onClick={() => setScope('courses')}
                  style={{ margin: '5px' }}
                >
                  <Trans>Aterian osat</Trans>
                </button>

                <button
                  type="button"
                  className={`button is-small is-rounded is-uppercase ${
                    scope === 'euro' ? 'is-primary' : null
                  }`}
                  onClick={() => setScope('euro')}
                  style={{ margin: '5px' }}
                >
                  <Trans>€</Trans>
                </button>
              </div>
            </div>
          </div>

          <ResponsiveContainer width="99%" height={500} minHeight={500}>
            <BarChart
              data={sortFn(chartData.data)}
              margin={{
                top: 40,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              onClick={columnProps => {
                if (columnProps) {
                  setSelectedDate(columnProps.activeLabel)
                  document.getElementById('waste-report').scrollIntoView({behavior: 'smooth'})
                }
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={label => moment(label).format('DD.MM.') } />
              <YAxis tickFormatter={label => scope === 'euro' ? (label+' €') : (label+' kg')} />
              <Tooltip formatter={label => scope === 'euro' ? (label+' €') : (label+' kg')} />
              {bars()}
              <Legend wrapperStyle={{ top: 500, left: 25 }} />
            </BarChart>
          </ResponsiveContainer>

          {chartData && chartData.data.length === 0 && (
            <div className="is-large" style={{ marginTop: '15px' }}>
              <Trans>Ei hävikkiä</Trans>
            </div>
          )}

          <div className="box" style={{ marginTop: '300px', position: 'relative', zIndex: '5' }}>
            <div className="column title is-size-3 is-marginless is-paddingless">
              <Trans>Asiakasmäärät ja Huomiot</Trans>
            </div>
            <p style={{marginBottom: '20px'}}><i><Trans>Punaisella ympyröidyt päivämäärät sisältävät huomioita</Trans></i></p>
            <div>
              { daysWithServingNotesHtml() }
            </div>
            <br />
            { servingNotesHtml() }
          </div>

          <ReportTable
            tableData={tableData}
            customers={totalCustomers}
            startDate={startDate}
            endDate={endDate}
          />

          <br /><br />

          <div id="waste-report">
          {selectedDate && (
            <div className="box">
              <div className="column title is-size-2 is-marginless is-paddingless">
                <Trans>Hävikki</Trans> {selectedDate}
              </div>

              <div className="columns is-mobile is-multiline">
                {generateDateWasteRows()}
              </div>
            </div>
          )}
          </div>

          <WasteReportTable
            tableData={tableData}
            lineWaste={wasteTypes.find(wt => wt.name === 'line').id}
            foods={orgFoods}
            startDate={startDate}
            endDate={endDate}
          />
        </>
      )}
    </div>
  )
}

export default WasteReport

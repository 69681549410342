import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { HUKKAPROFILES_URL, RESTAURANTS_URL, MEALSERVINGS_URL, GENERICSURVEYS_URL, GENERICSURVEYLINES_URL, post, del, get, patch, login } from '../../utility/api'
import { renewMealServings, updateHukkaProfiles, removeFromHukkaProfiles, updateRestaurants, loginRequestSuccess } from '../../actions'
import moment from 'moment'
import HukkaSelect from '../hukkaSelect'
import ErrorMessage from '../errorMessage'

const AddonsPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    hukkaProfiles,
    restaurants,
    hukkaServices,
    hukkaServiceSubscriptions,
    mealServings,
    genericSurveys,
    genericSurveyLines
  } = useSelector(state => state)
  const { loggedIn, orgAdmin, rou:readOnlyUser } = useSelector(state => state.user) || { loggedIn: false, readOnlyUser: false }
  const [restaurantId, setRestaurantId] = useState(null)
  const [fetchedData, setFetchedData] = useState([])
  const [error, setError] = useState(false)

  const restaurantOptions =
    restaurants &&
    restaurants.slice()
    .sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    .map(r => ({
      value: r.id,
      label: r.name,
    }))

  const getMealServings = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServings(resp.data))
      let fetchedDataArr = fetchedData
      fetchedDataArr.push('mealServings')
      setFetchedData(fetchedDataArr)
    }
  }

  const loginAsPlatewasteUser = async () => {
    let currentDayServings = mealServings.filter(ms => moment(ms.servingDatetime).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY') && ms.restaurant === restaurantId)
    let missingSurveys = []
    let surveyId = ''
    let defaultTitle = t('Miten onnistuimme tänään?')

    genericSurveys.forEach(function (gs) {
      if (gs.restaurant === restaurantId && moment(gs.validUntil).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY')) {
        surveyId = gs.id
      }
    })

    if (surveyId === '') {
      const response = await post(GENERICSURVEYS_URL, {
        valid_until: moment(new Date().setHours(23,59,59,999)),
        restaurant: restaurantId
      })

      if (response.status === 201) {
        currentDayServings.forEach(function (cds) {
          missingSurveys.push(cds.id)
        })

        await Promise.all(
          missingSurveys.map(ms => post(GENERICSURVEYLINES_URL, {
            survey: response.data.id,
            related_serving: ms,
            description: defaultTitle  
          }))
        )
      }
    } else {
      currentDayServings.forEach(function (cds) {
        let hasSurveyLine = false
        genericSurveyLines.forEach(function (gsl) {
          if(cds.id === gsl.relatedServing) {
            hasSurveyLine = true
          }
        })
        if (!hasSurveyLine) {
          missingSurveys.push(cds.id)
        }
      })

      await Promise.all(
        missingSurveys.map(ms => post(GENERICSURVEYLINES_URL, {
          survey: surveyId,
          related_serving: ms,
          description: defaultTitle  
        }))
      )
    }


    let roUsername = restaurants.find(r => r.id === restaurantId).roUsername
    let roPassword = restaurants.find(r => r.id === restaurantId).roPassword

    const resp = await login(roUsername, roPassword)
    if (resp.status === 200) {
      dispatch(loginRequestSuccess(resp))
      window.location.reload()
    } else {
      setError('Virhe! Lautashävikkikäyttäjää ei ehkä ole luotu tälle ravintolalle. Organisaatiosi ylläpitäjä voi luoda käyttäjän tälle ravintolalle omilla tunnuksillaan Lautashävikki -sivulla.')
    }
  }

  const createPlateWasteUser = async () => {
    var pwString           = '';
    var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < 6; i++ ) {
        pwString += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    let payload = {
      username: (restaurants.find(r => r.id === restaurantId).name).toLowerCase().replace(/\s/g, '') + Math.floor(Math.random() * Math.floor(99)),
      password: pwString,
      rou: 1,
      is_staff: 0,
      org_admin: false,
      org: restaurants.find(r => r.id === restaurantId).org,
      email: '',
      first_name: '',
      last_name: '',
      restaurants: [restaurantId]
    }

    let existingProfile = hukkaProfiles.find(hp => hp.username === restaurants.find(r => r.id === restaurantId).roUsername)
    if (existingProfile) {
      const deleteResp = await del(HUKKAPROFILES_URL, existingProfile.id)
      if (deleteResp.status === 204) {
        dispatch(removeFromHukkaProfiles(deleteResp.data))
      }
    }
    const resp = await post(HUKKAPROFILES_URL, payload)
    
    if (resp.status === 201) {
      dispatch(updateHukkaProfiles(resp.data))

      const resp2 = await patch(RESTAURANTS_URL, restaurantId, { ro_username: payload.username, ro_password: payload.password })

      if (resp2.status === 200) {
        dispatch(updateRestaurants(resp2.data))
      } else {
        setError(resp2.data)
      }
    } else {
      setError(resp.data.detail)
    }
  }

  const availableServices = (resId) => {
    let rId = resId ? resId : restaurantId
    let html = []
    let serviceSubs = hukkaServiceSubscriptions.filter(hss => hss.restaurant === rId)
    serviceSubs.forEach(function (ss, index) {
      if (moment().diff(ss.validUntil, 'seconds') < 0) {
        html.push(<li key={'sub'+index}>{t(hukkaServices.find(hs => hs.id === ss.service).name)}</li>)
      }
    })

    return (
      <ul>{html}</ul>
    )
  }

  const nonReadOnlyView = () => {
    return (
      <div>
        <div>
          <h2 className="title is-2 is-size-4-mobile is-uppercase" style={{lineHeight: '1', marginTop: '-30px'}}>{t('Näkymien seurantasivut')}</h2>
          <Link
            to="/surveys"
          >
            <button
              type="button"
              className={'button is-small'}
              style={{margin: '5px'}}
            >
              <Trans>Kyselyt</Trans>
            </button>
          </Link>
        </div>

        <br /><br /><br />

        <h2 className="title is-2 is-size-4-mobile is-uppercase" style={{lineHeight: '1'}}>{t('Näkymiin kirjautuminen')}</h2>

        <div className="select is-large is-fullwidth hukkaselect">
          <HukkaSelect
            options={restaurantOptions}
            value={restaurantOptions.find(
              ro => ro.value === restaurantId
            )}
            placeholder={t('Valitse ravintola')}
            onChange={e => {
              getMealServings(moment().format('YYYY-MM-DD'), moment().clone().add(1, 'days').format('YYYY-MM-DD'))
              setError(null)
              setRestaurantId(e.value)
            }}
          />
          {error && (
            <div style={{marginTop: '10px'}}>
              <ErrorMessage msg={error} />
            </div>
          )}
        </div>
        { restaurantId === null && (
          <div style={{paddingBottom: '250px'}}></div>
        )}
        {
          restaurantId !== null && (
            <div>
              <h1 className="title is-1 is-size-3-mobile is-uppercase" style={{lineHeight: '1.5'}}>{restaurants && restaurants.find(r => r.id === restaurantId) && restaurants.find(r => r.id === restaurantId).name}</h1>
              <h2 className="title is-2 is-size-4-mobile is-uppercase" style={{lineHeight: '1'}}>{t('Käytössä olevat palvelut')}</h2>
              { availableServices() }

              <br />
              <h2 className="title is-2 is-size-4-mobile is-uppercase" style={{lineHeight: '1'}}>{t('Tunnukset')}</h2>
              {(orgAdmin && restaurants && restaurants.find(r => r.id === restaurantId) && (restaurants.find(r => r.id === restaurantId).roUsername === null)) && (
                <div>
                  <p>{t('Luo ravintolalle lautashävikkikäyttäjä klikkaamalla Luo -painiketta')}</p>
                  <button
                    className="button is-green"
                    onClick={() => createPlateWasteUser()}
                  >
                    <Trans>Luo</Trans>
                  </button>
                </div>
              )}
              {(!orgAdmin || (restaurants && restaurants.find(r => r.id === restaurantId) && restaurants.find(r => r.id === restaurantId).roUsername !== null)) && (
                <div>
                  <div>{t('Käyttäjä')}: {restaurants.find(r => r.id === restaurantId).roUsername}</div>
                  <div>{t('Salasana')}: {restaurants.find(r => r.id === restaurantId).roPassword}</div>
                  <br /><br />
                  <button
                    className="button is-green"
                    onClick={() => loginAsPlatewasteUser()}
                  >
                    {t('Kirjaudu Taikametsään/Kyselyyn')}
                  </button>
                  { orgAdmin && (
                    <>
                    <br /><br />
                    <button
                      className="button is-green"
                      onClick={() => createPlateWasteUser()}
                    >
                      {t('Generoi tunnus uudelleen')}
                    </button>
                    </>
                  )}
                </div>
              )}
            </div>
          )
        }
      </div>
    )
  }

  const readOnlyView = () => {
    return (<div>
      {
        restaurants && restaurants.length > 0 && (
          <div>
            <h1 className="title is-1 is-size-3-mobile is-uppercase" style={{lineHeight: '1.5'}}>{restaurants[0] && restaurants[0].name}</h1>
            <h2 className="title is-2 is-size-4-mobile is-uppercase" style={{lineHeight: '1'}}>{t('Käytössä olevat palvelut')}</h2>
            { availableServices(restaurants[0].id) }
          </div>
        )
      }
    </div>)
  }

  return (
    <div className="addons">
      <div>
        <h1 className="title is-1 is-size-3-mobile is-uppercase"><Trans>Asiakasnäkymät</Trans></h1>
        {!hukkaProfiles && (<p className="is-size-2 is-size-3-mobile is-uppercase">{t('Ladataan')}...</p>)}
        {loggedIn && hukkaProfiles && hukkaServices && hukkaServiceSubscriptions && !readOnlyUser ? nonReadOnlyView() : ''}
        {loggedIn && hukkaProfiles && hukkaServices && hukkaServiceSubscriptions && readOnlyUser ? readOnlyView() : ''}
      </div>
    </div>
  )
}

export default AddonsPage

import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import noSleep from 'nosleep.js'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import MagicForestView from './magicForestView'
import ErrorMessage from '../errorMessage'

const MagicForest = () => {
  const {
    restaurants,
    hukkaProfiles,
    hukkaServices,
    hukkaServiceSubscriptions,
    avgWaste
  } = useSelector(state => state)
  const { t } = useTranslation()
  const [scaleValue, setScaleValue] = useState(0)
  const [scaleConnected, setScaleConnected] = useState(false)
  const [error, setError] = useState(null)
  const chartRef = useRef(null)
  const { loggedIn, rou:readOnlyUser, token } = useSelector(state => state.user) || { loggedIn: false, readOnlyUser: false }

  let scaleCheckValue = null
  let scaleComparisonValue = null
  let scaleTimeout = null

  const targetService = '0000ffe0-0000-1000-8000-00805f9b34fb'
	const targetChar = '0000ffe1-0000-1000-8000-00805f9b34fb'
  
  let noSleepObj = new noSleep()

  const fullscreen = () => {
    let elem = document.getElementById('fullscreenElement')
    elem.style.display = 'block'

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem = window.top.document.body; //To break out of frame in IE
      elem.msRequestFullscreen();
    }
    
    noSleepObj.enable()

    try {
      document.getElementsByClassName('magicForest_video').forEach(function (elem) {
        elem.load()
      })
    } catch {}
  }

  const connectScale = () => {
    try {
      navigator.bluetooth.requestDevice({
        filters: [{services: [targetService]}]
      })
      .then(device => {
        return device.gatt.connect();
      })
      .then(server => {
        return server.getPrimaryService(targetService);
      })
      .then(service => {
        return service.getCharacteristic(targetChar);
      })
      .then(characteristic => {
        characteristic.addEventListener('characteristicvaluechanged', handleNotifications);
        characteristic.startNotifications()
        setScaleConnected(true)
        fullscreen()
        return characteristic
      })
      .catch(error => {
        console.log('Argh! ' + error);
      });
    } catch (e) {
      console.log({e})
      setError(t('Tapahtui virhe.') + ' ' + t('Selaimesi ei ehkä tue Web Bluetoothia.'))
    }
  }

  const handleNotifications = (event) => {
    let decode = new TextDecoder().decode(event.target.value)
    decode = decode.split(' ')[1].split('kg')[0]
    if (avgWaste && scaleCheckValue !== null && scaleCheckValue !== decode) {
      clearTimeout(scaleTimeout)
      if (document.getElementById('magicForestPosReview') && document.getElementById('magicForestNegReview')) {
        scaleTimeout = setTimeout(function () {
          let wasteAmount = parseFloat(scaleCheckValue) - parseFloat(scaleComparisonValue)
          if (wasteAmount > 0.01) {
            let avgWasteValue = parseFloat(avgWaste.plateAvg) / 1000 / parseFloat(avgWaste.avgCustomers)
            if (wasteAmount > avgWasteValue) {
              document.getElementById('magicForestPosReview').load()
              document.getElementById('magicForestNegReview').play()
              document.getElementById('magicForestWasteAmount').innerHTML = wasteAmount.toFixed(2) + ' kg'
              document.getElementById('magicForestWasteAmount').classList.add('magicForest__wasteAmount--negative')
              setTimeout(function () {
                document.getElementById('magicForestWasteAmount').classList.remove('magicForest__wasteAmount--negative')
              }, 5000)
            } else {
              document.getElementById('magicForestNegReview').load()
              document.getElementById('magicForestPosReview').play()
              document.getElementById('magicForestWasteAmount').innerHTML = wasteAmount.toFixed(2) + ' kg'
              document.getElementById('magicForestWasteAmount').classList.add('magicForest__wasteAmount--positive')
              setTimeout(function () {
                document.getElementById('magicForestWasteAmount').classList.remove('magicForest__wasteAmount--positive')
              }, 5000)
            }
          }
          scaleComparisonValue = scaleCheckValue
        }, 3000)
      }
    }

    if (scaleComparisonValue === null) {
      scaleComparisonValue = decode
    }
    scaleCheckValue = decode
    setScaleValue(decode)
  }

  const view = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '85vh',
      }}
      ref={chartRef}
    >
      <div style={{width: '100%'}}>
        <div className="title is-3 uppercase" style={{ margin: 0 }}>
          {restaurants && restaurants[0].name}
        </div>
        <br />
      </div>
      {error && (
        <div style={{margin: '10px 0'}}>
          <ErrorMessage msg={error} />
        </div>
      )}
      <button
        type="button"
        className='hukkaBtn'
        onClick={() => {
          if (scaleConnected) {
            fullscreen()
          } else {
            connectScale()
          }
        }}
      >
        Käynnistä Taikametsä
      </button>

      <br />
      <br />

      <button
        type="button"
        className='hukkaBtn'
        onClick={() => {
          fullscreen()
        }}
      >
        Käynnistä ilman vaakaa
      </button>

        <br />
        <br />
        
        <div>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              let copyText = 'https://' + window.location.hostname + '/magic-forest-view?token=' + token
              document.addEventListener('copy', function(e) {
                e.clipboardData.setData('text/plain', copyText);
                e.preventDefault();
              }, true);
              document.execCommand('copy');
            }}
          >
            {t('Kopioi suora url infonäyttöjä varten')}
          </button>
        </div>

      <br />

      <div id="fullscreenElement" style={{position: 'fixed', top: '-200%', display: 'none'}}>
        <MagicForestView scaleValue={scaleValue} />
      </div>
    </div>
  )

  const nonReadOnlyView = () => {
    return (<div></div>)
  }

  const showMagicForestView = () => {
    let hasMagicForestService = false
    if (hukkaServices && hukkaServiceSubscriptions) {
      let magicForestService = hukkaServices.find(hs => (hs.name === 'Taikametsä'))
      if (hukkaServiceSubscriptions.find(hss => hss.service === magicForestService.id && moment(new Date()) < moment(hss.validUntil))) {
        hasMagicForestService = true
      }
    }
    return hasMagicForestService ? view() : (<p className="is-size-2 is-size-3-mobile is-uppercase" style={{marginTop: '30px'}}>{t('Hei! Valitettavasti sinulla ei ole oikeuksia tämän palvelun käyttöön, mutta jos haluat tietää lisää, niin ota yhteyttä mailitse')}:<br /><a href="mailto:asiakaspalvelu@hukka.ai">asiakaspalvelu@hukka.ai</a></p>)
  }


  /* General */

  return (
    <>
      {!hukkaProfiles && (<p className="is-size-2 is-size-3-mobile is-uppercase">{t('Ladataan')}...</p>)}
      {loggedIn && hukkaProfiles && hukkaServices && hukkaServiceSubscriptions && !readOnlyUser ? nonReadOnlyView() : ''}
      {loggedIn && hukkaProfiles && hukkaServices && hukkaServiceSubscriptions && readOnlyUser ? showMagicForestView() : ''}
    </>
  )
}

export default MagicForest

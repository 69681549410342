import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import HukkaSelect from '../hukkaSelect'
import { patch, RESTAURANTS_URL, del, post } from '../../utility/api'
import { updateRestaurants, removeFromRestaurants } from '../../actions'
import ErrorMessage from '../errorMessage'

const Restaurants = () => {
  const dispatch = useDispatch()
  const { restaurants, orgs, mealTypes, restaurantTypes } = useSelector(state => state)
  const [search, setSearch] = useState('')
  const [selectedOrgs, setSelectedOrgs] = useState(null)
  const [restaurantInEdit, setRestaurantInEdit] = useState(null)
  const [newRestaurant, setNewRestaurant] = useState(null)
  const [restaurantToDelete, setRestaurantToDelete] = useState(null)
  const [errors, setErrors] = useState({})
  const { t } = useTranslation()

  const orgOptions =
    orgs &&
    orgs
      .map(r => ({
        value: r.id,
        label: r.name,
      }))
      .concat([{ value: 'all', label: 'Lisää kaikki organisaatiot' }])

  const handleRestaurantDelete = async () => {
    const { id } = restaurantToDelete
    const resp = await del(RESTAURANTS_URL, id)

    if (resp.status === 204) {
      dispatch(removeFromRestaurants(id))
      setRestaurantToDelete(null)
    } else {
      setErrors({ delete: resp.data })
      setRestaurantToDelete(null)
    }
  }

  const handleRestaurantEdit = async () => {
    setErrors(null)
    const { id, name, org, municipality, mealType, restaurantType, defaultCustomerAmount, menuUrl, allowPublicInsights } = restaurantInEdit
    const resp = await patch(RESTAURANTS_URL, id, {
      name,
      org,
      municipality,
      restaurant_type: restaurantType,
      meal_type: mealType,
      default_customer_amount: defaultCustomerAmount,
      menu_url: menuUrl,
      allow_public_insights: allowPublicInsights
    })

    if (resp.status === 200) {
      setRestaurantInEdit(null)
      dispatch(updateRestaurants(resp.data))
    } else {
      let errorString = ''
      Object.keys(resp.data).forEach(function (key) {
        errorString += key + ': ' + resp.data[key] + ' - '
      })
      setErrors({ edit: errorString })
    }
  }

  const handleNewRestaurant = async () => {
    setErrors(null)
    const { name, municipality, org, restaurantType, mealType, defaultCustomerAmount, menuUrl, allowPublicInsights } = newRestaurant
    const validated = !!name && !!municipality && !!org && !!restaurantType

    if (validated) {
      const resp = await post(RESTAURANTS_URL, {
        name,
        municipality,
        org,
        restaurant_type: restaurantType,
        meal_type: mealType,
        default_customer_amount: defaultCustomerAmount,
        menu_url: menuUrl,
        allow_public_insights: allowPublicInsights
      })

      if (resp.status === 201) {
        dispatch(updateRestaurants(resp.data))
        setNewRestaurant(null)
      } else {
        let errorString = ''
        Object.keys(resp.data).forEach(function (key) {
          errorString += key + ': ' + resp.data[key] + ' - '
        })
        setErrors({ newRestaurant: errorString })
      }
    } else {
      setErrors({ newRestaurant: t('Täytä kaikki kentät') })
    }
  }

  const restaurantRows = () => {
    const selectedOrgsIds =
      orgs && orgs.length === 1
        ? [orgs[0].id]
        : selectedOrgs && selectedOrgs.map(o => o.id)

    const relatedRestaurants = selectedOrgsIds ? restaurants.filter(r =>
      selectedOrgsIds.includes(r.org)
    ).sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    }) : []

    return relatedRestaurants
      .filter(r => search === '' || r.name.toLowerCase().includes(search.toLowerCase()))
      .sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      })
      .map(r => (
        <div key={r.id} className="level levelRow">
          <div className="level-left">
            <div className="is-fullwidth has-text-left-desktop has-text-dark">
              <h4 className="subtitle is-4 is-marginless has-text-primary levelRowTitle">
                <i
                  className="fas fa-utensils"
                  style={{ marginRight: '15px' }}
                />
                <br className="is-hidden-desktop" />
                {r.name}
              </h4>
            </div>
          </div>
          <div className="level-right">
            <button
              type="button"
              className="button is-green is-large"
              onClick={() => {
                setRestaurantInEdit(
                  restaurants.find(restaurant => r.id === restaurant.id)
                )
              }}
            >
              <Trans>Muokkaa</Trans>
            </button>
            <button
              type="button"
              className="button is-danger is-large"
              onClick={() => setRestaurantToDelete(r)}
            >
              <Trans>Poista</Trans>
            </button>
          </div>
        </div>
      ))
  }

  const mealTypeOptions = () => {
    let html = []
    if (restaurantInEdit) {
      mealTypes.forEach((element, index) => {
        html.push(
          <div className="meal-types__item" key={'mealType'+index}>
            <span>{t(element.name)}</span>
            <button
              type="button"
              className={"button is-large"+(restaurantInEdit && restaurantInEdit.mealType.indexOf(element.id) === -1 ? ' is-green' : ' is-danger')}
              onClick={() => {
                let mealTypeArr = JSON.parse(JSON.stringify(restaurantInEdit.mealType))
                let currentPos = mealTypeArr.indexOf(element.id)
                if (currentPos === -1) {
                  mealTypeArr.push(element.id)
                } else {
                  mealTypeArr.splice(currentPos, 1)
                }
                setRestaurantInEdit({
                  ...restaurantInEdit,
                  mealType: mealTypeArr
                })
              }}
            >
              {restaurantInEdit && restaurantInEdit.mealType.indexOf(element.id) === -1 ? t('Käytössä') : t('Ei käytössä')}
            </button>
          </div>
        )
      })
    } else if (newRestaurant) {
      mealTypes.forEach((element, index) => {
        html.push(
          <div className="meal-types__item" key={'mealType'+index}>
            <span>{t(element.name)}</span>
            <button
              type="button"
              className={"button is-large"+(newRestaurant && newRestaurant.mealType.indexOf(element.id) === -1 ? ' is-green' : ' is-danger')}
              onClick={() => {
                let mealTypeArr = JSON.parse(JSON.stringify(newRestaurant.mealType))
                let currentPos = mealTypeArr.indexOf(element.id)
                if (currentPos === -1) {
                  mealTypeArr.push(element.id)
                } else {
                  mealTypeArr.splice(currentPos, 1)
                }
                setNewRestaurant({
                  ...newRestaurant,
                  mealType: mealTypeArr
                })
              }}
            >
              {newRestaurant && newRestaurant.mealType.indexOf(element.id) === -1 ? t('Käytössä') : t('Ei käytössä')}
            </button>
          </div>
        )
      })
    }

    return (
      <div className="meal-types">
        <div className="label">
          <Trans>Ateriatyypit</Trans>
        </div>
        {html}
      </div>
    )
  }

  const publicInsightsOption = () => {
    let html = []
    let val = false
    if (restaurantInEdit) {
      val = restaurantInEdit.allowPublicInsights
    } else if (newRestaurant) {
      val = newRestaurant.allowPublicInsights
    }
    html.push(
      <div style={{display: 'block', textAlign: 'center'}}>
        <button
          type="button"
          className={"button is-large" + (val ? ' is-green' : ' is-danger')}
          onClick={() => {
            if (restaurantInEdit) {
              setRestaurantInEdit({
                ...restaurantInEdit,
                allowPublicInsights: !val
              })
            } else if (newRestaurant) {
              setNewRestaurant({
                ...newRestaurant,
                allowPublicInsights: !val
              })
            }
          }}
        >
          {val ? t('Käytössä') : t('Ei käytössä')}
        </button>
      </div>
    )
    
    return (
      <div className="meal-types">
        <div className="label">
          <Trans>Julkiset insightit</Trans>
        </div>
        {html}
      </div>
    )
  }

  const editRestaurantModal = (
    <div key={restaurantInEdit} className={`modal ${restaurantInEdit ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title"><Trans>Muokkaa ravintolaa</Trans></p>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label htmlFor="modal-edit-restaurant-name" className="label">
              <Trans>Ravintolan nimi</Trans>
              <input
                className="input is-large is-fullwidth"
                type="text"
                id="modal-edit-restaurant"
                value={(restaurantInEdit && restaurantInEdit.name) || ''}
                onChange={e =>
                  setRestaurantInEdit({
                    ...restaurantInEdit,
                    name: e.target.value,
                  })
                }
              />
            </label>
          </div>
          <div className="field">
            <label htmlFor="modal-edit-restaurant-org" className="label">
              <Trans>Organisaatio</Trans>
              <div className="control">
                <div className="select is-large is-fullwidth">
                  <select
                    id="modal-edit-restaurant-org"
                    value={(restaurantInEdit && restaurantInEdit.org) || ''}
                    onChange={e => {
                      setRestaurantInEdit({
                        ...restaurantInEdit,
                        org: e.target.value,
                      })
                    }}
                  >
                    {orgs &&
                      orgs.map(o => (
                        <option key={o.id} value={o.id}>
                          {o.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </label>
          </div>

          <div className="field">
            <div className="label"><Trans>Kunta</Trans></div>
            <div className="control">
              <input
                type="text"
                className="input is-large is-fullwidth"
                value={
                  (restaurantInEdit && restaurantInEdit.municipality) || ''
                }
                onChange={e =>
                  setRestaurantInEdit({
                    ...restaurantInEdit,
                    municipality: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="field">
            <div className="label"><Trans>Ravintolatyyppi</Trans></div>
            <div className="control">
              <div className="select is-large is-fullwidth">
                <select
                  id="modal-edit-restaurant-org"
                  value={(
                    (restaurantInEdit && restaurantInEdit.restaurantType) || ''
                  )}
                  placeholder={t('Valitse ravintolatyyppi')}
                  onChange={e => {
                    setRestaurantInEdit({
                        ...restaurantInEdit,
                        restaurantType: e.target.value,
                      })
                  }}
                >
                  {restaurantTypes &&
                    restaurantTypes.map(o => (
                      <option key={o.id} value={o.id}>
                        {t(o.name)}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          <div className="field">
            <label htmlFor="modal-edit-restaurant-name" className="label">
              <Trans>Ruokalistan url</Trans>
              <input
                className="input is-large is-fullwidth"
                type="text"
                id="modal-edit-restaurant"
                value={(restaurantInEdit && restaurantInEdit.menuUrl) || ''}
                onChange={e =>
                  setRestaurantInEdit({
                    ...restaurantInEdit,
                    menuUrl: e.target.value,
                  })
                }
              />
            </label>
          </div>

          <div className="field">
            <label htmlFor="modal-edit-restaurant-name" className="label">
              <Trans>Vakio asiakasmäärä</Trans>
              <input
                className="input is-large is-fullwidth"
                type="number"
                id="modal-edit-restaurant"
                value={(restaurantInEdit && restaurantInEdit.defaultCustomerAmount) || ''}
                onChange={e =>
                  setRestaurantInEdit({
                    ...restaurantInEdit,
                    defaultCustomerAmount: e.target.value,
                  })
                }
              />
            </label>
          </div>

          {mealTypeOptions()}

          {publicInsightsOption()}

        </section>
        <footer className="modal-card-foot">
        { restaurantInEdit && (
          <button
            type="button"
            className="button is-success is-large"
            onClick={handleRestaurantEdit}
            disabled={restaurantInEdit.name === '' || restaurantInEdit.org === '' || restaurantInEdit.municipality === '' || restaurantInEdit.restaurantType === ''}
          >
            <Trans>Tallenna</Trans>
          </button>
        )}
          <button
            type="button"
            className="button is-large"
            onClick={() => setRestaurantInEdit(null)}
          >
            <Trans>Peruuta</Trans>
          </button>
        </footer>
      </div>

      {errors && errors.edit && (
        <ErrorMessage msg={errors.edit} />
      )}
    </div>
  )

  const newRestaurantModal = (
    <div key={newRestaurant} className={`modal ${newRestaurant ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title"><Trans>Lisää ravintola</Trans></p>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <div className="label"><Trans>Ravintolan nimi</Trans></div>
            <div className="control">
              <input
                onChange={e =>
                  setNewRestaurant({ ...newRestaurant, name: e.target.value })
                }
                className="input is-large is-fullwidth"
                type="text"
              />
            </div>
          </div>
          <div className="field">
            <div className="label"><Trans>Organisaatio</Trans></div>
            <div className="control">
              <div className="select is-large is-fullwidth">
                <select
                  onChange={e =>
                    setNewRestaurant({
                      ...newRestaurant,
                      org: e.target.value,
                    })
                  }
                >
                  {orgs &&
                    orgs.map(o => (
                      <option key={o.id} value="o.id">
                        {o.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          <div className="field">
            <div className="label"><Trans>Kunta</Trans></div>
            <div className="control">
              <input
                type="text"
                className="input is-large is-fullwidth"
                onChange={e =>
                  setNewRestaurant({
                    ...newRestaurant,
                    municipality: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="field">
            <div className="label"><Trans>Ravintolatyyppi</Trans></div>
            <div className="control">
              <div className="select is-large is-fullwidth">
                <select
                  id="modal-edit-restaurant-org"
                  value={(
                    (newRestaurant && newRestaurant.restaurantType) || ''
                  )}
                  placeholder={t('Valitse ravintolatyyppi')}
                  onChange={e => {
                    setNewRestaurant({
                        ...newRestaurant,
                        restaurantType: e.target.value,
                      })
                  }}
                >
                  {restaurantTypes &&
                    restaurantTypes.map(o => (
                      <option key={o.id} value={o.id}>
                        {t(o.name)}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          <div className="field">
            <label htmlFor="modal-edit-restaurant-name" className="label">
              <Trans>Ruokalistan url</Trans>
              <input
                className="input is-large is-fullwidth"
                type="text"
                id="modal-edit-restaurant"
                value={(newRestaurant && newRestaurant.menuUrl) || ''}
                onChange={e =>
                  setNewRestaurant({
                    ...newRestaurant,
                    menuUrl: e.target.value,
                  })
                }
              />
            </label>
          </div>

          <div className="field">
            <label htmlFor="modal-edit-restaurant-name" className="label">
              <Trans>Vakio asiakasmäärä</Trans>
              <input
                className="input is-large is-fullwidth"
                type="number"
                id="modal-edit-restaurant"
                value={(newRestaurant && newRestaurant.defaultCustomerAmount) || ''}
                onChange={e =>
                  setNewRestaurant({
                    ...newRestaurant,
                    defaultCustomerAmount: e.target.value,
                  })
                }
              />
            </label>
          </div>

          {mealTypeOptions()}

          {publicInsightsOption()}

          { errors && errors.newRestaurant && (
            <ErrorMessage msg={errors.newRestaurant} />
          )}
        </section>
        <footer className="modal-card-foot">
          { newRestaurant && (
              <button
                type="button"
                className="button is-success is-large"
                onClick={handleNewRestaurant}
                disabled={newRestaurant.name === '' || newRestaurant.org === '' || newRestaurant.municipality === '' || newRestaurant.restaurantType === ''}
              >
                <Trans>Lisää</Trans>
              </button>
          )}
          <button
            type="button"
            className="button is-large"
            onClick={() => setNewRestaurant(null)}
          >
            <Trans>Peruuta</Trans>
          </button>
        </footer>
      </div>
    </div>
  )

  const deleteRestaurantModal = (
    <div className={`modal ${restaurantToDelete ? 'is-active' : null}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title"><Trans>Vahvista poisto</Trans></p>
        </header>
        <section className="modal-card-body">
          {t('Haluatko varmasti poistaa ravintolan') + ': ' + (restaurantToDelete && (restaurantToDelete.name ? restaurantToDelete.name : '')) + '?'}
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-success is-danger is-large"
            onClick={handleRestaurantDelete}
          >
            <Trans>Kyllä</Trans>
          </button>
          <button
            type="button"
            className="button is-large"
            onClick={() => setRestaurantToDelete(null)}
          >
            <Trans>Ei</Trans>
          </button>
        </footer>
      </div>
    </div>
  )

  return (
    <div className="restaurants">
      <h1 className="title is-1 is-size-3-mobile is-uppercase "><Trans>Ravintolat</Trans></h1>

      {orgs && orgs.length > 1 && (
        <div className="field">
          <div className="control">
            <div className="select is-large is-fullwidth hukkaselect">
              <HukkaSelect
                options={orgOptions}
                placeholder={t('Valitse organisaatio')}
                onChange={e => {
                  if (e && e.find(r => r.value === 'all')) {
                    setSelectedOrgs(orgOptions.filter(ro => ro.value !== 'all'))
                  } else {
                    setSelectedOrgs(e)
                  }
                }}
                value={selectedOrgs}
                isMulti
              />
            </div>
          </div>
        </div>
      )}

      {orgs && orgs.length === 1 && (
        <h3 className="title is-3 is-uppercase">{orgs[0].name}</h3>
      )}

      <button
        type="button"
        className="button is-large is-fullwidth is-primary"
        onClick={() => setNewRestaurant({ org: orgs[0].id, restaurantType: restaurantTypes[0].id, mealType: [], name: '', municipality: '' })}
      >
        <Trans>Lisää ravintola</Trans>
      </button>

      { errors && errors.delete && (
        <ErrorMessage msg={errors.delete} />
      )}

      <div style={{ marginTop: '60px' }}></div>

      <input
        className="input has-text-primary"
        type="text"
        placeholder={t('Hae ravintolaa') + '...'}
        value={search}
        onChange={e => {
          setSearch(e.target.value)
        }}
      />

      <div style={{ marginTop: '20px' }}>
        <div className="restaurantRows">{restaurantRows()}</div>
      </div>

      {editRestaurantModal}
      {newRestaurantModal}
      {deleteRestaurantModal}
    </div>
  )
}

export default Restaurants

import React, { useState } from 'react'
import { instanceOf, oneOfType, arrayOf, string, bool, func } from 'prop-types'
import TimeKeeper from 'react-timekeeper'
import moment from 'moment'
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'

import {
  updateAddWasteForm,
  updateMealServings,
  emptyAddWasteForm,
  renewMealServings
} from '../../actions'
import HukkaSelect from '../hukkaSelect'
import { get, post, MEALSERVINGS_URL } from '../../utility/api'

const NewMealserviceForm = ({
  date,
  restaurant,
  disabledMealTypes = [],
  isInModal,
  submitCallback,
  hideCustomerInput,
  hideNotesInput,
  fromDate,
  toDate
}) => {
  const { mealTypes, addWasteForm } = useSelector(state => state)
  const { selectedMealType } = addWasteForm || {}
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [showTimeKeeper, setShowTimeKeeper] = useState(false)
  const [selectedTime, setSelectedTime] = useState('12:00')
  const [customerAmount, setCustomerAmount] = useState('')
  const [note, setNote] = useState('')
  const [error, setError] = useState(null)

  const mealTypeOptions = mealTypes.filter(mt => disabledMealTypes.indexOf(mt.id) === -1).map(mt => ({
    label: t(mt.name),
    value: mt.id
  }))

  const getMealServings = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServings(resp.data))
    }
  }

  if (mealTypeOptions.length === 1 && !selectedMealType) {
    dispatch(updateAddWasteForm('selectedMealType', mealTypeOptions[0].value))
  }

  const postToServer = async (mealserving, restaurants) => {
    let payload = false
    let body = { ...mealserving }

    if (!hideCustomerInput) {
      body = { ...body, customers: customerAmount }
    }

    let isArray = false

    if (Array.isArray(restaurants)) {
      isArray = true
      payload = []
      restaurants.forEach(function (r) {
        let obj = body
        obj = { ...obj, restaurant: r }
        payload.push(obj)
      })
    } else {
      body = { ...body, restaurant: restaurants }
      payload = body
    }

    const resp = await post(MEALSERVINGS_URL, payload)

    if (resp.status === 201) {
      const mealServing = resp.data

      if (isArray) {
        if (fromDate && toDate) {
          getMealServings(fromDate, toDate)
        } else {
          mealServing.forEach((rd, rdIndex) => {
            dispatch(updateMealServings(rd))
          })
        }
      } else {
        dispatch(updateMealServings(mealServing))
      }


      // If we are in modal we don't want to continue add waste form
      if (isInModal) {
        dispatch(emptyAddWasteForm())
        submitCallback()
      } else {
        dispatch(updateAddWasteForm('selectedMealServing', mealServing.id))
      }
    } else {
      setError(resp.data)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const [h, m] = selectedTime.split(':')
    date.set({ h, m })

    const mealserving = {
      meal_type: selectedMealType,
      serving_datetime: date.toISOString(),
      serving_notes: note,
    }

    // this happens only if user selects multiple restaurants in menu
    // and wants to create a serving for all restaurants at once
    try {
      postToServer(mealserving, restaurant)
    } catch (e) {
      setError(t('Jotain meni pieleen') + '.' + t('Kokeile uudestaan') + '!')
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div
        className="field"
        onClick={() => {
          setShowTimeKeeper(true)
        }}
        onKeyPress={() => {
          setShowTimeKeeper(true)
        }}
        role="button"
        tabIndex="0"
      >
        <div
          className="control is-large is-primary input"
          style={{
            border: 'solid 2px',
            borderColor: '#dbdbdb',
            padding: '0 15px',
          }}
        >
          <i className="mdi mdi-clock mdi-36px" /> <span>{selectedTime}</span>
        </div>
      </div>

      {showTimeKeeper && (
        <div
          className="time-picker"
          style={{
            position: 'absolute',
            zIndex: 1,
          }}
        >
          <TimeKeeper
            hour24Mode
            onChange={newTime => setSelectedTime(newTime.formatted24)}
            switchToMinuteOnHourSelect
            onDoneClick={() => setShowTimeKeeper(false)}
            closeOnMinuteSelect
            // time={selectedTime}
            doneButton={newTime => (
              <div
                style={{ textAlign: 'center', padding: '10px 0' }}
                onClick={() => {
                  setSelectedTime(newTime.formatted24)
                  setShowTimeKeeper(false)
                }}
                onKeyPress={() => setShowTimeKeeper(false)}
                role="button"
                tabIndex="0"
              >
                <Trans>Sulje</Trans>
              </div>
            )}
          />
        </div>
      )}

      <div className="field">
        <div className="control">
          <div className="select is-large is-fullwidth hukkaselect">
            <HukkaSelect
              options={mealTypeOptions}
              placeholder={t('Valitse kattauksen tyyppi')}
              value={mealTypeOptions.find(
                mto => mto.value === selectedMealType
              )}
              onChange={e =>
                dispatch(updateAddWasteForm('selectedMealType', e.value))
              }
            />
          </div>
        </div>
      </div>

      {!hideCustomerInput && (
        <div className="control has-icons-left is-large has-text-primary is-clearfix">
          <input
            id="customerAmount"
            type="number"
            placeholder={t('Asiakasmäärä')}
            className="input is-large has-text-primary"
            value={customerAmount}
            onChange={e => setCustomerAmount(e.target.value)}
            onFocus={e => e.target.select()}
          />
          <span className="icon is-left">
            <i className="mdi mdi-user mdi-24px" />
          </span>
        </div>
      )}
      
      {!hideNotesInput && (
      <div className="field">
        <div className="control has-icons-left is-large has-text-primary is-clearfix">
          <input
            type="text"
            placeholder={t('Huomiot')}
            className="input is-large has-text-primary"
            value={note}
            onChange={e => setNote(e.target.value)}
          />
          <span className="icon is-left">
            <i className="mdi mdi-pencil mdi-24px" />
          </span>
        </div>
      </div>
      )}

      {error && (
        <div className="message is-danger">
          <div className="message-body">{Object.values(error)}</div>
        </div>
      )}

      <div>
        <button
          type="submit"
          disabled={
            !(customerAmount || hideCustomerInput) ||
            !selectedMealType ||
            !selectedTime
          }
          className="button is-large is-fullwidth is-green"
        >
          <Trans>Luo kattaus</Trans>
        </button>
      </div>
    </form>
  )
}

NewMealserviceForm.defaultProps = {
  isInModal: null,
  submitCallback: null,
  hideCustomerInput: null,
}

NewMealserviceForm.propTypes = {
  restaurant: oneOfType([string, arrayOf(string)]).isRequired,
  date: instanceOf(moment).isRequired,
  isInModal: bool,
  submitCallback: func,
  hideCustomerInput: bool,
}

export default NewMealserviceForm

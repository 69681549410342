import React from 'react'

const ErrorMessage = ({ msg = '' }) => {
  const message = (
    <div className="message is-danger">
      <div className="message-body">{msg}</div>
    </div>
  )

  return msg ? message : null
}

export default ErrorMessage

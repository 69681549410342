import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import Actions from './actions'
import Forms from './forms'
import Plans from './plans'
import History from './history'

const MonitoringIndex = () => {
	const [selectedTab, setSelectedTab] = useState('actions')
	const {orgs, restaurants, formFieldTemplates, formTemplates, fieldOrders, forms, formRows} = useSelector(
    state => state
  )
  const user = useSelector(
    state => state.user
  )
  const { t } = useTranslation()
  if (!orgs || !restaurants || !formFieldTemplates || !formTemplates || !fieldOrders || !forms || !formRows) {
    return (<p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>)
  }
  return (<div>
  	<div>
	  	<button
	      type="button"
	      className={'button is-small' + (selectedTab === 'actions' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('actions')
	      }}
	    >
	      <Trans>Toimenpiteet</Trans>
	    </button>
		{user && user.orgAdmin === true && (<>
			<button
				type="button"
				className={'button is-small' + (selectedTab === 'forms' ? ' is-primary-background' : '')}
				style={{margin: '5px'}}
				onClick={() => {
					setSelectedTab('forms')
				}}
			>
				<Trans>Lomakkeet</Trans>
			</button>
			<button
				type="button"
				className={'button is-small' + (selectedTab === 'history' ? ' is-primary-background' : '')}
				style={{margin: '5px'}}
				onClick={() => {
					setSelectedTab('history')
				}}
			>
				<Trans>Historia</Trans>
			</button>
		</>)}
		<br />
		<button
			type="button"
			className={'button is-small' + (selectedTab === 'plans' ? ' is-primary-background' : '')}
			style={{margin: '5px'}}
			onClick={() => {
				setSelectedTab('plans')
			}}
		>
			<Trans>Suunnitelma</Trans>
		</button>
  	</div>
  	<div>
  	{ selectedTab === 'actions' && (<Actions />) }
  	{ selectedTab === 'forms' && (<Forms />) }
	{ selectedTab === 'history' && (<History />) }
    { selectedTab === 'plans' && (<Plans />) }
  	</div>
  </div>)
}

export default MonitoringIndex

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Foodview = ({restaurants, courses, mealTypes, wasteTypes, tags}) => {
  const { t } = useTranslation()
  const {
    orgs,
    foodStats
  } = useSelector(state => state)
  const [sortBy, setSortBy] = useState('name')
  
  const consumedTargetPercentage = orgs && orgs[0].wasteTargetPercentage ? (100 - parseFloat(orgs[0].wasteTargetPercentage)) : 0
  const consumedAlarmPercentage = orgs && orgs[0].wasteAlarmPercentage ? (100 - parseFloat(orgs[0].wasteAlarmPercentage)) : 100

  const foodView = () => {
    let html = []
    if (restaurants && restaurants.length > 0 && foodStats && foodStats.length > 0) {
      let rows = []
      let foods = []

      let selectedCourses = courses ? courses.map(r => r.value) : []
      let selectedMealTypes = mealTypes ? mealTypes.map(r => r.value) : []
      let selectedWasteTypes = wasteTypes ? wasteTypes.map(r => r.value) : []
      let selectedTags = tags ? tags.map(r => r.value) : []

      foodStats.forEach(function (food) {
        let isIncluded = true

        if (selectedCourses.length > 0) {
          isIncluded = false
          food.courses.forEach(function (course) {
            if (selectedCourses.indexOf(course) !== -1) {
              isIncluded = true
            }            
          })
        }
        if (selectedMealTypes.length > 0) {
          isIncluded = false
          food.mealTypes.forEach(function (mealType) {
            if (selectedMealTypes.indexOf(mealType) !== -1) {
              isIncluded = true
            }            
          })
        }
        if (selectedWasteTypes.length > 0) {
          isIncluded = false
          food.wasteTypes.forEach(function (wasteType) {
            if (selectedWasteTypes.indexOf(wasteType) !== -1) {
              isIncluded = true
            }            
          })
        }
        if (selectedTags.length > 0) {
          isIncluded = false
          food.tags.forEach(function (tag) {
            if (selectedTags.indexOf(tag) !== -1) {
              isIncluded = true
            }            
          })
        }

        if (isIncluded) {
          let foodObj = {
            ...food,
            consumedPercent: food.preparedAmountGrams > 0 && food.customers > 0 ? (food.preparedAmountGrams - food.wasteGrams) / food.preparedAmountGrams * 100 : 0,
            consumedPerCustomer: food.customers > 0 ? parseFloat((food.preparedAmountGrams - food.wasteGrams) / food.customers) : 0
          }
          foods.push(foodObj)
        }
      })

      foods.slice().sort((a, b) => {
        if (typeof a[sortBy] === 'string') {
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) return 1
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) return -1
          return 0
        } else {
          if (a[sortBy] < b[sortBy]) return 1
          if (a[sortBy] > b[sortBy]) return -1
          return 0
        }
      }).forEach(function (food) {
        rows.push(<tr key={food.name + restaurants.length + selectedWasteTypes.length + selectedCourses.length + selectedMealTypes.length}>
          <td>{food.name}</td>
          <td>{parseFloat((food.preparedAmountGrams / 1000).toFixed(1))} kg</td>
          <td>{parseFloat((food.wasteGrams / 1000).toFixed(1))} kg</td>
          <td
            className={food.consumedPercent > consumedTargetPercentage ? 'targetValue' : (food.consumedPercent < consumedAlarmPercentage ? 'alarmValue' : '')}
          >
            {parseFloat((food.consumedPercent).toFixed(1))} %
          </td>
          <td>{parseFloat((food.consumedPerCustomer / 1000).toFixed(3))} kg</td>
          <td>{food.servingsCount}</td>
        </tr>)
      })

      if (rows.length > 0) {
        html.push(<table key={'table' + restaurants.length + selectedWasteTypes.length + selectedCourses.length + selectedMealTypes.length} className="table is-striped is-bordered" style={{margin: '20px auto'}}>
          <thead>
            <tr style={{cursor: 'pointer'}}>
              <th
                style={sortBy === 'name' ? {background: '#000', color: '#fff'} : {}}
                onClick={() => {
                  setSortBy('name')
                }}
              >
                <b>{t('Ruokanimike')}</b>
              </th>
              <th
                style={sortBy === 'preparedAmountGrams' ? {background: '#000', color: '#fff'} : {}}
                onClick={() => {
                  setSortBy('preparedAmountGrams')
                }}
              >
                <b>{t('Tarjoilumäärä kg')}</b>
              </th>
              <th
                style={sortBy === 'wasteGrams' ? {background: '#000', color: '#fff'} : {}}
                onClick={() => {
                  setSortBy('wasteGrams')
                }}
              >
                <b>{t('Hukattu kg')}</b>
              </th>
              <th
                style={sortBy === 'consumedPercent' ? {background: '#000', color: '#fff'} : {}}
                onClick={() => {
                  setSortBy('consumedPercent')
                }}
              >
                <b>{t('Syöty %')}</b>
              </th>
              <th
                style={sortBy === 'consumedPerCustomer' ? {background: '#000', color: '#fff'} : {}}
                onClick={() => {
                  setSortBy('consumedPerCustomer')
                }}
              >
                <b>{t('Syöty per asiakas kg')}</b>
              </th>
              <th
                style={sortBy === 'servingsCount' ? {background: '#000', color: '#fff'} : {}}
                onClick={() => {
                  setSortBy('servingsCount')
                }}
              >
                <b>{t('Kattaukset')}</b>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>)
      } else {
        html.push(<h2 style={{marginTop: '30px'}} className="is-size-2 is-size-3-mobile is-uppercase">{t('Ei tuloksia')}!</h2>)
      }

    }

    return (<div>
      <div>
        {html}
      </div>
    </div>)
  }

  return <>{foodView()}</>
}

export default Foodview
import React, { useState, useEffect, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { MEALSERVINGS_URL, MEALSERVINGLOGS_URL, post, get, patch } from '../../utility/api'
import noSleep from 'nosleep.js'
import moment from 'moment'
import HukkaSelect from '../hukkaSelect'
import Modal from '../modal'
import ErrorMessage from '../errorMessage'

const ScaleView = () => {
	const {restaurants, mealTypes, wasteTypes} = useSelector(
    state => state
  )
  const [scaleValue, setScaleValue] = useState(0)
  const [scaleConnected, setScaleConnected] = useState(false)
  const [servings, setServings] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [editModalLog, setEditModalLog] = useState(null)
  const [editModalPrepared, setEditModalPrepared] = useState(null)
  const [editModalWaste, setEditModalWaste] = useState(null)
  const [logs, setLogs] = useState(null)
  const [firstLoad, setFirstLoad] = useState(true)
  const [selectedRestaurant, setSelectedRestaurant] = useState(false)
  const [selectedServing, setSelectedServing] = useState(null)
  const [customers, setCustomers] = useState(null)
  const [notes, setNotes] = useState(null)
  const [saving, setSaving] = useState('-')
  const [savingTimeout, setSavingTimeout] = useState(null)
  const [selectedTab, setSelectedTab] = useState('prepared')
  const { t } = useTranslation()
  const targetService = '0000ffe0-0000-1000-8000-00805f9b34fb'
	const targetChar = '0000ffe1-0000-1000-8000-00805f9b34fb'
  const [error, setError] = useState('')
  
  const history = useHistory()
  let noSleepObj = new noSleep()

  const getRestaurantOptions = useCallback(
    () =>
      restaurants.slice()
      .sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      })
      .map(r => ({
        value: r.id,
        label: r.name,
      })),
    [restaurants, t]
  )

  const getServingOptions = servings && servings.length > 0 && servings.filter(s => s.restaurant === selectedRestaurant.value).map(s => {
    return {
      value: s.id,
      label: mealTypes.find(mt => mt.id === s.mealType).name + ' ' + moment(s.servingDatetime).format('HH:mm')
    }
  })

  const getMealServings = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      setServings(resp.data)
    } else {
    }
  }
  const getMealServingLogs = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGLOGS_URL, payload)

    if (resp.status === 200) {
      setLogs(resp.data)
    } else {
    }
  }

  if (!selectedServing && getServingOptions && getServingOptions.length === 1) {
    setSelectedServing(getServingOptions[0])
  }

  const handleMealServingEdit = async (customerAmountVal, notesVal) => {
    setSaving(true)
    const resp = await patch(MEALSERVINGS_URL, selectedServing.value, {
      meal_serving: selectedServing.value,
      customers: customerAmountVal === false ? customers : customerAmountVal,
      serving_notes: notesVal === false ? notes : notesVal
    })

    if (resp.status === 200) {
      setSaving(t('Muutokset tallennettu')+'.')
      let currentServings = servings
      let index = currentServings.findIndex(cs => cs.id === resp.data.id)
      currentServings[index] = resp.data
      setServings(currentServings)
    } else {
      setSaving(t('Jokin meni vikaan')+'.')
      console.log(resp.data)
    }
  }

  if (restaurants && selectedRestaurant === false && getRestaurantOptions().length === 1) {
    setSelectedRestaurant(getRestaurantOptions()[0])
  }

  const validateNumeric = (str) => {
    let orig = str.toString()
    if (orig.length === 0 || orig === '' || orig === null) {
      return '0'
    }
    let firstChar = orig.substr(0, 1)
    let lastChar = orig.substr(orig.length - 1)
    if (lastChar === ',') {
      orig += '.'
    }
    let validated = orig.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")
    if (firstChar === '0') {
      if (validated.substr(1, 1) !== '.') {
        validated = validated.substr(1)
      }
    }
    return validated.toString()
  }

  useEffect(() => {
    if (firstLoad) {
      let date = moment().format('YYYY-MM-DD')
      getMealServings(date, date)
      getMealServingLogs(date, date)
      setFirstLoad(false)
    }
  }, [firstLoad])
	
  if (!servings || !logs || !restaurants || (restaurants && restaurants.length < 1)) {
    return (<p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>)
	}

  const editModalHtml = (
    <Modal
      isActive={editModal}
      title={editModalLog ? editModalLog.foodName : ''}
      closeModalCallBack={() => {
        setEditModal(false)
      }}
      submitCallback={() => {
        setEditAmounts(editModalLog.id, {
          meal_serving: editModalLog.mealServing,
          waste_type: editModalLog.wasteType,
          food: editModalLog.food,
          prepared_amount_grams: parseFloat(editModalPrepared) * 1000,
          waste_grams: parseFloat(editModalWaste) * 1000,
          course: editModalLog.course
        })
      }}
    >
      { selectedTab === 'prepared' && (<>
        <h2>{t('Muokkaa tarjoilumäärää')}</h2>
        <div className="control is-large has-text-primary is-clearfix">
          <input
            className="input is-large has-text-primary"
            style={{ fontSize: '16px', flex: 1 }}
            type="text"
            inputMode="numeric"
            value={
              editModalPrepared ? editModalPrepared : 0
            }
            onChange={e => {
              setEditModalPrepared(validateNumeric(e.target.value))
            }}
          />
        </div>
      </>)}
      { selectedTab === 'waste' && (<>
        <h2>{t('Muokkaa hävikkimäärää')}</h2>
        <div className="control is-large has-text-primary is-clearfix">
          <input
            className="input is-large has-text-primary"
            style={{ fontSize: '16px', flex: 1 }}
            type="text"
            inputMode="numeric"
            value={
              editModalWaste ? editModalWaste : 0
            }
            onChange={e => {
              setEditModalWaste(validateNumeric(e.target.value))
            }}
          />
        </div>
      </>)}
    </Modal>)

  const fullscreen = () => {
    let elem = document.getElementById('fullscreenElement')

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem = window.top.document.body; //To break out of frame in IE
      elem.msRequestFullscreen();
    }
    
    noSleepObj.enable()
  }

  const connectScale = () => {
    try {
      navigator.bluetooth.requestDevice({
        filters: [{services: [targetService]}]
      })
      .then(device => {
        return device.gatt.connect();
      })
      .then(server => {
        return server.getPrimaryService(targetService);
      })
      .then(service => {
        return service.getCharacteristic(targetChar);
      })
      .then(characteristic => {
        characteristic.addEventListener('characteristicvaluechanged', handleNotifications);
        characteristic.startNotifications()
        setScaleConnected(true)
        fullscreen()
        return characteristic
      })
      .catch(error => {
        console.log('Argh! ' + error);
      });
    } catch (e) {
      console.log({e})
      setError(t('Tapahtui virhe.') + ' ' + t('Selaimesi ei ehkä tue Web Bluetoothia.'))
    }
  }

  const handleNotifications = (event) => {
    let decode = new TextDecoder().decode(event.target.value)
    decode = decode.split(' ')[1].split('kg')[0]
    setScaleValue(decode)
  }
  
  const setEditAmounts = async (id, payload) => {
    const resp = await patch(MEALSERVINGLOGS_URL, id, payload)
    
    if (resp.status === 200) {
      let date = moment().format('YYYY-MM-DD')
      getMealServingLogs(date, date)
    } else if (resp && resp.data && resp.data.detail) {
      setError(resp.data.detail)
    } else {
      setError('Tapahtui virhe')
    }
    setEditModal(false)
    setEditModalLog(false)
    setEditModalPrepared(0)
    setEditModalWaste(0)
  }
  
  const setPreparedAmount = async (id, payload) => {
    const resp = await patch(MEALSERVINGLOGS_URL, id, payload)
    
    if (resp.status === 200) {
      let date = moment().format('YYYY-MM-DD')
      getMealServingLogs(date, date)
    } else if (resp && resp.data && resp.data.detail) {
      setError(resp.data.detail)
    } else {
      setError('Tapahtui virhe')
    }
  }

  const addWaste = async (payload) => {
    const resp = await post(MEALSERVINGLOGS_URL, payload)
    
    if (resp.status === 201) {
      let date = moment().format('YYYY-MM-DD')
      getMealServingLogs(date, date)
    } else if (resp && resp.data && resp.data.detail) {
      setError(resp.data.detail)
    } else {
      setError('Tapahtui virhe')
    }
  }
  
  const setWaste = async (id, payload) => {
    const resp = await patch(MEALSERVINGLOGS_URL, id, payload)
    
    if (resp.status === 200) {
      let date = moment().format('YYYY-MM-DD')
      getMealServingLogs(date, date)
    } else if (resp && resp.data && resp.data.detail) {
      setError(resp.data.detail)
    } else {
      setError('Tapahtui virhe')
    }
  }

  const foodsList = () => {
    let html = []
    let lineId = wasteTypes.find(wt => wt.name === 'line' || wt.name === 'Linjastohävikki').id
    let plateId = wasteTypes.find(wt => wt.name === 'plate' || wt.name === 'Lautashävikki').id

    if (selectedRestaurant && selectedServing) {
      servings.filter(s => s.restaurant === selectedRestaurant.value && s.id === selectedServing.value).forEach(s => {
        html.push(<div key={'gap' + s.id} style={{marginTop: '20px'}}></div>)
        let mealType = mealTypes.find(mt => mt.id === s.mealType)
        html.push(<div>
          <p style={{lineHeight: '1', marginBottom: '10px'}} className="title is-4 is-size-5-mobile">{mealType ? t(mealType.name) : t('Ateria')} {t('klo')} {moment(s.servingDatetime).format('DD.MM HH:mm')}</p>
          <h2 style={{lineHeight: '1', marginBottom: '0'}} className="title is-3 is-size-5-mobile is-uppercase">{t('Asiakasmäärä')}</h2>
          <span style={{display: 'block', fontSize: '10px', color: '#777'}}>{saving}</span>
          <input
            type="text"
            value={customers}
            onChange={e => {
              let val = e.target.value
              setCustomers(val)
              setSaving(t('Tallennetaan')+'...')
              clearTimeout(savingTimeout)
              setSavingTimeout(setTimeout(function () {
                handleMealServingEdit(val,false)
              }, 2000))
            }}
          />
          <br />
          <br />
          <h2 style={{lineHeight: '1', marginBottom: '0'}} className="title is-3 is-size-5-mobile is-uppercase">{t('Huomiot')}</h2>
          <span style={{display: 'block', fontSize: '10px', color: '#777'}}>{saving}</span>
          <input
            type="text"
            value={notes}
            style={{width: '90%'}}
            onChange={e => {
              let val = e.target.value
              setNotes(val)
              setSaving(t('Tallennetaan')+'...')
              clearTimeout(savingTimeout)
              setSavingTimeout(setTimeout(function () {
                handleMealServingEdit(false, val)
              }, 2000))
            }}
          />
        </div>)

        logs.filter(log => log.mealServing === s.id && log.wasteType === lineId).forEach(function (log) {
          html.push(<div key={'food' + s.id + log.id} className="scaleView__food">

            <div className="scaleView__left">
            {selectedTab === 'prepared' && (
              <div
                className="scaleView__editServingAmount fas fa-edit"
                onClick={() => {
                  setEditModal(true)
                  setEditModalLog(log)
                  setEditModalPrepared(log.preparedAmountGrams / 1000)
                  setEditModalWaste(log.wasteGrams / 1000)
                }}
              />
            )}
            {selectedTab === 'waste' && (
              <div
                className="scaleView__editWasteAmount fas fa-edit"
                onClick={() => {
                  setEditModal(true)
                  setEditModalLog(log)
                  setEditModalPrepared(log.preparedAmountGrams / 1000)
                  setEditModalWaste(log.wasteGrams / 1000)
                }}
              />
            )}
              <div>
                <h2 style={{lineHeight: '1'}} className="title is-3 is-size-4-mobile is-uppercase">{log.foodName}</h2>
                <p>{t('Tarjoilumäärä')} {log.preparedAmountGrams > 0 ? log.preparedAmountGrams / 1000 : 0} kg</p>
                <p>{t('Hävikkimäärä')} {log.wasteGrams > 0 ? log.wasteGrams / 1000 : 0} kg</p>
              </div>
            </div>

            <div className="scaleView__right">
              {selectedTab === 'prepared' && (
                <button
                  disabled={!scaleConnected}
                  type="button"
                  className={'button is-small'}
                  onClick={() => {
                    if (scaleValue > 0) {
                      setPreparedAmount(log.id, {
                        meal_serving: log.mealServing,
                        waste_type: log.wasteType,
                        food: log.food,
                        prepared_amount_grams: (parseInt(log.preparedAmountGrams) + parseInt(scaleValue * 1000)),
                        waste_grams: log.wasteGrams,
                        course: log.course
                      })
                    }
                  }}
                >
                  <Trans>Aseta</Trans><br /><Trans>Tarjoilumäärä</Trans>
                </button>
              )}
              {selectedTab === 'waste' && (
                <button
                  disabled={!scaleConnected}
                  type="button"
                  className={'button is-small'}
                  onClick={() => {
                    if (scaleValue > 0) {
                      setWaste(log.id, {
                        meal_serving: log.mealServing,
                        waste_type: log.wasteType,
                        food: log.food,
                        prepared_amount_grams: log.preparedAmountGrams,
                        waste_grams: (parseInt(log.wasteGrams) + parseInt(scaleValue * 1000)),
                        course: log.course
                      })
                    }
                  }}
                >
                  <Trans>Aseta</Trans><br /><Trans>Hävikkimäärä</Trans>
                </button>
              )}
            </div>
          </div>)
        })

        if (selectedTab === 'waste') {
          let platewaste = logs.find(l => l.mealServing === s.id && l.wasteType === plateId)
  
          if (platewaste) {

            html.push(<div key={'food' + s.id + 'platewaste'} className="scaleView__food">
              <div className="scaleView__left">
                <div
                  className="scaleView__editWasteAmount fas fa-edit"
                  onClick={() => {
                    setEditModal(true)
                    setEditModalLog(platewaste)
                    setEditModalPrepared(platewaste.preparedAmountGrams / 1000)
                    setEditModalWaste(platewaste.wasteGrams / 1000)
                  }}
                />
                <div>
                  <h2 style={{lineHeight: '1'}} className="title is-3 is-size-4-mobile is-uppercase">{t('Lautashävikki')}</h2>
                  <p>{t('Hävikkimäärä')} {platewaste.wasteGrams > 0 ? platewaste.wasteGrams / 1000 : 0} kg</p>
                </div>
              </div>

              <div className="scaleView__right">
                <button
                  disabled={!scaleConnected}
                  type="button"
                  className={'button is-small'}
                  onClick={() => {
                    if (scaleValue > 0) {
                      setWaste(platewaste.id, {
                        meal_serving: platewaste.mealServing,
                        waste_type: platewaste.wasteType,
                        food: platewaste.food,
                        prepared_amount_grams: 0,
                        waste_grams: (parseInt(platewaste.wasteGrams) + parseInt(scaleValue * 1000)),
                        course: platewaste.course
                      })
                    }
                  }}
                >
                  <Trans>Aseta</Trans><br /><Trans>Hävikkimäärä</Trans>
                </button>
              </div>
            </div>)

          } else {
            
            html.push(<div key={'food' + s.id + 'platewaste'} className="scaleView__food">
              <div className="scaleView__left">
                <div
                  className="scaleView__editWasteAmount fas fa-edit"
                  onClick={() => {
                    addWaste({
                      meal_serving: s.id,
                      waste_type: plateId,
                      food: '',
                      prepared_amount_grams: 0,
                      waste_grams: 0,
                      course: ''
                    })
                  }}
                />
                <div>
                  <h2 style={{lineHeight: '1'}} className="title is-3 is-size-4-mobile is-uppercase">{t('Lautashävikki')}</h2>
                  <p>{t('Ei asetettu')}</p>
                </div>
              </div>

              <div className="scaleView__right">
                <button
                  disabled={!scaleConnected}
                  type="button"
                  className={'button is-small'}
                  onClick={() => {
                    addWaste({
                      meal_serving: s.id,
                      waste_type: plateId,
                      food: '',
                      prepared_amount_grams: 0,
                      waste_grams: scaleValue > 0 ? parseInt(scaleValue * 1000) : 0,
                      course: ''
                    })
                  }}
                >
                  <Trans>Aseta</Trans><br /><Trans>Hävikkimäärä</Trans>
                </button>
              </div>
            </div>)

          }
        }

      })
      if (html.length === 0) {
        html.push(<p style={{marginTop: '30px'}}>{t('Yhtään kattausta ei löytynyt')}!</p>)
      }
    }

    html.push(<button
      type="button"
      style={{marginTop: '20px', padding: '40px 100px', color: '#fff'}}
      className={'button is-secondary-background'}
      onClick={() => {
        document.exitFullscreen()
        history.push('/')
      }}
    >
      <Trans>Valmis</Trans>
    </button>)


    return html
  }

  return (<div className="scaleView">
  	<h1 className="title is-1 is-size-3-mobile is-uppercase"><Trans>Vaakanäkymä</Trans></h1>

    <h2 style={{lineHeight: '1', marginBottom: '0'}} className="title is-3 is-size-5-mobile is-uppercase">{t('Ravintola')}</h2>
    
    <HukkaSelect
      options={getRestaurantOptions()}
      placeholder={t('Valitse ravintola')}
      value={selectedRestaurant}
      onChange={e => {
        setSelectedServing(null)
        setCustomers(null)
        setNotes(null)
        setSelectedRestaurant(e)
      }}
    />

    <br />

    <h2 style={{lineHeight: '1', marginBottom: '0'}} className="title is-3 is-size-5-mobile is-uppercase">{t('Kattaus')}</h2>
    
    <HukkaSelect
      options={getServingOptions}
      placeholder={t('Valitse kattaus')}
      value={selectedServing}
      onChange={e => {
        setSelectedServing(e)
        let thisServing = servings.find(s => s.id === e.value)
        if (thisServing) {
          setCustomers(thisServing.customers)
          setNotes(thisServing.servingNotes)
        }
      }}
    />

    <div style={{paddingBottom: '30px'}} />

    <ErrorMessage msg={error} />
    <button
      type="button"
      disabled={!selectedServing}
      className={'button is-small is-secondary-background' + (!selectedServing ? ' disabled' : '')}
      style={{margin: '20px 5px 30px 5px', height: '100px', padding: '20px 40px', color: '#fff', display: 'block', margin: 'auto'}}
      onClick={() => {
        connectScale()
      }}
    >
      <Trans>Yhdistä vaaka</Trans>
    </button>
    <br />
    <button
      type="button"
      disabled={!selectedServing}
      onClick={() => {
        fullscreen()
      }}
    >
      <Trans>Avaa näkymä yhdistämättä vaakaa</Trans>
    </button>

    
    <div id='fullscreenElement' className='scaleView__fullScreen'>
      <h2 style={{lineHeight: '1', marginBottom: '0', marginTop: '10px'}} className="title is-3 is-size-5-mobile is-uppercase">{t('Mitä punnitaan')}?</h2>

      <div>
        <div id="tabs" className="scaleView__tabs">
          <button
            type="button"
            className={'button is-small' + (selectedTab === 'prepared' ? ' is-primary-background' : '')}
            onClick={() => {
              setSelectedTab('prepared')
              document.getElementById('tabs').scrollIntoView()
            }}
          >
            {t('Tarjoilumäärä')}
          </button>

          <button
            type="button"
            className={'button is-small' + (selectedTab === 'waste' ? ' is-primary-background' : '')}
            onClick={() => {
              setSelectedTab('waste')
              document.getElementById('tabs').scrollIntoView()
            }}
          >
            {t('Hävikki')}
          </button>
        </div>

        <div className="scaleView__value">
          <span style={{color: '#fff'}}>{t('Vaakalukema')}</span>
          {scaleConnected && (
            <h2 style={{lineHeight: '1'}} className="title is-1 is-size-1-mobile is-uppercase">{scaleValue} kg</h2>
          )}
          {!scaleConnected && (
            <h2 style={{lineHeight: '1'}} className="title is-1 is-size-1-mobile is-uppercase">{t('Vaaka ei yhdistetty')}</h2>
          )}
        </div>
      </div>

      <div
        className={'scaleView__foods' + ' scaleView__foods--' + selectedTab}
        style={{visibility: selectedTab !== '' ? 'visible' : 'hidden'}}
      >
        {foodsList()}
        {editModalHtml}
      </div>
    </div>
  </div>)
}

export default ScaleView

import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { tokenLogin } from '../../actions'
import Carousel from '../plateWaste/carousel'

const PlateWaste = () => {
  const dispatch = useDispatch()
  const {
    mealTypes,
    hukkaProfiles,
    hukkaServices,
    hukkaServiceSubscriptions
  } = useSelector(state => state)
  const { t } = useTranslation()
  const chartRef = useRef(null)
  const { loggedIn, rou:readOnlyUser } = useSelector(state => state.user) || { loggedIn: false, readOnlyUser: false }
  const [init, setInit] = useState(false)
  const [selectedMealTypes, setSelectedMealTypes] = useState([])

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  if (urlParams.get('token') !== null) {
    dispatch(tokenLogin(urlParams.get('token')))
    urlParams.delete('token')
    window.location.href = `${window.location.pathname}?${urlParams}${window.location.hash}`
  }

  const mealTypeOptions = mealTypes && mealTypes.slice()
    .sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    .map(r => ({
      value: r.id,
      label: r.name,
    }))
    .concat([
      {
        value: 'all',
        label: t('Valitse kaikki ateriat'),
      },
    ])

  useEffect(() => {
    // Start fullscreen automatically if there is autostart -parameter in url
	if (init === false && mealTypeOptions.length > 1) {
	  if (urlParams.get('mealTypes') !== null) {
	    let selectTypes = []
	    let mealTypeParams = urlParams.get('mealTypes').toLowerCase().split(',')
	    mealTypeOptions.forEach(function (mto) {
	      if (mto.label && mealTypeParams.indexOf(mto.label.toLowerCase()) !== -1) {
	        selectTypes.push(mto)
	      }
	    })
	    if (selectTypes.length > 0) {
	      setSelectedMealTypes(selectTypes)
	    }
	  } else {
	    setSelectedMealTypes(mealTypeOptions.filter(mto => mto.value !== 'all'))
	  }
	  setInit(true)
	}
  }, [init, mealTypeOptions, urlParams])

  const plateWasteView = () => (
    <div
      style={{
        display: 'block',
        alignItems: 'center',
        height: '100vh',
        width: '100vw'
      }}
      ref={chartRef}
    >
      <div style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%'}}>
        <Carousel selectedMealTypes={selectedMealTypes} />
      </div> 
    </div>
  )

  const showPlateWasteView = () => {
    let hasPlateWasteService = false
    if (hukkaServices && hukkaServiceSubscriptions) {
      let plateWasteService = hukkaServices.find(hs => (hs.name === 'Lautashävikki'))
      if (hukkaServiceSubscriptions.find(hss => hss.service === plateWasteService.id && moment(new Date()) < moment(hss.validUntil))) {
        hasPlateWasteService = true
      }
    }
    return hasPlateWasteService ? plateWasteView() : (<p className="is-size-2 is-size-3-mobile is-uppercase" style={{marginTop: '30px'}}>{t('Hei! Valitettavasti sinulla ei ole oikeuksia tämän palvelun käyttöön, mutta jos haluat tietää lisää, niin ota yhteyttä mailitse')}:<br /><a href="mailto:asiakaspalvelu@hukka.ai">asiakaspalvelu@hukka.ai</a></p>)
  }


  /* General */

  return (
    <>
      {!hukkaProfiles && (<p className="is-size-2 is-size-3-mobile is-uppercase">Ladataan...</p>)}
      {loggedIn && hukkaProfiles && hukkaServices && hukkaServiceSubscriptions && readOnlyUser ? showPlateWasteView() : ''}
    </>
  )
}

export default PlateWaste

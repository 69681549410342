import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Yearview = ({combinedValues}) => {
  const { t } = useTranslation()
  const {
    yearServingStats,
    orgs,
    wasteTypes
  } = useSelector(state => state)

  const wasteTargetPercentage = orgs && orgs[0].wasteTargetPercentage ? orgs[0].wasteTargetPercentage : 0
  const wasteAlarmPercentage = orgs && orgs[0].wasteAlarmPercentage ? orgs[0].wasteAlarmPercentage : 100

  const yearView = () => {
    if (yearServingStats && yearServingStats.restaurants) {
      let html = []
      let totals = []

      let rows = [
        'moneyWaste',
        'totalCustomers',
        'avgCustomers',
        'preparedAmountGrams'
      ]
      let rowUnits = [
        '€',
        '',
        '',
        'kg'
      ]
      let rowNames = [
        'Rahaa hukattu',
        'Asiakasmäärä',
        'Asiakasmäärä ka.',
        'Tarjoilumäärä'
      ]
      let wasteTypeArr = wasteTypes.slice().sort((a, b) => {
        if (a.name === 'line') return -1
        if (b.name === 'line') return 1
        if (a.name === 'plate') return -1
        if (b.name === 'plate') return 1
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      })
      wasteTypeArr.forEach(function (type) {
        rows.push(type.name.toLowerCase() + 'WasteGrams')
        rows.push(type.name.toLowerCase() + 'Co2')
        rowUnits.push('kg')
        rowUnits.push('kg co2')
        rowNames.push(type.name)
        rowNames.push(type.name)
      })

      yearServingStats.restaurants.forEach(function (r) {
        let months = []
        let monthsHtml = []

        let restaurantTotals = {}
        rows.forEach(function (row) {
          restaurantTotals[row] = 0
        })

        Object.keys(r.months).forEach(function (month) {
          if (combinedValues) {
            if (!totals[month]) {
              totals[month] = {}
              rows.forEach(function (row) {
                totals[month][row] = 0
              })
            }
            rows.forEach(function (row) {
              if (r.months[month][row]) {
                totals[month][row] = totals[month][row] + r.months[month][row]
              }
            })
          } else {
            months[month] = {}
            rows.forEach(function (row, index) {
              let val = r.months[month][row] ? r.months[month][row] : 0
              if (rowUnits[index] === 'kg' || rowUnits[index] === 'kg co2') {
                months[month][row] = parseFloat((val / 1000).toFixed(1))
                restaurantTotals[row] = restaurantTotals[row] + val
              } else if (rowUnits[index] === '€') {
                months[month][row] = (val).toFixed(2)
                restaurantTotals[row] = restaurantTotals[row] + val
              } else {
                months[month][row] = parseFloat((val).toFixed(1))
                restaurantTotals[row] = restaurantTotals[row] + val
              }
            })
          }
        })

        if (!combinedValues) {
          rows.forEach(function (row, index) {
            let monthValuesHtml = []
            for (let i = 1; i < 13; i++) {
              monthValuesHtml.push(<td>{months[i] ? (months[i][row] ? months[i][row] : 0) : 0}{rowUnits[index]}</td>)
            }
            let total = ''
            let unit = rowUnits[index]
            let name = rowNames[index]

            if (rowUnits[index] === 'kg' || rowUnits[index] === 'kg co2') {
              total = parseFloat((restaurantTotals[row] / 1000).toFixed(1))
            } else if (rowUnits[index] === '€') {
              total = (restaurantTotals[row]).toFixed(2)
            } else {
              total = parseFloat((restaurantTotals[row]).toFixed(1))
            }
            if (rowNames[index] === 'line') {
              name = 'Linjastohävikki'
            } else if (rowNames[index] === 'plate') {
              name = 'Lautashävikki'
            }

            if (name === 'Asiakasmäärä ka.') {
              monthsHtml.push(<tr>
                <td><b>{t(name)}{unit !== '' ? ' (' + rowUnits[index] + ')' : ''}</b></td>
                {monthValuesHtml}
                <td><b>-</b></td>
              </tr>)
            } else {
              monthsHtml.push(<tr>
                <td><b>{t(name)}{unit !== '' ? ' (' + rowUnits[index] + ')' : ''}</b></td>
                {monthValuesHtml}
                <td><b>{total}{unit}</b></td>
              </tr>)
            }

            if (row.indexOf('WasteGrams') !== -1) {
              monthValuesHtml = []
              let totalPrepared = 0
              let totalValue = 0
              for (let i = 1; i < 13; i++) {
                if (months[i]['preparedAmountGrams']) {
                  totalPrepared = totalPrepared + months[i]['preparedAmountGrams']
                }
                if (months[i][row]) {
                  totalValue = totalValue + months[i][row]
                }
                monthValuesHtml.push(<td>{months[i] ? (months[i][row] && months[i]['preparedAmountGrams'] ? parseFloat((months[i][row] / months[i]['preparedAmountGrams'] * 100).toFixed(1)) : 0) : 0}%</td>)
              }

              let percentage = totalPrepared > 0 ? parseFloat((totalValue / totalPrepared * 100).toFixed(1)) : 0
              monthsHtml.push(<tr>
                <td><b>{t(name)} (%)</b></td>
                {monthValuesHtml}
                <td className={percentage < wasteTargetPercentage ? 'targetValue' : (percentage > wasteAlarmPercentage ? 'alarmValue' : '')}><b>{percentage}%</b></td>
              </tr>)
            }
          })

          html.push(<>
            <tr>
              <td><b>{r.restaurantName}</b></td>
              <td><b>1</b></td>
              <td><b>2</b></td>
              <td><b>3</b></td>
              <td><b>4</b></td>
              <td><b>5</b></td>
              <td><b>6</b></td>
              <td><b>7</b></td>
              <td><b>8</b></td>
              <td><b>9</b></td>
              <td><b>10</b></td>
              <td><b>11</b></td>
              <td><b>12</b></td>
              <td><b>{t('Yhteensä')}</b></td>
            </tr>
            {monthsHtml}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </>)      
        }
      })

      if (combinedValues) {
        let cumulativeTotal = {}
        rows.forEach(function (row) {
          cumulativeTotal[row] = 0
        })
        totals.forEach(function (total) {
          rows.forEach(function (row, index) {
            if (total[row]) {
              cumulativeTotal[row] = cumulativeTotal[row] + total[row]
            }
            if (rowUnits[index] === 'kg') {
              total[row] = parseFloat((total[row] / 1000).toFixed(1))
            } else if (rowUnits[index] === '€') {
              total[row] = (total[row]).toFixed(2)
            } else {
              total[row] = parseFloat((total[row]).toFixed(1))
            }
          })
        })
        html.push(<>
          <tr>
            <td><b>{t('Ravintolat yhteensä')}</b></td>
            <td><b>1</b></td>
            <td><b>2</b></td>
            <td><b>3</b></td>
            <td><b>4</b></td>
            <td><b>5</b></td>
            <td><b>6</b></td>
            <td><b>7</b></td>
            <td><b>8</b></td>
            <td><b>9</b></td>
            <td><b>10</b></td>
            <td><b>11</b></td>
            <td><b>12</b></td>
            <td><b>{t('Yhteensä')}</b></td>
          </tr>
        </>)

        rows.forEach(function (row, index) {
          let monthValuesHtml = []
        
          for (let i = 1; i < 13; i++) {
            monthValuesHtml.push(<td>{totals[i] ? (totals[i][row] ? totals[i][row] : 0) : 0}{rowUnits[index]}</td>)
          }

          let name = rowNames[index]
          if (name === 'line') {
            name = 'Linjastohävikki'
          } else if (name === 'plate') {
            name = 'Lautashävikki'
          }
          let unit = rowUnits[index]

          let val = cumulativeTotal[row] ? cumulativeTotal[row] : 0
          if (rowUnits[index] === 'kg') {
            val = parseFloat((val / 1000).toFixed(1))
          } else if (rowUnits[index] === '€') {
            val = (val).toFixed(2)
          } else {
            val = parseFloat((val).toFixed(1))
          }
          
          if (name === 'Asiakasmäärä ka.') {
            html.push(<tr>
              <td><b>{t(name)}</b></td>
              {monthValuesHtml}
              <td><b>-</b></td>
            </tr>)
          } else {
            html.push(<tr>
              <td><b>{t(name)}</b></td>
              {monthValuesHtml}
              <td><b>{val}{unit}</b></td>
            </tr>)
          }

          if (row.indexOf('WasteGrams') !== -1) {
            monthValuesHtml = []
            let totalPrepared = 0
            let totalValue = 0
            for (let i = 1; i < 13; i++) {
              if (totals[i]['preparedAmountGrams']) {
                totalPrepared = totalPrepared + totals[i]['preparedAmountGrams']
              }
              if (totals[i][row]) {
                totalValue = totalValue + totals[i][row]
              }
              monthValuesHtml.push(<td>{totals[i] ? (totals[i][row] && totals[i]['preparedAmountGrams'] ? parseFloat((totals[i][row] / totals[i]['preparedAmountGrams'] * 100).toFixed(1)) : 0) : 0}%</td>)
            }

            let percentage = totalPrepared > 0 ? parseFloat((totalValue / totalPrepared * 100).toFixed(1)) : 0
            html.push(<tr>
              <td><b>{t(name)} (%)</b></td>
              {monthValuesHtml}
              <td className={percentage < wasteTargetPercentage ? 'targetValue' : (percentage > wasteAlarmPercentage ? 'alarmValue' : '')}><b>{percentage}%</b></td>
            </tr>)
          }
        })

      }

      return (<table className="table is-striped is-bordered" style={{margin: '20px auto'}}>
        <tbody>
          {html}
        </tbody>
      </table>)
    } else {
      return (<div></div>)
    }
  }

  return <>{yearView()}</>
}

export default Yearview
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { string } from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import Modal from '../modal'
import { put, PWD_URL } from '../../utility/api'

const INITIAL_PW_INPUTS = {
  old: '',
  newPw: '',
  newCheck: '',
}

const Password = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [errors, setErrors] = useState(null)
  const [passwordInputs, setPasswordInputs] = useState(INITIAL_PW_INPUTS)
  const { t } = useTranslation()

  useEffect(() => {
    const { newPw, newCheck } = passwordInputs
    if (newPw && newCheck) {
      const match = newPw === newCheck
      if (!match) {
        setErrors({ newPassword: [t('Salasanat eivät täsmää')] })
      }
    }
  }, [passwordInputs, t])

  const { email, username, firstName, lastName, org, orgAdmin } = useSelector(
    state => state.user
  )
  const { orgs } = useSelector(state => state)

  const title = firstName && lastName ? `${firstName} ${lastName}` : username
  const userOrg = orgs && orgs.find(o => o.id === org)
  const orgName = userOrg ? userOrg.name : null

  const handlePasswordChange = async () => {
    if (errors && errors.newCheck) {
      return
    }

    const { old, newPw } = passwordInputs
    const resp = await put(PWD_URL, {
      old_password: old,
      new_password: newPw,
    })

    if (resp.status === 204) {
      setModalOpen(false)
    } else {
      setErrors(resp.data)
    }
  }

  const updatePasswordInputs = (target, value) => {
    setErrors(null)
    setPasswordInputs({ ...passwordInputs, [target]: value })
  }

  const closeModal = () => {
    setModalOpen(false)
    setPasswordInputs(INITIAL_PW_INPUTS)
  }

  const changePasswordModal = (
    <Modal
      isActive={modalOpen}
      closeModalCallBack={closeModal}
      title={t('vaihda salasana')}
      submitCallback={handlePasswordChange}
    >
      <Modal.Field label={t('vanha salasana')} errors={errors && errors.oldPassword}>
        <input
          type="password"
          className="input"
          value={passwordInputs.old}
          onChange={e => updatePasswordInputs('old', e.target.value)}
        />
      </Modal.Field>

      <Modal.Field label={t('uusi salasana')}>
        <input
          type="password"
          className="input"
          value={passwordInputs.newPw}
          onChange={e => updatePasswordInputs('newPw', e.target.value)}
        />
      </Modal.Field>

      <Modal.Field
        label={t('uusi salasana uudestaan')}
        errors={errors && errors.newPassword}
      >
        <input
          type="password"
          className="input"
          value={passwordInputs.newCheck}
          onChange={e => updatePasswordInputs('newCheck', e.target.value)}
        />
      </Modal.Field>
    </Modal>
  )

  return (
    <div className="box is-uppercase is-size-5" style={{ margin: '45px 0' }}>
      <div className="column">
        <div className="title is-3 is-marginless">{title}</div>
      </div>

      <Row name="email" value={email} />
      <Row name={t('organisaatio')} value={orgName} />
      <Row
        name={t('käyttäjästatus')}
        value={orgAdmin ? t('organisaation admin') : t('normaali')}
      />

      <button
        type="button"
        className="button is-primary"
        onClick={() => setModalOpen(true)}
      >
        <Trans>Vaihda salasana</Trans>
      </button>

      {changePasswordModal}
    </div>
  )
}

const Row = ({ name, value }) => {
  if (!value) {
    return null
  }

  return (
    <div className="columns">
      <div className="column" style={{ textAlign: 'right' }}>
        {name}:
      </div>
      <div className="column" style={{ textAlign: 'left' }}>
        {value}
      </div>
    </div>
  )
}

Row.defaultProps = {
  value: null,
}

Row.propTypes = {
  value: string,
  name: string.isRequired,
}

export default Password

/**
 * email: "test@example.com"
firstName: "Pilvi"
id: "3785a8d1-4f20-475f-a441-40d31f5c4e1d"
isStaff: 0
lastName: "Kukka"
loggedIn: true
org: "9fa76af7-3468-4aa8-bd07-1c522cf4a162"
orgAdmin: true
restaurants: (2) ["5a9b990a-85b5-43b8-af5e-01c83adad922", "6dee758a-aad2-489e-9168-c1ef4a678572"]
rou: 0
token: "96b75f789a79ef8c2ffc0750b3c8205625de1fff"
user: 5
username: "pilvi_admin"
 */

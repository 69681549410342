import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { get, post, patchFile, MONITORINGPLANS_URL, MONITORINGPLANUPLOAD_URL } from '../../utility/api'
import { updateMonitoringPlans } from '../../actions'

const Plans = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { orgs, monitoringPlans } = useSelector(
    state => state
  )
  const { orgAdmin } = useSelector(state => state.user)
  const [selectedFile, setSelectedFile] = useState(null)
  const [uploadLoading, setUploadLoading] = useState(false)

  const uploadPlanFile = async () => {
    let resp = null
    let formData = new FormData()
    formData.append('self_monitoring_plan', selectedFile)
    if (monitoringPlans && monitoringPlans.length > 0) {
      resp = await patchFile(MONITORINGPLANUPLOAD_URL, monitoringPlans[monitoringPlans.length -1].id, formData)
    } else {
      let postResp = await post(MONITORINGPLANS_URL, {org: orgs[0].id})
      if (postResp.status === 201) {
        resp = await patchFile(MONITORINGPLANUPLOAD_URL, postResp.data.id, formData)
      } else {
        resp = {
          status: 400
        }
        console.log({error: postResp.data})
      }
    }

    if (resp.status === 200) {
      let plansResp = await get(MONITORINGPLANS_URL)
      if (plansResp.status === 200) {
        dispatch(updateMonitoringPlans(plansResp.data))
        setSelectedFile(null)
      } else {
        console.log({error: plansResp.data})
      }
    }
    setUploadLoading(false)
  }

  const uploadPlan = () => {
    return (<div>
      <h1
        className="title is-3 is-size-4-mobile is-uppercase"
        style={{margin: '0'}}
      >
          <Trans>Lataa uusi suunnitelma</Trans>
      </h1>
      <input
        type="file"
        id="input-file-upload"
        name="input-file-upload"
        style={{display: 'none'}}
        onChange={(e) => setSelectedFile(e.target.files[0])}
      />
      <p>{selectedFile ? selectedFile.name : t('Ei tiedostoa valittuna')}</p>
      <a
				onClick={() => {
					document.getElementById('input-file-upload').click()
				}}
			>
				{ selectedFile ? t('Vaihda valittu tiedosto') : t('Valitse tiedosto') }
			</a>
      <br />
      {selectedFile && (
        <button
          type="button"
          disabled={!selectedFile || uploadLoading}
          className={'button is-small' + (selectedFile ? ' is-primary-background' : '')}
          style={{margin: '5px'}}
          onClick={() => {
            setUploadLoading(true)
            uploadPlanFile()
          }}
        >
          { uploadLoading ? t('Tallennetaan...') : t('Tallenna valittu tiedosto') }
        </button>
      )}
    </div>
    )
  }

  const planView = () => {
    let html = []

    monitoringPlans.forEach(plan => {
      let obj = [];
      
      if (plan.selfMonitoringPlan && plan.selfMonitoringPlan !== '') {
        let planSplit = plan.selfMonitoringPlan.split('/')
        obj.push(<div key={'plan_' + plan.id}>
          <p><b>{planSplit[planSplit.length - 1]}</b></p>
          <br />
          <a 
            href={plan.selfMonitoringPlan}
            target="_blank"
          >
            <button
              disabled={uploadLoading}
              className={'button is-small'}
            >
                Avaa suunnitelma
            </button>
          </a>
        </div>)
      }
      html = <div>{obj}</div>
    })

    if (html.length === 0) {
      html.push(<div key="no_plan">
        <p>{t('Organisaation ylläpitäjä ei ole ladannut suunnitelmaa Hukkaan.')}</p>
      </div>)
    }

    return html
  }

  return (<div>
  	<h1
      className="title is-1 is-size-3-mobile is-uppercase"
      style={{margin: '0'}}
    >
        <Trans>Suunnitelma</Trans>
    </h1>
    {monitoringPlans && planView()}
    <br /><br />
    {orgAdmin && uploadPlan()}
  </div>)
}

export default Plans

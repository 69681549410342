/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { element, arrayOf, number } from 'prop-types'

export const SCOPES = {
  ORG_ADMIN: 1,
  STAFF_ADMIN: 2,
}

const checkScopes = (scopes, isStaff, orgAdmin) =>
  scopes.reduce((acc, scope) => {
    if (scope === SCOPES.ORG_ADMIN) {
      return acc || orgAdmin
    }

    if (scope === SCOPES.STAFF_ADMIN) {
      return acc || !!isStaff
    }

    return acc || false
  }, false)

const PrivateRoute = ({ scopes, children, ...options }) => {
  const { loggedIn, token, isStaff, orgAdmin, rou: readOnlyUser } = useSelector(
    state => state.user
  ) || { loggedIn: false, isStaff: false, orgAdmin: false, readOnlyUser: false }

  const isAuthed = loggedIn && token && !readOnlyUser
  const scopeMatch = scopes ? checkScopes(scopes, isStaff, orgAdmin) : true

  return isAuthed && scopeMatch ? (
    <Route {...options}>{children}</Route>
  ) : (
    <Route {...options}>
      <Redirect to="/" />
    </Route>
  )
}

PrivateRoute.defaultProps = {
  scopes: null,
}

PrivateRoute.propTypes = {
  children: element.isRequired,
  scopes: arrayOf(number),
}

export default PrivateRoute

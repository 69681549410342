/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from "react-router-dom";
import {
  setReportsToggle
} from '../../actions'

const SideNav = () => {
  const { sideNav, user, reportsToggle } = useSelector(
    state => state
  )
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation();

  const linkClick = (link) => {
    history.push(link)
  }

  return (<>
    {user && user.username && (
      <nav
        className={'sideNav' + (sideNav ? '' : ' sideNav--closed')}
        role="navigation"
        aria-label="main navigation"
      >
        <div className='sideNav__content'>
          <span className={'sideNav__title' + (location.pathname === '/add-waste' || location.pathname === '/waste-report' ? ' sideNav__selected' : '')}>{t('Hävikkikirjaukset')}</span>
          <span
            className={'sideNav__link' + (location.pathname === '/add-waste' ? ' sideNav__selected' : '')}
            onClick={() => linkClick('/add-waste')}
          >
            {t('Lisää hävikki')}
          </span>
          <span
            className={'sideNav__link' + (location.pathname === '/waste-report' ? ' sideNav__selected' : '')}
            onClick={() => linkClick('/waste-report')}
          >
            {t('Hävikkiseuranta')}
          </span>
          
          <span className={'sideNav__title' + (location.pathname === '/reports' ? ' sideNav__selected' : '')}>{t('Raportointi')}</span>
          <span
            className={'sideNav__link' + (location.pathname === '/reports' && reportsToggle === 'overview' ? ' sideNav__selected' : '')}
            onClick={() => {
              linkClick('/reports')
              dispatch(setReportsToggle('overview'))
            }}
          >
            {t('Yleiskatsaus')}
          </span>
          <span
            className={'sideNav__link' + (location.pathname === '/reports' && reportsToggle === 'week' ? ' sideNav__selected' : '')}
            onClick={() => {
              linkClick('/reports#weekview')
              dispatch(setReportsToggle('week'))
            }}
          >
            {t('Viikkonäkymä')}
          </span>
          <span
            className={'sideNav__link' + (location.pathname === '/reports' && reportsToggle === 'year' ? ' sideNav__selected' : '')}
            onClick={() => {
              linkClick('/reports#yearview')
              dispatch(setReportsToggle('year'))
            }}
          >
            {t('Vuosinäkymä')}
          </span>
          <span
            className={'sideNav__link' + (location.pathname === '/reports' && reportsToggle === 'food' ? ' sideNav__selected' : '')}
            onClick={() => {
              linkClick('/reports#foodview')
              dispatch(setReportsToggle('food'))
            }}
          >
            {t('Ruokanäkymä')}
          </span>
  
          <span className={'sideNav__title' + (location.pathname === '/food-order-estimation-single' || location.pathname === '/food-order-estimation-week' || location.pathname === '/food-order-estimation-ml' ? ' sideNav__selected' : '')}>{t('Menekkiarvio')}</span>
          <span
            className={'sideNav__link' + (location.pathname === '/food-order-estimation-single' ? ' sideNav__selected' : '')}
            onClick={() => {
              linkClick('/food-order-estimation-single')
            }}
          >
            {t('Yksittäinen ruoka')}
          </span>
          <span
            className={'sideNav__link' + (location.pathname === '/food-order-estimation-week' ? ' sideNav__selected' : '')}
            onClick={() => {
              linkClick('/food-order-estimation-week')
            }}
          >
            {t('Viikkoarvio')}
          </span>
          <span
            className={'sideNav__link' + (location.pathname === '/food-order-estimation-ml' ? ' sideNav__selected' : '')}
            onClick={() => {
              linkClick('/food-order-estimation-ml')
            }}
          >
            {t('Koneoppimisarvio')}
          </span>
  
          <span className={'sideNav__title' + (location.pathname === '/menu' || location.pathname === '/food-prices' ? ' sideNav__selected' : '')}>{t('Listat')}</span>
          <span
            className={'sideNav__link' + (location.pathname === '/menu' ? ' sideNav__selected' : '')}
            onClick={() => linkClick('/menu')}
          >
            {t('Ruokalista')}
          </span>
          <span
            className={'sideNav__link' + (location.pathname === '/food-prices' ? ' sideNav__selected' : '')}
            onClick={() => linkClick('/food-prices')}
          >
            {t('Ruokahinnasto')}
          </span>
  
          <span className={'sideNav__title' + (location.pathname === '/info' ? ' sideNav__selected' : '')}>{t('Info')}</span>
          <span
            className={'sideNav__link' + (location.pathname === '/info' ? ' sideNav__selected' : '')}
            onClick={() => linkClick('/info')}
          >
            {t('Opastusvideot')}
          </span>
  
          <span className={'sideNav__title' + (location.pathname === '/addons' || location.pathname === '/survey-management' || location.pathname === '/survey-reports' ? ' sideNav__selected' : '')}>{t('Asiakasnäkymät')}</span>
          <span
            className={'sideNav__link' + (location.pathname === '/addons' ? ' sideNav__selected' : '')}
            onClick={() => linkClick('/addons')}
          >
            {t('Näkymiin kirjautuminen')}
          </span>
          <span
            className={'sideNav__link' + (location.pathname === '/survey-management' ? ' sideNav__selected' : '')}
            onClick={() => linkClick('/survey-management')}
          >
            {t('Kyselyiden hallinta')}
          </span>
          <span
            className={'sideNav__link' + (location.pathname === '/survey-reports' ? ' sideNav__selected' : '')}
            onClick={() => linkClick('/survey-reports')}
          >
            {t('Kyselyiden seuranta')}
          </span>
  
          <span className={'sideNav__title' + (location.pathname === '/users' || location.pathname === '/restaurants' || location.pathname === '/organizations' ? ' sideNav__selected' : '')}>{t('Hallinnointi')}</span>
          <span
            className={'sideNav__link' + (location.pathname === '/users' ? ' sideNav__selected' : '')}
            onClick={() => linkClick('/users')}
          >
            {t('Käyttäjät')}
          </span>
          <span
            className={'sideNav__link' + (location.pathname === '/restaurants' ? ' sideNav__selected' : '')}
            onClick={() => linkClick('/restaurants')}
          >
            {t('Ravintolat')}
          </span>
          <span
            className={'sideNav__link' + (location.pathname === '/organizations' ? ' sideNav__selected' : '')}
            onClick={() => linkClick('/organizations')}
          >
            {t('Organisaatio')}
          </span>
        </div>
      </nav>
    )}
  </>)
}

export default SideNav
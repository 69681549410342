import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { Trans, useTranslation } from 'react-i18next';
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/initialize'
import { useHistory } from 'react-router-dom'
import 'moment/locale/fi'
import ChooseServingForm from './chooseServingForm'
import MealServingLogsTable from './mealServingLogsTable'
import NewMealServingLogForm from './newMealServingLogForm'
import {
  updateAddWasteForm,
  updateMealServingLogs,
  emptyAddWasteForm,
  removeMealServingLog,
  addFoodCourseDefault,
  updateFoodCourseDefault
} from '../../actions'
import { post, MEALSERVINGLOGS_URL, patch, del } from '../../utility/api'
import ErrorMessage from '../errorMessage'
import Modal from '../modal'

moment.locale('fi')
const PLATE_WASTE_TYPE_ID = process.env.REACT_APP_PLATE_WASTE_TYPE_ID

const AddWaste = () => {
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    user,
    wasteTypes,
    orgFoods,
    mealServings,
    mealServingLogs,
    addWasteForm,
    courses,
    foodCourseDefaults
  } = useSelector(state => state)
  const {
    selectedMealServing,
    selectedWasteType,
    selectedFood,
    preparedAmountKg,
    wasteKg = 0,
    selectedCourse,
  } = addWasteForm || {}
  const [wasteLogInEdit, setWasteLogInEdit] = useState(null)
  const [checkModalOpen, setCheckModalOpen] = useState(false)

  const updateFormAttribute = (target, value) => {
    dispatch(updateAddWasteForm(target, value))
  }

  const updateEditFormAttribute = (target, value) =>
    setWasteLogInEdit({ ...wasteLogInEdit, [target]: value })

  const handleNewMealServingLogSubmit = async () => {
    if (selectedWasteType !== null && selectedWasteType !== '') {
      const reqData = {
        meal_serving: selectedMealServing,
        food: (PLATE_WASTE_TYPE_ID !== selectedWasteType && selectedWasteType !== wasteTypes.find(wt => wt.name === 'Keittiöhävikki').id && selectedWasteType !== wasteTypes.find(wt => wt.name === 'Ruoka-apu').id) ? selectedFood : '',
        prepared_amount_grams: parseInt(parseFloat(preparedAmountKg, 10) * 1000),
        waste_grams: parseInt(parseFloat(wasteKg, 10) * 1000),
        waste_type: selectedWasteType,
        course: (PLATE_WASTE_TYPE_ID !== selectedWasteType && selectedWasteType !== wasteTypes.find(wt => wt.name === 'Keittiöhävikki').id && selectedWasteType !== wasteTypes.find(wt => wt.name === 'Ruoka-apu').id) ? selectedCourse : '',
      }
  
      const resp = await post(MEALSERVINGLOGS_URL, reqData)
  
      if (resp.status === 201) {
        dispatch(updateMealServingLogs(resp.data))
        dispatch(updateAddWasteForm('selectedCourse', ''))
        dispatch(emptyAddWasteForm())
        let obj = resp.data
        let food = foodCourseDefaults.find(fcd => fcd.id === obj.food)
        if (food && food.id) {
          if (food.course.id !== obj.course) {
            dispatch(updateFoodCourseDefault({
              id: obj.food,
              name: obj.foodName,
              course: {
                id: obj.course,
                name: courses.find(c => c.id === obj.course) ? courses.find(c => c.id === obj.course).name : ''
              }
            }))
          }
        } else {
          dispatch(addFoodCourseDefault({
            id: obj.food,
            name: obj.foodName,
            course: {
              id: obj.course,
              name: courses.find(c => c.id === obj.course) ? courses.find(c => c.id === obj.course).name : ''
            }
          }))
        }
      } else if (resp && resp.detail && resp.data.detail) {
        setError(resp.detail)
      } else {
        setError('Tapahtui virhe')
      }
    }
  }

  const handleMealServingEdit = async () => {
    const {
      id,
      wasteKg: editWasteKg,
      preparedAmountKg: editPreparedKg,
      selectedFood: editFood,
      selectedWasteType: editWasteType,
      selectedCourse: editCourse,
    } = wasteLogInEdit

    if (wasteLogInEdit.selectedWasteType && wasteLogInEdit.selectedWasteType !== '') {
      const resp = await patch(MEALSERVINGLOGS_URL, id, {
        meal_serving: selectedMealServing,
        waste_type: editWasteType,
        food: editFood,
        prepared_amount_grams: parseInt(parseFloat(editPreparedKg, 10) * 1000),
        waste_grams: editWasteKg ? parseInt(parseFloat(editWasteKg, 10) * 1000) : 0,
        course: editCourse,
      })
      
      if (resp.status === 200) {
        dispatch(updateMealServingLogs(resp.data))
        dispatch(emptyAddWasteForm())
      } else if (resp && resp.data && resp.data.detail) {
        setError(resp.data.detail)
      } else {
        setError('Tapahtui virhe')
      }
      setWasteLogInEdit(null)
    }
  }

  const deleteWasteLog = async id => {
    const resp = await del(MEALSERVINGLOGS_URL, id)

    if (resp.status === 204) {
      dispatch(removeMealServingLog(id))
    } else if (resp && resp.data && resp.data.detail) {
      setError(resp.data.detail)
    } else {
      setError('Tapahtui virhe')
    }
  }

  const customerAmountCheckModal = (
    <div className={`modal ${checkModalOpen ? 'is-active' : null}`}>
      <div className="modal-background" />
      <div className="modal-card" style={{textAlign: 'center'}}>
        <header className="modal-card-head">
          <p className="modal-card-title"><Trans>Tarkista asiakasmäärä</Trans></p>
        </header>
        <section className="modal-card-body">
            <p><Trans>Merkattu asiakasmäärä on alle 10</Trans>.</p>
            <p><Trans>Onko määrä oikea</Trans>?</p>
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-success is-large"
            onClick={() => {
              history.push('/')
            }}
          >
            <Trans>Asiakasmäärä on oikein</Trans>
          </button>
          <button
            type="button"
            className="button is-large"
            onClick={() => setCheckModalOpen(false)}
          >
            <Trans>Peruuta ja korjaa</Trans>
          </button>
        </footer>
      </div>
    </div>
  )

  const wasteLogEditModal = (
    <Modal
      isActive={!!wasteLogInEdit}
      title={t('Muokkaa hävikkiä')}
      closeModalCallBack={() => setWasteLogInEdit(null)}
      submitCallback={handleMealServingEdit}
    >
      <NewMealServingLogForm
        wasteTypes={wasteTypes}
        foods={orgFoods}
        updateFormAttribute={updateEditFormAttribute}
        selectedWasteType={wasteLogInEdit && wasteLogInEdit.selectedWasteType}
        selectedFood={wasteLogInEdit && wasteLogInEdit.selectedFood}
        selectedCourse={wasteLogInEdit && wasteLogInEdit.selectedCourse}
        handleSubmit={handleNewMealServingLogSubmit}
        preparedAmountKgs={wasteLogInEdit && wasteLogInEdit.preparedAmountKg ? wasteLogInEdit.preparedAmountKg.toString() : ''}
        wastedAmountKgs={wasteLogInEdit && wasteLogInEdit.wasteKg ? wasteLogInEdit.wasteKg.toString() : ''}
        edit
        courses={courses || []}
      />
    </Modal>
  )

  if (!user || !wasteTypes || !orgFoods || !mealServingLogs || !addWasteForm || !courses) {
    return (<p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>)
  }

  return (
    <div className="addWaste">
      <h1 className="title is-1 is-size-3-mobile is-uppercase ">
        <Trans>Lisää hävikki</Trans>
      </h1>
      {wasteLogEditModal}
      <ChooseServingForm />
      <ErrorMessage msg={error} />

      {selectedMealServing && selectedMealServing !== 'new' && (
        <div>
          <MealServingLogsTable
            setWasteLogInEdit={setWasteLogInEdit}
            selectedMealServing={selectedMealServing}
            isStaff={!!user.isStaff}
            orgAdmin={!!user.orgAdmin}
            mealServingLogs={mealServingLogs}
            wasteTypes={wasteTypes}
            foods={orgFoods}
            preparedAmountKgs={preparedAmountKg}
            wastedAmountKgs={wasteKg}
            courses={courses || []}
            deleteWasteLog={deleteWasteLog}
          />

          <NewMealServingLogForm
            wasteTypes={wasteTypes}
            foods={orgFoods}
            updateFormAttribute={updateFormAttribute}
            selectedWasteType={selectedWasteType}
            selectedFood={selectedFood}
            selectedCourse={selectedCourse}
            handleSubmit={handleNewMealServingLogSubmit}
            preparedAmountKgs={preparedAmountKg ? preparedAmountKg.toString() : ''}
            wastedAmountKgs={wasteKg ? wasteKg.toString() : ''}
            courses={courses || []}
          />

          <button
            className="button is-primary is-large is-fullwidth is-uppercase"
            onClick={() => {
              let customers = mealServings.find(ms => ms.id === selectedMealServing).customers
              if (customers && customers < 10) {
                setCheckModalOpen(true)
              } else {
                history.push('/')
              }
            }}
          >
            <Trans>valmis</Trans>
          </button>
        </div>
      )}

      { customerAmountCheckModal }
    </div>
  )
}

export default AddWaste
